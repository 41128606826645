import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useAssessmentManagement } from "@/hooks/useAssessmentManagement";
import { DoseType } from "@/components/pricing/types";

interface PlanSelectionHandlerProps {
  formData: any;
  onSuccess: (plan: { selectedPlan: string; doseType: DoseType; amount: number }, assessmentId: string) => void;
}

export const usePlanSelection = ({ formData, onSuccess }: PlanSelectionHandlerProps) => {
  const { toast } = useToast();
  const { saveAssessment } = useAssessmentManagement();

  const handlePlanSelect = async (planData: { selectedPlan: string; doseType: DoseType; amount: number }) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Error",
          description: "Please sign in to continue",
          variant: "destructive",
        });
        return;
      }

      console.log('Selected plan data:', planData);
      
      // Determine if this is a new patient who needs to ramp up
      const isNewPatient = !formData.previousGlp1 || 
                          !formData.recentGlp1 || 
                          formData.previousMedication?.toLowerCase() !== formData.selectedMedication?.toLowerCase();

      console.log('Is new patient:', isNewPatient);

      const assessment = await saveAssessment(user.id, {
        ...formData,
        auto_renew: false, // Always set auto_renew to false for new assessments
      }, planData);
      
      if (assessment) {
        console.log('Plan selected successfully:', { planData, assessmentId: assessment.id });
        onSuccess(planData, assessment.id);
      }
    } catch (error: any) {
      console.error("Error selecting plan:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to save assessment. Please try again.",
        variant: "destructive",
      });
    }
  };

  return { handlePlanSelect };
};