
import { SalesBannerManagement } from "./SalesBannerManagement";

export const BannerManagementPage = () => {
  return (
    <div className="container mx-auto py-10 space-y-8">
      <h1 className="text-2xl font-bold">Banner Management</h1>
      <SalesBannerManagement />
    </div>
  );
};
