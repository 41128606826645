
import { RadioGroup } from "@/components/ui/radio-group";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, PartyPopper } from "lucide-react";
import { MedicationHeader } from "./medication/MedicationHeader";
import { MedicationCard } from "./medication/MedicationCard";
import { usePricingData } from "./pricing/usePricingData";

interface MedicationSelectionProps {
  selectedMedication: string;
  onMedicationSelect: (medication: string) => void;
  previousGlp1: string;
  previousMedication: string;
  recentGlp1: string;
}

export const MedicationSelection = ({ 
  selectedMedication, 
  onMedicationSelect,
  previousGlp1,
  previousMedication,
  recentGlp1
}: MedicationSelectionProps) => {
  const { pricingData } = usePricingData({
    selectedMedication,
    previousGlp1,
    previousMedication,
    recentGlp1,
    doseType: "standard"
  });

  const needsRampUp = (medication: string): boolean => {
    return previousGlp1 !== "yes" || 
           recentGlp1 !== "yes" || 
           previousMedication.toLowerCase() !== medication.toLowerCase();
  };

  const isCurrentMedication = (medication: string): boolean => {
    return previousGlp1 === "yes" && 
           previousMedication.toLowerCase() === medication.toLowerCase() && 
           recentGlp1 === "yes";
  };

  return (
    <div className="space-y-6">
      <div className="bg-green-50 border border-green-200 rounded-lg p-6 text-center space-y-2">
        <div className="flex items-center justify-center gap-2 text-green-700">
          <PartyPopper className="h-6 w-6" />
          <h2 className="text-xl font-semibold">Congratulations!</h2>
        </div>
        <p className="text-green-700">
          Based on your information, you're pre-approved to continue with your medication selection.
        </p>
      </div>

      <MedicationHeader />
      
      {previousGlp1 === "yes" && recentGlp1 !== "yes" && (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Since it's been more than 8 weeks since your last treatment, our providers require you to start with the ramp-up dosing schedule.
          </AlertDescription>
        </Alert>
      )}

      <RadioGroup
        value={selectedMedication}
        onValueChange={onMedicationSelect}
        className="grid md:grid-cols-2 gap-6"
      >
        <MedicationCard
          medication="semaglutide"
          isSelected={selectedMedication === "semaglutide"}
          onSelect={() => onMedicationSelect("semaglutide")}
          needsRampUp={needsRampUp("semaglutide")}
          imagePath="/lovable-uploads/5d873cbe-12e4-4b4f-9d6b-9a3d960e4a40.png"
          isCurrent={isCurrentMedication("semaglutide")}
        />
        <MedicationCard
          medication="tirzepatide"
          isSelected={selectedMedication === "tirzepatide"}
          onSelect={() => onMedicationSelect("tirzepatide")}
          needsRampUp={needsRampUp("tirzepatide")}
          imagePath="/lovable-uploads/ef4aed5b-dbef-4796-8b0f-4fcb9e92b367.png"
          isCurrent={isCurrentMedication("tirzepatide")}
        />
      </RadioGroup>

      <div className="mt-8 p-4 bg-muted rounded-lg">
        <p className="text-sm text-muted-foreground text-center">
          Your selected medication will be reviewed by our healthcare providers to ensure it's the right choice for you.
          They may recommend a different option based on your medical history.
        </p>
      </div>
    </div>
  );
};
