
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { makeUserProvider } from "@/utils/providerUtils";
import { Loader2 } from "lucide-react";

interface MakeProviderButtonProps {
  userId: string;
  onSuccess?: () => void;
}

export const MakeProviderButton = ({ userId, onSuccess }: MakeProviderButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  
  const handleClick = async () => {
    setIsLoading(true);
    try {
      const success = await makeUserProvider(userId);
      if (success && onSuccess) {
        onSuccess();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button onClick={handleClick} variant="outline" disabled={isLoading}>
      {isLoading ? (
        <>
          <Loader2 className="h-4 w-4 mr-2 animate-spin" />
          Making Provider...
        </>
      ) : (
        "Make Provider"
      )}
    </Button>
  );
};
