import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";

interface DoseSelectionCardProps {
  medication: string;
  doseType: "standard" | "max";
  onDoseTypeChange: (doseType: "standard" | "max") => void;
}

export const DoseSelectionCard = ({ medication, doseType, onDoseTypeChange }: DoseSelectionCardProps) => {
  const standardDoseText = medication === "semaglutide" ? "1.25mg per week" : "7.5mg per week";
  const maxDoseText = medication === "semaglutide" ? "2.5mg per week" : "10mg per week";

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <h3 className="text-lg font-semibold">Select Your Dose</h3>
        <div className="flex items-center justify-between">
          <div>
            <Label className="text-base">
              {doseType === "standard" ? "Standard Dose" : "Maximum Dose"}
            </Label>
            <p className="text-sm text-muted-foreground">
              {doseType === "standard" ? standardDoseText : maxDoseText}
            </p>
          </div>
          <Switch
            checked={doseType === "max"}
            onCheckedChange={(checked) => onDoseTypeChange(checked ? "max" : "standard")}
          />
        </div>
      </div>
    </Card>
  );
};