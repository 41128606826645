
import { useState, useEffect } from "react"
import { useChats } from "@/hooks/useChats"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Assessment } from "@/types/database/assessment"
import { ChatCard } from "@/components/provider/messaging/ChatCard"
import { Input } from "@/components/ui/input"
import { Search } from "lucide-react"
import { isValidProfile } from "@/types/database/profile"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Checkbox } from "@/components/ui/checkbox"
import { Skeleton } from "@/components/ui/skeleton"

interface MessageListProps {
  onSelectAssessment: (assessment: Assessment) => void
  selectedAssessmentId?: string
}

export const MessageList = ({ onSelectAssessment, selectedAssessmentId }: MessageListProps) => {
  const [filter, setFilter] = useState<"open" | "closed" | "all">("open") // Default to "open" filter
  const [searchQuery, setSearchQuery] = useState("")
  const [showUnreadOnly, setShowUnreadOnly] = useState(false)
  const { assessments, chatStatuses, unreadCounts, handleMarkComplete, isLoading } = useChats()

  // Debug logs to check what we're receiving from useChats
  useEffect(() => {
    console.log('MessageList - Chat Statuses:', chatStatuses);
    if (Object.keys(chatStatuses).length > 0) {
      console.log('MessageList - Sample chat status values:', 
        Object.entries(chatStatuses).slice(0, 5).map(([key, value]) => `${key}: ${value}`));
    }
    console.log('MessageList - Unread Counts:', unreadCounts);
    console.log('MessageList - Filter:', filter);
  }, [chatStatuses, unreadCounts, filter]);
  
  // Track if we've seen unread messages
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)
  
  useEffect(() => {
    // Check if there are any unread messages
    const totalUnread = Object.values(unreadCounts).reduce((sum, count) => sum + count, 0)
    setHasUnreadMessages(totalUnread > 0)
  }, [unreadCounts])

  const filteredAssessments = assessments.filter((assessment) => {
    // Check if the chat status exists and if it's complete
    const isComplete = chatStatuses[assessment.id] === true
    // Check if there are unread messages for this assessment
    const hasUnread = (unreadCounts[assessment.id] || 0) > 0
    
    // Filter by open/closed status
    let matchesStatusFilter = true;
    if (filter === "open") {
      // Open chats are those that are not complete OR have unread messages
      matchesStatusFilter = !isComplete || hasUnread;
    } else if (filter === "closed") {
      // Closed chats are those that are complete AND have no unread messages
      matchesStatusFilter = isComplete && !hasUnread;
    }
    
    // Filter by unread if that option is selected
    const matchesUnreadFilter = showUnreadOnly ? hasUnread : true
    
    // Search filter logic
    let matchesSearch = true
    if (searchQuery.trim()) {
      const profile = assessment.profiles
      const firstName = isValidProfile(profile) ? profile.first_name?.toLowerCase() || '' : ''
      const lastName = isValidProfile(profile) ? profile.last_name?.toLowerCase() || '' : ''
      const fullName = `${firstName} ${lastName}`.trim()
      const searchTerm = searchQuery.toLowerCase().trim()
      
      matchesSearch = firstName.includes(searchTerm) || 
                     lastName.includes(searchTerm) || 
                     fullName.includes(searchTerm)
    }
    
    return matchesStatusFilter && matchesUnreadFilter && matchesSearch
  })

  // Count of chats that are open (not completed or has unread messages)
  const openCount = assessments.filter(a => 
    !chatStatuses[a.id] || unreadCounts[a.id] > 0
  ).length

  // Count of unread messages
  const unreadMessagesCount = Object.values(unreadCounts).reduce((sum, count) => sum + count, 0)

  // Additional debug logging for filtered assessments
  useEffect(() => {
    if (assessments.length > 0) {
      console.log(`MessageList - Filtered ${filteredAssessments.length} assessments out of ${assessments.length}`);
      console.log(`MessageList - Open Count: ${openCount}, Unread Count: ${unreadMessagesCount}`);
    }
  }, [filteredAssessments, assessments, openCount, unreadMessagesCount]);

  return (
    <div className="space-y-4 h-[calc(100vh-6rem)]">
      <div className="space-y-4">
        <h2 className="text-lg font-semibold flex items-center">
          Messages 
          {openCount > 0 && (
            <span className="ml-2 bg-primary text-primary-foreground text-xs px-2 py-1 rounded-full">
              {openCount} open
            </span>
          )}
          {unreadMessagesCount > 0 && (
            <span className="ml-2 bg-destructive text-destructive-foreground text-xs px-2 py-1 rounded-full">
              {unreadMessagesCount} unread
            </span>
          )}
        </h2>
        <div className="relative">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search patients..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8"
          />
        </div>
        <div className="flex items-center justify-between">
          <Select value={filter} onValueChange={(value: "open" | "closed" | "all") => setFilter(value)}>
            <SelectTrigger className="w-full bg-white">
              <SelectValue />
            </SelectTrigger>
            <SelectContent className="w-full bg-white">
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="open">Open</SelectItem>
              <SelectItem value="closed">Closed</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox 
            id="unread-only" 
            checked={showUnreadOnly}
            onCheckedChange={(checked) => setShowUnreadOnly(checked === true)}
          />
          <label 
            htmlFor="unread-only" 
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
          >
            Show unread only {unreadMessagesCount > 0 ? `(${unreadMessagesCount})` : ''}
          </label>
        </div>
        {showUnreadOnly && !hasUnreadMessages && (
          <div className="text-sm text-muted-foreground">
            No unread messages found.
          </div>
        )}
      </div>
      <ScrollArea className="h-[calc(100vh-16rem)]">
        {isLoading ? (
          <div className="space-y-4 pr-4">
            {[1, 2, 3, 4].map((i) => (
              <Skeleton key={i} className="h-24 w-full rounded-md" />
            ))}
          </div>
        ) : (
          <div className="space-y-2 pr-4">
            {filteredAssessments.map((assessment) => (
              <ChatCard
                key={assessment.id}
                assessment={assessment}
                unreadCount={unreadCounts[assessment.id] || 0}
                onMarkComplete={handleMarkComplete}
                onClick={() => onSelectAssessment(assessment)}
                isSelected={selectedAssessmentId === assessment.id}
                isCompleted={chatStatuses[assessment.id] === true && !(unreadCounts[assessment.id] > 0)}
              />
            ))}
            {filteredAssessments.length === 0 && (
              <div className="text-center p-4 text-muted-foreground">
                No {filter !== "all" ? filter : ""} messages 
                {showUnreadOnly ? " unread" : ""} 
                {searchQuery && ' matching your search'}
              </div>
            )}
          </div>
        )}
      </ScrollArea>
    </div>
  )
}
