
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

export const useLowestMedicationPrice = (medication: string, isRampedPatient: boolean) => {
  return useQuery({
    queryKey: ["lowest-medication-price", medication, isRampedPatient],
    queryFn: async () => {
      console.log('Fetching lowest price for:', { medication, isRampedPatient });
      
      const { data, error } = await supabase
        .from("medication_pricing")
        .select("monthly_price")
        .eq("medication", medication.toLowerCase())
        .eq("patient_type", isRampedPatient ? 'ramped' : 'existing')
        .order("monthly_price", { ascending: true })
        .limit(1);

      if (error) {
        console.error("Error fetching lowest price:", error);
        throw error;
      }

      console.log('Lowest price data:', data);
      return data?.[0]?.monthly_price || null;
    },
    enabled: !!medication
  });
};
