import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { Send } from "lucide-react"

interface MessageInputProps {
  value: string
  onChange: (value: string) => void
  onSend: () => void
  isLoading?: boolean
}

export function MessageInput({ value, onChange, onSend, isLoading }: MessageInputProps) {
  return (
    <div className="sticky bottom-0 w-full border-t bg-background p-4">
      <form 
        className="flex gap-2"
        onSubmit={(e) => {
          e.preventDefault()
          if (value.trim()) {
            onSend()
          }
        }}
      >
        <Textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Type your message..."
          className="min-h-[44px] max-h-[100px] resize-none"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault()
              if (value.trim()) {
                onSend()
              }
            }
          }}
        />
        <Button
          type="submit"
          disabled={isLoading || !value.trim()}
          size="icon"
          className="h-[44px] w-[44px] shrink-0"
        >
          <Send className="h-4 w-4" />
        </Button>
      </form>
    </div>
  )
}