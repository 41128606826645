
import { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { useInfiniteQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { PatientDetailsModal } from "../PatientDetailsModal";
import { Skeleton } from "@/components/ui/skeleton";
import { EmptyState } from "../EmptyState";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";

export function PatientsPage() {
  const [selectedPatientId, setSelectedPatientId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const pageSize = 10;

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchQuery]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["patients", debouncedSearchQuery],
    queryFn: async ({ pageParam }) => {
      const startIndex = (pageParam as number) * pageSize;
      
      let patientIdsFromCellPhone: string[] = [];
      
      if (debouncedSearchQuery) {
        const { data: assessmentsData } = await supabase
          .from("assessments")
          .select("user_id, cell_phone")
          .ilike("cell_phone", `%${debouncedSearchQuery}%`);
        
        if (assessmentsData) {
          patientIdsFromCellPhone = assessmentsData.map(a => a.user_id);
          console.log("Found patients by cell phone:", patientIdsFromCellPhone);
        }
      }
      
      let query = supabase
        .from("profiles")
        .select("*", { count: "exact" })
        .order("created_at", { ascending: false });
      
      if (debouncedSearchQuery) {
        if (patientIdsFromCellPhone.length > 0) {
          query = query.or(
            `id.in.(${patientIdsFromCellPhone.join(',')}),first_name.ilike.%${debouncedSearchQuery}%,last_name.ilike.%${debouncedSearchQuery}%,email.ilike.%${debouncedSearchQuery}%`
          );
        } else {
          query = query.or(`first_name.ilike.%${debouncedSearchQuery}%,last_name.ilike.%${debouncedSearchQuery}%,email.ilike.%${debouncedSearchQuery}%`);
        }
      }
      
      const { data, error, count } = await query.range(startIndex, startIndex + pageSize - 1);

      if (error) {
        console.error("Error fetching patients:", error);
        throw error;
      }

      console.log("Patients search results:", data);
      console.log("Search query used:", debouncedSearchQuery);

      const patientsWithPhone = await Promise.all(
        data.map(async (patient) => {
          const { data: assessmentData } = await supabase
            .from("assessments")
            .select("cell_phone")
            .eq("user_id", patient.id)
            .order("created_at", { ascending: false })
            .limit(1);
            
          return {
            ...patient,
            cell_phone: assessmentData && assessmentData.length > 0 ? assessmentData[0].cell_phone : null
          };
        })
      );

      return {
        patients: patientsWithPhone,
        currentPage: pageParam,
        hasMore: count ? startIndex + pageSize < count : false,
      };
    },
    getNextPageParam: (lastPage: any) => {
      if (lastPage.hasMore) {
        return (lastPage.currentPage as number) + 1;
      }
      return undefined;
    },
    initialPageParam: 0,
  });

  const handleOpenDetails = (patientId: string) => {
    setSelectedPatientId(patientId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPatientId(null);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const patients = data?.pages.flatMap((page: any) => page.patients) || [];

  if (isLoading) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Patients</h1>
          <div className="relative w-72">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500" />
            <Input
              type="search"
              placeholder="Search patients..."
              className="pl-8"
              disabled
            />
          </div>
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Phone</TableHead>
                <TableHead>Created</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell><Skeleton className="h-4 w-[150px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[200px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Patients</h1>
        <div className="relative w-72">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-gray-500" />
          <Input
            type="search"
            placeholder="Search patients..."
            value={searchQuery}
            onChange={handleSearch}
            className="pl-8"
          />
        </div>
      </div>

      {patients.length === 0 ? (
        <EmptyState 
          title={debouncedSearchQuery ? "No matching patients" : "No patients found"} 
          description={debouncedSearchQuery ? "Try a different search term." : "There are no patients in the system yet."} 
        />
      ) : (
        <>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone</TableHead>
                  <TableHead>Created</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {patients.map((patient: any) => (
                  <TableRow key={patient.id}>
                    <TableCell>
                      {patient.first_name} {patient.last_name}
                    </TableCell>
                    <TableCell>{patient.email}</TableCell>
                    <TableCell>{patient.cell_phone || "N/A"}</TableCell>
                    <TableCell>
                      {patient.created_at
                        ? format(new Date(patient.created_at), "MMM d, yyyy")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleOpenDetails(patient.id)}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          {hasNextPage && (
            <div className="flex justify-center mt-4">
              <Button
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage || !hasNextPage}
                variant="outline"
              >
                {isFetchingNextPage
                  ? "Loading more..."
                  : hasNextPage
                  ? "Load More"
                  : "No more patients"}
              </Button>
            </div>
          )}
        </>
      )}

      {selectedPatientId && (
        <PatientDetailsModal
          patientId={selectedPatientId}
          assessmentId={null} // Adding the required assessmentId prop with null value
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}
