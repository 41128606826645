import { Assessment, isValidProfile } from "../types";

interface BasicInfoSectionProps {
  assessment: Assessment;
}

export const BasicInfoSection = ({ assessment }: BasicInfoSectionProps) => {
  const profile = assessment.profiles;
  
  // Get profile UTM parameters if assessment UTM parameters are not available
  const utmSource = assessment.utm_source || (isValidProfile(profile) ? profile.utm_source : null);
  const utmMedium = assessment.utm_medium || (isValidProfile(profile) ? profile.utm_medium : null);
  const utmCampaign = assessment.utm_campaign || (isValidProfile(profile) ? profile.utm_campaign : null);
  const utmTerm = assessment.utm_term || (isValidProfile(profile) ? profile.utm_term : null);
  const utmContent = assessment.utm_content || (isValidProfile(profile) ? profile.utm_content : null);

  const patientName = isValidProfile(profile) ? 
    `${profile.first_name || ''} ${profile.last_name || ''}`.trim() : 
    'Unknown Patient';

  const patientEmail = isValidProfile(profile) ? profile.email : 'Not provided';

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Basic Information</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm text-muted-foreground">Name</p>
          <p>{patientName}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Email</p>
          <p>{patientEmail}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Phone</p>
          <p>{assessment.cell_phone || 'Not provided'}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Date of Birth</p>
          <p>{assessment.date_of_birth ? new Date(assessment.date_of_birth).toLocaleDateString() : 'Not provided'}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">Gender</p>
          <p className="capitalize">{assessment.gender || 'Not provided'}</p>
        </div>
      </div>

      <div className="mt-6">
        <h4 className="text-md font-semibold mb-2">UTM Parameters</h4>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-muted-foreground">Source</p>
            <p>{utmSource || 'Not provided'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">Medium</p>
            <p>{utmMedium || 'Not provided'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">Campaign</p>
            <p>{utmCampaign || 'Not provided'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">Term</p>
            <p>{utmTerm || 'Not provided'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">Content</p>
            <p>{utmContent || 'Not provided'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};