import { PricingCard } from "./PricingCard";

interface PricingGridProps {
  pricingData: any[];
  selectedPlan: string;
  onSelect: (plan: string) => void;
}

export const PricingGrid = ({ pricingData, selectedPlan, onSelect }: PricingGridProps) => {
  return (
    <div className="grid md:grid-cols-3 gap-6">
      {pricingData.map((plan, index) => (
        <PricingCard
          key={plan.duration_months}
          plan={plan}
          isSelected={selectedPlan === `${plan.duration_months} months`}
          isPopular={index === 1}
          onSelect={() => onSelect(`${plan.duration_months} months`)}
        />
      ))}
    </div>
  );
};