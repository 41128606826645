import { useEffect } from "react"
import { supabase } from "@/integrations/supabase/client"

export const useMessageSubscription = (assessmentId: string, onNewMessage: () => void) => {
  useEffect(() => {
    const channel = supabase
      .channel(`messages:${assessmentId}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "messages",
          filter: `assessment_id=eq.${assessmentId}`,
        },
        () => {
          onNewMessage()
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(channel)
    }
  }, [assessmentId, onNewMessage])
}