
import { BasicInfoForm } from "@/components/BasicInfoForm";
import { MedicalHistoryForm } from "@/components/MedicalHistoryForm";
import { MedicationSelection } from "@/components/MedicationSelection";
import { PricingPlans } from "@/components/PricingPlans";
import { ShippingForm } from "@/components/ShippingForm";
import { PaymentStep } from "@/components/PaymentStep";
import { ConfirmationScreen } from "@/components/ConfirmationScreen";
import { Welcome } from "@/components/Welcome";
import { Glp1QuestionForm } from "@/components/medical-history/Glp1QuestionForm";
import { useState, useEffect, useRef } from "react";

interface AssessmentStepsProps {
  currentStep: number;
  formData: any;
  onFormDataChange: (data: any) => void;
  onMedicationSelect: (medication: string) => void;
  onPlanSelect: (plan: any) => void;
  onPaymentSuccess: (assessmentId: string) => void;
  onPrevious: () => void;
  onValidationChange?: (isValid: boolean) => void;
}

export const AssessmentSteps = ({
  currentStep,
  formData,
  onFormDataChange,
  onMedicationSelect,
  onPlanSelect,
  onPaymentSuccess,
  onPrevious,
  onValidationChange,
}: AssessmentStepsProps) => {
  const [isStepValid, setIsStepValid] = useState(false);
  const stepRef = useRef<HTMLDivElement>(null);

  const handleStepValidation = (isValid: boolean) => {
    setIsStepValid(isValid);
    if (onValidationChange) {
      onValidationChange(isValid);
    }
  };

  // Effect to scroll to top of step when step changes
  useEffect(() => {
    if (stepRef.current) {
      // Allow time for the DOM to update
      setTimeout(() => {
        // Scroll to the top of the current step
        stepRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
        // Or use window scroll for more consistent behavior
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }, 100);
    }
  }, [currentStep]);
  
  // Determine if we should show the navigation buttons
  // Don't show the "Previous" button on confirmation screen
  const shouldShowPrevious = currentStep !== 9;
  
  const renderStep = () => {
    switch (currentStep) {
      case 2:
        return (
          <BasicInfoForm
            formData={formData}
            onChange={(data) => onFormDataChange({ ...formData, ...data })}
            onValidationChange={handleStepValidation}
          />
        );
      case 3:
        return (
          <MedicalHistoryForm
            formData={formData}
            onChange={(data) => onFormDataChange({ ...formData, ...data })}
          />
        );
      case 4:
        return (
          <Glp1QuestionForm
            formData={formData}
            onChange={(data) => onFormDataChange({ ...formData, ...data })}
          />
        );
      case 5:
        return (
          <MedicationSelection
            selectedMedication={formData.selectedMedication}
            onMedicationSelect={onMedicationSelect}
            previousGlp1={formData.previousGlp1}
            previousMedication={formData.previousMedication}
            recentGlp1={formData.recentGlp1}
          />
        );
      case 6:
        return (
          <PricingPlans
            selectedMedication={formData.selectedMedication}
            selectedPlan={formData.selectedPlan}
            onPlanSelect={(plan, doseType, amount) => {
              console.log('Plan selected with amount:', amount);
              onPlanSelect({ 
                selectedPlan: plan, 
                doseType,
                amount 
              });
            }}
            previousGlp1={formData.previousGlp1}
            previousMedication={formData.previousMedication}
            recentGlp1={formData.recentGlp1}
            doseType={formData.doseType || "standard"}
            onBack={onPrevious}
          />
        );
      case 7:
        return (
          <ShippingForm
            formData={formData}
            onChange={(data) => onFormDataChange({ ...formData, ...data })}
          />
        );
      case 8:
        const assessmentId = formData.id || formData.assessmentId;
        // Get amount either from selectedPlan object or direct amount property
        const paymentAmount = formData.selectedPlan?.amount || formData.amount;
        
        console.log('Payment step data:', { 
          assessmentId,
          paymentAmount,
          formData 
        });

        return assessmentId ? (
          <PaymentStep
            subscriptionId={assessmentId}
            onSuccess={() => onPaymentSuccess(assessmentId)}
            onBack={onPrevious}
            amount={paymentAmount}
          />
        ) : (
          <div className="text-center">
            <p className="text-red-500">Error loading assessment details</p>
          </div>
        );
      case 9:
        return formData.assessment ? (
          <ConfirmationScreen 
            subscription={formData.assessment} 
          />
        ) : (
          <div className="text-center">
            <p className="text-red-500">Error loading order details</p>
          </div>
        );
      default:
        return <Welcome />;
    }
  };

  return (
    <div ref={stepRef} className="scroll-mt-[var(--banner-height)]">
      {renderStep()}
      {/* Pass the shouldShowPrevious flag to the parent component */}
      {shouldShowPrevious === false && <input type="hidden" id="hide-previous-button" data-hide-previous="true" />}
    </div>
  );
};
