import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useToast } from "@/hooks/use-toast"
import { supabase } from "@/integrations/supabase/client"
import { useAuth } from "@/hooks/useAuth"
import { MessageList } from "./MessageList"
import { format } from "date-fns"

export default function PatientMessages() {
  const [message, setMessage] = useState("")
  const [messages, setMessages] = useState<any[]>([])
  const [assessment, setAssessment] = useState<any>(null)
  const { toast } = useToast()
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    const fetchAssessment = async () => {
      if (!user) return

      const { data: assessmentData, error } = await supabase
        .from("assessments")
        .select("*")
        .eq("user_id", user.id)
        .in("status", ["completed", "prescribed"])
        .order("created_at", { ascending: false })
        .limit(1)
        .single()

      if (error) {
        console.error("Error fetching assessment:", error)
        toast({
          title: "Error",
          description: "You need to complete an assessment before messaging your provider.",
          variant: "destructive",
        })
        navigate("/dashboard")
        return
      }

      if (!assessmentData) {
        toast({
          title: "No Assessment Found",
          description: "You need to complete an assessment before messaging your provider.",
          variant: "destructive",
        })
        navigate("/dashboard")
        return
      }

      setAssessment(assessmentData)

      // Fetch initial messages
      const { data: messagesData, error: messagesError } = await supabase
        .from("messages")
        .select("*")
        .eq("assessment_id", assessmentData.id)
        .order("created_at", { ascending: true })

      if (messagesError) {
        console.error("Error fetching messages:", messagesError)
        return
      }

      setMessages(messagesData || [])

      // Subscribe to new messages
      const channel = supabase
        .channel("messages-channel")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "messages",
            filter: `assessment_id=eq.${assessmentData.id}`,
          },
          (payload) => {
            if (payload.eventType === "INSERT") {
              setMessages((current) => [...current, payload.new])
            }
          }
        )
        .subscribe()

      return () => {
        supabase.removeChannel(channel)
      }
    }

    fetchAssessment()
  }, [user, navigate, toast])

  const handleSendMessage = async () => {
    if (!message.trim() || !assessment) return

    const { error } = await supabase.from("messages").insert({
      assessment_id: assessment.id,
      content: message.trim(),
      sender_id: user?.id,
      is_provider: false,
    })

    if (error) {
      console.error("Error sending message:", error)
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        variant: "destructive",
      })
      return
    }

    setMessage("")
  }

  if (!assessment) {
    return null
  }

  return (
    <div className="h-[100dvh] flex flex-col">
      <Card className="flex-1 flex flex-col rounded-none md:rounded-lg">
        <CardHeader>
          <CardTitle>Message Your Provider</CardTitle>
        </CardHeader>
        <CardContent className="flex-1 flex flex-col p-0">
          <ScrollArea className="flex-1">
            <div className="p-4">
              <MessageList messages={messages} currentUserId={user?.id} />
            </div>
          </ScrollArea>
          <div className="sticky bottom-0 border-t bg-background p-4">
            <form 
              className="flex gap-2"
              onSubmit={(e) => {
                e.preventDefault()
                handleSendMessage()
              }}
            >
              <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1"
              />
              <Button type="submit" disabled={!message.trim()}>Send</Button>
            </form>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}