import { Assessment, isValidProfile } from "../types"
import { Card, CardContent } from "@/components/ui/card"
import { MapPin } from "lucide-react"
import { formatDate } from "@/utils/formatDate"

interface ShippingSectionProps {
  assessment: Assessment
}

export const ShippingSection = ({ assessment }: ShippingSectionProps) => {
  const profile = assessment.profiles;
  const email = isValidProfile(profile) ? profile.email : 'Not specified';
  const lastUpdated = assessment.updated_at ? formatDate(assessment.updated_at) : 'Not specified';

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <MapPin className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-semibold">Current Shipping Information</h3>
          </div>
          <div className="text-sm text-muted-foreground">
            Last updated: {lastUpdated}
          </div>
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-muted-foreground">Address</p>
            <p className="font-medium">{assessment.shipping_address || 'Not specified'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">City</p>
            <p className="font-medium">{assessment.shipping_city || 'Not specified'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">State</p>
            <p className="font-medium">{assessment.shipping_state || 'Not specified'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">ZIP Code</p>
            <p className="font-medium">{assessment.shipping_zip || 'Not specified'}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">Contact Email</p>
            <p className="font-medium">{email}</p>
          </div>
          <div>
            <p className="text-sm text-muted-foreground">Contact Phone</p>
            <p className="font-medium">{assessment.cell_phone || 'Not specified'}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}