import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { DoseType, PricingData } from "./types";

interface PricingDataProps {
  selectedMedication: string;
  previousGlp1: string;
  previousMedication: string;
  recentGlp1: string;
  doseType: DoseType;
}

export const usePricingData = ({
  selectedMedication,
  previousGlp1,
  previousMedication,
  recentGlp1,
  doseType,
}: PricingDataProps) => {
  const needsRampUp = (): boolean => {
    return previousGlp1 !== "yes" || 
           recentGlp1 !== "yes" || 
           previousMedication.toLowerCase() !== selectedMedication.toLowerCase();
  };

  const { data: pricingData = [], isLoading } = useQuery({
    queryKey: ["pricing", selectedMedication, doseType, needsRampUp()],
    queryFn: async () => {
      const patientType = needsRampUp() ? 'ramped' : 'existing';
      const actualDoseType = needsRampUp() ? 'standard' : doseType;

      console.log("Fetching pricing data with params:", {
        medication: selectedMedication.toLowerCase(),
        patientType,
        doseType: actualDoseType,
        needsRampUp: needsRampUp()
      });

      const { data, error } = await supabase
        .from("medication_pricing")
        .select("*")
        .eq("medication", selectedMedication.toLowerCase())
        .eq("patient_type", patientType)
        .eq("dose_type", actualDoseType)
        .order('duration_months', { ascending: true });

      if (error) {
        console.error("Error fetching pricing data:", error);
        throw error;
      }

      console.log("Fetched pricing data:", data);
      return data as PricingData[];
    },
  });

  return {
    pricingData: pricingData || [],
    isLoading,
  };
};