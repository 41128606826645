
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { PaymentData } from "../types/payment";
import { getErrorMessage, trackPaymentFailure } from "../utils/paymentValidation";

export const usePaymentSubmission = (subscriptionId: string, onSuccess: () => void) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (paymentData: PaymentData, selectedAmount: number, setSaveCard: (value: boolean) => void) => {
    try {
      setIsProcessing(true);
      console.log('Starting payment submission with amount:', selectedAmount);

      // First verify the assessment exists and get its details
      const { data: assessment, error: assessmentError } = await supabase
        .from('assessments')
        .select(`
          *,
          profiles (
            first_name,
            last_name,
            email
          )
        `)
        .eq('id', subscriptionId)
        .single();

      if (assessmentError || !assessment) {
        console.error('Error fetching assessment:', assessmentError);
        throw new Error('Assessment not found');
      }

      let paymentMethodDetails;
      if (paymentData.savedMethodId) {
        console.log('Using saved payment method:', paymentData.savedMethodId);
        
        const { data: savedMethod, error: savedMethodError } = await supabase
          .from('payment_methods')
          .select('*')
          .eq('id', paymentData.savedMethodId)
          .single();

        if (savedMethodError || !savedMethod) {
          console.error('Error fetching saved payment method:', savedMethodError);
          throw new Error('Saved payment method not found');
        }

        if (!savedMethod.customer_profile_id || !savedMethod.payment_profile_id) {
          console.error('Invalid saved payment method - missing profile IDs:', savedMethod);
          throw new Error('Invalid saved payment method');
        }

        console.log('Retrieved payment method details:', {
          customerProfileId: savedMethod.customer_profile_id,
          paymentProfileId: savedMethod.payment_profile_id,
          lastFour: savedMethod.last_four,
          cardBrand: savedMethod.card_brand
        });

        paymentMethodDetails = savedMethod;
      }

      // Get customer profile if available
      const customerProfile = assessment.profiles ? {
        firstName: assessment.profiles.first_name,
        lastName: assessment.profiles.last_name,
        email: assessment.profiles.email
      } : undefined;

      console.log('Using customer profile:', customerProfile);

      // Prepare the payment request data
      const paymentRequestData = paymentData.savedMethodId ? {
        paymentData: {
          savedMethodId: paymentData.savedMethodId,
          customerProfileId: paymentMethodDetails?.customer_profile_id,
          paymentProfileId: paymentMethodDetails?.payment_profile_id,
          saveCard: false,
          firstName: customerProfile?.firstName || '',
          lastName: customerProfile?.lastName || ''
        },
        subscriptionId,
        amount: selectedAmount,
        description: `${assessment.medication} - ${assessment.plan_type} (${assessment.dose_type} dose)`,
        customerProfile: {
          firstName: customerProfile?.firstName || '',
          lastName: customerProfile?.lastName || '',
          email: customerProfile?.email || ''
        }
      } : {
        paymentData: {
          cardNumber: paymentData.cardNumber.replace(/\s/g, ''),
          expirationDate: paymentData.expirationDate,
          cardCode: paymentData.cardCode,
          saveCard: paymentData.saveCard,
          firstName: customerProfile?.firstName || '',
          lastName: customerProfile?.lastName || ''
        },
        subscriptionId,
        amount: selectedAmount,
        description: `${assessment.medication} - ${assessment.plan_type} (${assessment.dose_type} dose)`,
        customerProfile: {
          firstName: customerProfile?.firstName || '',
          lastName: customerProfile?.lastName || '',
          email: customerProfile?.email || ''
        }
      };

      // Process the payment
      const { data, error } = await supabase.functions.invoke('process-payment', {
        body: paymentRequestData
      });

      if (error) {
        console.error('Payment processing error:', error);
        // Track the payment failure for delayed email notification if we have customer email
        if (customerProfile?.email) {
          await trackPaymentFailure(
            error, 
            customerProfile.email, 
            customerProfile.firstName
          );
        }
        throw error;
      }

      if (!data || !data.success || data.data?.success === false) {
        console.error('Payment failed:', data);
        // Track the payment failure for delayed email notification if we have customer email
        if (customerProfile?.email) {
          await trackPaymentFailure(
            { message: data?.data?.message || data?.message || 'Payment processing failed' },
            customerProfile.email,
            customerProfile.firstName
          );
        }
        throw new Error(data?.data?.message || data?.message || 'Payment processing failed');
      }

      console.log('Payment processed successfully:', {
        success: data.success,
        hasCustomerProfileId: !!data.customerProfileId,
        hasPaymentProfileId: !!data.paymentProfileId,
        fullResponse: data
      });

      // If user chose to save the card and it's a new card
      if (paymentData.saveCard && !paymentData.savedMethodId && data.customerProfileId && data.paymentProfileId) {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          console.log('Attempting to save new payment method');

          const { error: saveError } = await supabase.from('payment_methods').insert({
            user_id: user.id,
            last_four: paymentData.cardNumber.slice(-4),
            expiration_date: paymentData.expirationDate,
            card_brand: 'visa',
            is_default: true,
            customer_profile_id: data.customerProfileId,
            payment_profile_id: data.paymentProfileId
          });

          if (saveError) {
            console.error('Error saving payment method:', saveError);
          } else {
            console.log('Successfully saved new payment method to database');
          }
        }
      }

      toast({
        title: "Payment Successful",
        description: paymentData.saveCard && !paymentData.savedMethodId
          ? "Your payment has been processed and your card has been saved for future use."
          : "Your payment has been processed successfully.",
      });
      
      onSuccess();
    } catch (error: any) {
      console.error('Payment processing error:', error);
      // setSaveCard(false); // Commented out to preserve checkbox state on error
      const errorMessage = getErrorMessage(error);
      
      toast({
        title: "Payment Failed",
        description: `${errorMessage} Please wait at least 2 minutes before trying the same card again, or use a different card.`,
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isProcessing,
    handleSubmit,
  };
};
