
import { useQuery } from "@tanstack/react-query";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PricingTable } from "./PricingTable";
import { supabase } from "@/integrations/supabase/client";
import { Loader2 } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useAuth } from "@/hooks/useAuth";

export const PricingManagementPage = () => {
  const { user } = useAuth();
  console.log("PricingManagementPage mounted, user:", user);

  const { data: pricingData, isLoading, error } = useQuery({
    queryKey: ["medication-pricing"],
    queryFn: async () => {
      console.log("Fetching pricing data...");
      
      if (!user) {
        throw new Error("Not authenticated");
      }

      const { data, error } = await supabase
        .from("medication_pricing")
        .select("*")
        .order("medication", { ascending: true })
        .order("duration_months", { ascending: true });

      if (error) {
        console.error("Error fetching pricing data:", error);
        throw error;
      }

      console.log("Fetched pricing data:", data);
      return data;
    },
    enabled: !!user // Only run query when user is authenticated
  });

  // Add this console log to help debug render flow
  console.log("PricingManagementPage Render:", { isLoading, error, dataLength: pricingData?.length });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <Alert variant="destructive">
          <AlertDescription>
            Error loading pricing data: {(error as Error).message}
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  if (!pricingData || pricingData.length === 0) {
    return (
      <div className="p-6" style={{ background: 'white' }}>
        <h1 className="text-2xl font-bold mb-6">Medication Pricing Management</h1>
        <Alert>
          <AlertDescription>
            No pricing data available. Please contact an administrator to set up initial pricing.
          </AlertDescription>
        </Alert>
      </div>
    );
  }

  return (
    <div className="p-6" style={{ background: 'white' }}>
      <h1 className="text-2xl font-bold mb-6">Medication Pricing Management</h1>
      <Card>
        <CardHeader>
          <CardTitle>Current Pricing</CardTitle>
        </CardHeader>
        <CardContent>
          <PricingTable pricingData={pricingData} />
        </CardContent>
      </Card>
    </div>
  );
};

// Make sure we're exporting the component as both default and named export
export default PricingManagementPage;
