
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { formatCompletionTime } from "../utils/formatTime";

interface RenewalMetrics {
  totalDueForRenewal: number;
  renewedCount: number;
  renewalRate: number;
  renewalRevenue: number;
  newPatientRevenue: number;
  averageRenewalTime: string;
  churnRate: number;
  totalRenewals: number;
  manualRenewals: number;
  autoRenewals: number;
}

export const useRenewalMetrics = (startDate: Date, endDate: Date) => {
  return useQuery<RenewalMetrics>({
    queryKey: ["renewal-metrics", startDate, endDate],
    queryFn: async () => {
      console.log('Fetching renewal metrics for range:', { startDate, endDate });

      // Get all renewals (regardless of date range) to show total renewal count
      const { data: allTimeRenewals, error: allTimeError } = await supabase
        .from('assessments')
        .select('*')
        .or(`is_renewal.eq.true,renewal_from_assessment_id.not.is.null,and(previous_glp1.eq.true,recent_glp1.eq.true,previous_medication.not.is.null)`);

      if (allTimeError) {
        console.error('Error fetching all-time renewals:', allTimeError);
        throw allTimeError;
      }

      // Count manual vs auto renewals, including patients who transitioned from new to existing
      const manualRenewals = allTimeRenewals?.filter(a => 
        a.renewal_from_assessment_id || 
        (a.previous_glp1 && a.recent_glp1 && a.previous_medication)
      ).length || 0;
      
      const autoRenewals = allTimeRenewals?.filter(a => 
        a.is_renewal && !a.renewal_from_assessment_id
      ).length || 0;

      console.log('Total all-time renewals:', allTimeRenewals?.length);
      console.log('Manual renewals:', manualRenewals);
      console.log('Auto renewals:', autoRenewals);

      // Get assessments in the date range
      const { data: assessments, error } = await supabase
        .from('assessments')
        .select('*')
        .gte('created_at', startDate.toISOString())
        .lte('created_at', endDate.toISOString());

      if (error) {
        console.error('Error fetching assessments:', error);
        throw error;
      }

      // Separate renewals from new patients, including both auto and manual renewals
      // and patients who transitioned from new to existing
      const renewals = assessments?.filter(a => 
        a.is_renewal || 
        a.renewal_from_assessment_id || 
        (a.previous_glp1 && a.recent_glp1 && a.previous_medication)
      ) || [];

      const newPatients = assessments?.filter(a => 
        !a.is_renewal && 
        !a.renewal_from_assessment_id && 
        !(a.previous_glp1 && a.recent_glp1 && a.previous_medication)
      ) || [];

      console.log('Renewals in date range:', renewals.length);
      console.log('New patients in date range:', newPatients.length);

      // Calculate metrics
      const totalDueForRenewal = renewals.length;
      const renewedCount = renewals.filter(a => 
        ['completed', 'prescribed'].includes(a.status || '')
      ).length;

      console.log('Successfully renewed count:', renewedCount);

      const renewalRate = totalDueForRenewal > 0 
        ? (renewedCount / totalDueForRenewal) * 100 
        : 0;

      // Calculate revenue
      const renewalRevenue = renewals
        .filter(a => ['completed', 'prescribed'].includes(a.status || ''))
        .reduce((sum, a) => sum + Number(a.amount || 0), 0);

      const newPatientRevenue = newPatients
        .filter(a => ['completed', 'prescribed'].includes(a.status || ''))
        .reduce((sum, a) => sum + Number(a.amount || 0), 0);

      console.log('Renewal revenue:', renewalRevenue);
      console.log('New patient revenue:', newPatientRevenue);

      // Calculate average renewal time
      const renewalTimes = renewals
        .filter(a => ['completed', 'prescribed'].includes(a.status || ''))
        .map(a => {
          const startTime = new Date(a.created_at || '').getTime();
          const endTime = new Date(a.prescribed_at || a.completed_at || '').getTime();
          return endTime - startTime;
        })
        .filter(time => !isNaN(time));

      const averageRenewalTime = renewalTimes.length > 0
        ? formatCompletionTime(
            renewalTimes.reduce((sum, time) => sum + time, 0) / renewalTimes.length
          )
        : 'N/A';

      // Calculate churn rate
      const churnRate = totalDueForRenewal > 0
        ? ((totalDueForRenewal - renewedCount) / totalDueForRenewal) * 100
        : 0;

      return {
        totalDueForRenewal,
        renewedCount,
        renewalRate,
        renewalRevenue,
        newPatientRevenue,
        averageRenewalTime,
        churnRate,
        totalRenewals: allTimeRenewals?.length || 0,
        manualRenewals,
        autoRenewals
      };
    }
  });
};
