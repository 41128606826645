
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface OrderSummaryProps {
  medication: string;
  planType: string;
  amount: number;
  doseType?: "standard" | "max";
  discountedAmount?: number;
  appliedPromoCode?: string;
}

export const OrderSummary = ({ 
  medication, 
  planType, 
  amount,
  doseType,
  discountedAmount,
  appliedPromoCode
}: OrderSummaryProps) => {
  const capitalizedMedication = medication?.charAt(0).toUpperCase() + medication?.slice(1);
  
  // Extract months from plan type (e.g. "3 months" -> 3)
  const monthsMatch = planType.match(/^(\d+)/);
  const months = monthsMatch ? parseInt(monthsMatch[1]) : 1;
  
  // Use discounted amount if available, otherwise use original amount
  const finalAmount = discountedAmount || amount;
  const monthlyAmount = Math.round(finalAmount / months);

  // Calculate discount if applicable
  const discount = discountedAmount ? amount - discountedAmount : 0;
  const hasDiscount = discount > 0;

  console.log('OrderSummary displaying:', {
    medication,
    planType,
    amount,
    months,
    monthlyAmount,
    doseType,
    discountedAmount,
    appliedPromoCode,
    discount,
    hasDiscount
  });

  const getDoseText = () => {
    // For new patients or undefined dose type, always show ramping up message
    // This ensures new patient plans always show ramping message regardless of dose type
    if (planType.toLowerCase().includes('new patient') || !doseType || doseType === undefined) {
      return "ramping up: please reference provider instructions";
    }

    // For existing patients with a defined dose type
    if (medication.toLowerCase() === 'semaglutide') {
      if (doseType === 'max') {
        return "Max Dose: up to 2.5mg/week";
      } else if (doseType === 'standard') {
        return "Standard Dose: up to 1.25mg/week";
      }
    }
    if (medication.toLowerCase() === 'tirzepatide') {
      if (doseType === 'max') {
        return "Max Dose: up to 10mg/week";
      } else if (doseType === 'standard') {
        return "Standard Dose: up to 7.5mg/week";
      }
    }
    return "ramping up: please reference provider instructions";
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Order Summary</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid grid-cols-2 gap-2">
          <span className="text-muted-foreground">Medication:</span>
          <span className="font-medium">{capitalizedMedication}</span>
          
          <span className="text-muted-foreground">Dose:</span>
          <span className="font-medium">{getDoseText()}</span>
          
          <span className="text-muted-foreground">Plan:</span>
          <span className="font-medium">{planType}</span>
          
          {hasDiscount && (
            <>
              <span className="text-muted-foreground">Original Amount:</span>
              <span className="font-medium line-through text-muted-foreground">${amount}</span>
              
              {appliedPromoCode && (
                <>
                  <span className="text-muted-foreground">Promo Code:</span>
                  <span className="font-medium text-green-600">{appliedPromoCode.toUpperCase()} (-${discount})</span>
                </>
              )}
            </>
          )}
          
          <span className="text-muted-foreground">Total Amount:</span>
          <span className="font-medium">${finalAmount}</span>

          <span className="text-muted-foreground">Monthly Payment:</span>
          <span className="font-medium">${monthlyAmount}/month</span>
        </div>

        <div className="border-t pt-4 mt-4 space-y-2">
          <h4 className="font-medium">What's Next?</h4>
          <ul className="list-disc list-inside space-y-1 text-muted-foreground">
            <li>Our medical provider will review your information within 24-48 business hours</li>
            <li>Your credit card has been preauthorized, and the payment will finalize once you have been approved for the medication</li>
            <li>You'll receive an email confirmation once your prescription is approved</li>
            <li>Your medication will be shipped directly to your address</li>
            <li>Track your order status in your dashboard</li>
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};
