
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "@/integrations/supabase/client"

export const useAuthCheck = () => {
  const [authChecked, setAuthChecked] = useState(false)
  const [isProvider, setIsProvider] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser()
        
        if (error) {
          console.error("Auth error:", error)
          setIsProvider(false)
          setAuthChecked(true)
          return
        }

        if (!user) {
          console.log("No user found")
          setIsProvider(false)
          setAuthChecked(true)
          return
        }

        // Check user metadata first
        console.log("User metadata check:", user.user_metadata)
        const isProviderMeta = user.user_metadata?.is_provider === true
        
        // Also check the profiles table as a fallback
        if (!isProviderMeta) {
          console.log("Provider status not found in metadata, checking profiles table")
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('is_provider')
            .eq('id', user.id)
            .single()
            
          if (!profileError && profile && profile.is_provider) {
            console.log("Provider status found in profiles table")
            setIsProvider(true)
            setAuthChecked(true)
            return
          } else {
            console.log("Not a provider in profiles table either:", profileError, profile)
          }
        } else {
          console.log("User is a provider according to metadata")
        }
        
        setIsProvider(isProviderMeta)
        setAuthChecked(true)
      } catch (error) {
        console.error("Auth check error:", error)
        setIsProvider(false)
        setAuthChecked(true)
      }
    }

    checkAuth()
  }, [navigate])

  return { authChecked, isProvider }
}
