import { Assessment } from "@/types/database/assessment"
import { Button } from "@/components/ui/button"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible"
import { ChevronDown, ChevronUp } from "lucide-react"
import { useState } from "react"
import { useAssessmentDetails } from "@/components/provider/hooks/useAssessmentDetails"
import { PatientHeader } from "./patient-info/PatientHeader"
import { PreviousAssessments } from "./patient-info/PreviousAssessments"
import { PatientDetails } from "./patient-info/PatientDetails"
import { MedicalInfo } from "./patient-info/MedicalInfo"

interface PatientInfoProps {
  assessment: Assessment
}

export const PatientInfo = ({ assessment }: PatientInfoProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: patientAssessments } = useAssessmentDetails(assessment.user_id)

  return (
    <div className="border-b bg-background">
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <div className="p-4 flex items-center justify-between bg-background relative z-50">
          <PatientHeader assessment={assessment} />
          <CollapsibleTrigger asChild>
            <Button 
              variant="secondary"
              size="icon"
              className="bg-secondary hover:bg-accent relative z-50 shadow-md"
            >
              {isOpen ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </Button>
          </CollapsibleTrigger>
        </div>

        <CollapsibleContent className="relative z-40">
          <div className="p-4 bg-background border-t">
            {patientAssessments && (
              <PreviousAssessments 
                currentAssessmentId={assessment.id} 
                assessments={patientAssessments} 
              />
            )}
            
            <div className="grid grid-cols-2 gap-x-8 gap-y-6">
              <PatientDetails assessment={assessment} />
              <MedicalInfo assessment={assessment} />
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}