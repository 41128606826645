import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { DoseType } from "./types";
import { PricingData } from "./types";

interface PricingSelectionProps {
  selectedMedication: string;
  pricingData: PricingData[];
  onPlanSelect: (plan: string, doseType: DoseType, amount: number) => void;
}

export const usePricingSelection = ({ 
  selectedMedication, 
  pricingData,
  onPlanSelect 
}: PricingSelectionProps) => {
  const { toast } = useToast();

  const handlePlanSelect = async (plan: string, doseType: DoseType) => {
    try {
      if (!plan || typeof plan !== 'string') {
        console.error('Invalid plan selected:', plan);
        toast({
          title: "Error",
          description: "Invalid plan selected. Please try again.",
          variant: "destructive",
        });
        return;
      }

      const monthsMatch = plan.match(/^(\d+)/);
      if (!monthsMatch) {
        console.error('Invalid plan format:', plan);
        toast({
          title: "Error",
          description: "Invalid plan format. Please try again.",
          variant: "destructive",
        });
        return;
      }

      const months = parseInt(monthsMatch[1]);
      const selectedPricing = pricingData.find(p => p.duration_months === months);

      if (!selectedPricing) {
        console.error('No pricing found for plan:', { plan, months, doseType });
        toast({
          title: "Error",
          description: "Selected plan pricing not found. Please try again.",
          variant: "destructive",
        });
        return;
      }

      console.log('Selected pricing:', {
        plan,
        months,
        doseType,
        pricing: selectedPricing
      });

      // Get the current draft assessment
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        return;
      }

      const { data: draftAssessment, error: fetchError } = await supabase
        .from('assessments')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'draft')
        .single();

      if (fetchError) {
        console.error('Error fetching draft assessment:', fetchError);
        return;
      }

      if (draftAssessment) {
        // Update the plan selection in the draft assessment
        const { error: updateError } = await supabase
          .from('assessments')
          .update({ 
            plan_type: plan,
            amount: selectedPricing.total_price,
            dose_type: doseType,
            medication: selectedMedication
          })
          .eq('id', draftAssessment.id)
          .select()
          .single();

        if (updateError) {
          console.error('Error updating plan selection:', updateError);
          toast({
            title: "Error",
            description: "Failed to save plan selection. Please try again.",
            variant: "destructive",
          });
          return;
        }

        console.log('Successfully saved plan selection:', {
          plan,
          amount: selectedPricing.total_price,
          doseType
        });
      }

      // Call the onPlanSelect callback with the selected plan details
      onPlanSelect(plan, doseType, selectedPricing.total_price);
    } catch (error) {
      console.error('Error in handlePlanSelect:', error);
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        variant: "destructive",
      });
    }
  };

  return { handlePlanSelect };
};