import { useState } from "react"
import { Assessment } from "@/types/database/assessment"
import { useMessages } from "@/hooks/messages/useMessages"
import { MessageList } from "@/components/patient/messages/MessageList"
import { MessageInput } from "@/components/messages/MessageInput"
import { useAuth } from "@/hooks/useAuth"
import { ScrollArea } from "@/components/ui/scroll-area"

interface MessageThreadProps {
  assessment: Assessment
}

export const MessageThread = ({ assessment }: MessageThreadProps) => {
  const { messages, sendMessage, isLoading } = useMessages(assessment.id)
  const [newMessage, setNewMessage] = useState("")
  const { user } = useAuth()

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return
    await sendMessage(newMessage)
    setNewMessage("")
  }

  return (
    <div className="flex flex-col h-full border rounded-lg">
      <ScrollArea className="flex-1 h-[calc(100vh-200px)]">
        <div className="p-4">
          <MessageList messages={messages} currentUserId={user?.id} />
        </div>
      </ScrollArea>
      <div className="p-4 border-t mt-auto">
        <MessageInput
          value={newMessage}
          onChange={setNewMessage}
          onSend={handleSendMessage}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}