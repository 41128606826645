import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { usePaymentContext } from "./payment/PaymentFormContext";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

export const PaymentFormFields = () => {
  const {
    paymentData,
    handleCardNumberChange,
    handleExpirationDateChange,
    handleCardCodeChange,
    handleSaveCardChange,
    handleFirstNameChange,
    handleLastNameChange,
  } = usePaymentContext();

  // Add query for payment methods
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useQuery({
    queryKey: ["payment-methods"],
    queryFn: async () => {
      console.log('Fetching saved payment methods...');
      const { data, error } = await supabase
        .from("payment_methods")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) {
        console.error('Error fetching payment methods:', error);
        throw error;
      }

      console.log('Retrieved payment methods:', data?.length || 0);
      return data;
    },
  });

  console.log('DEBUG: Payment form data:', {
    firstName: paymentData.firstName,
    lastName: paymentData.lastName,
    savedMethods: paymentMethods?.length || 0,
    isLoadingPaymentMethods
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label htmlFor="firstName">First Name *</Label>
          <Input
            id="firstName"
            placeholder="John"
            value={paymentData.firstName}
            onChange={handleFirstNameChange}
            required
            className="w-full"
          />
        </div>
        <div>
          <Label htmlFor="lastName">Last Name *</Label>
          <Input
            id="lastName"
            placeholder="Doe"
            value={paymentData.lastName}
            onChange={handleLastNameChange}
            required
            className="w-full"
          />
        </div>
      </div>
      <div>
        <Label htmlFor="cardNumber">Card Number *</Label>
        <Input
          id="cardNumber"
          placeholder="1234 5678 9012 3456"
          value={paymentData.cardNumber}
          onChange={handleCardNumberChange}
          maxLength={19}
          required
        />
      </div>
      <div>
        <Label htmlFor="expirationDate">Expiration Date (MM/YY) *</Label>
        <Input
          id="expirationDate"
          placeholder="MM/YY"
          value={paymentData.expirationDate}
          onChange={handleExpirationDateChange}
          maxLength={5}
          required
        />
      </div>
      <div>
        <Label htmlFor="cardCode">CVV *</Label>
        <Input
          id="cardCode"
          placeholder="123"
          value={paymentData.cardCode}
          onChange={handleCardCodeChange}
          maxLength={4}
          required
        />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="flex items-center space-x-2">
          <Checkbox
            id="saveCard"
            checked={paymentData.saveCard}
            onCheckedChange={handleSaveCardChange}
          />
          <Label htmlFor="saveCard" className="text-sm font-normal">
            Save this card for future payments
          </Label>
        </div>
        <span className="text-xs text-muted-foreground ml-6">
          (please disable if running into card denial issues)
        </span>
      </div>
    </>
  );
};