import { Assessment } from "../types";
import { useState } from "react";
import { OrderDetailsModal } from "./OrderDetailsModal";
import { OrderHistoryHeader } from "./OrderHistoryHeader";
import { OrderHistoryTable } from "./OrderHistoryTable";

interface RenewalHistorySectionProps {
  assessment: Assessment;
  allAssessments: Assessment[];
}

export const RenewalHistorySection = ({ assessment, allAssessments }: RenewalHistorySectionProps) => {
  const [selectedOrder, setSelectedOrder] = useState<Assessment | null>(null);

  // Get all assessments for this patient, including renewals
  const patientAssessments = allAssessments.filter(a => 
    a.user_id === assessment.user_id
  );
  
  console.log('All patient assessments:', patientAssessments);

  // Calculate total lifetime spend from all completed/prescribed assessments
  const lifetimeSpend = patientAssessments
    .filter(a => ['completed', 'prescribed'].includes(a.status || ''))
    .reduce((total, curr) => total + (curr.amount || 0), 0);

  return (
    <div className="space-y-4">
      <OrderHistoryHeader 
        lifetimeSpend={lifetimeSpend}
        totalOrders={patientAssessments.length}
      />

      <OrderHistoryTable 
        assessments={patientAssessments}
        onSelectOrder={setSelectedOrder}
      />

      <OrderDetailsModal 
        assessment={selectedOrder}
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(null)}
      />
    </div>
  );
};