import { Assessment } from "@/types/database/assessment"

interface MedicalInfoProps {
  assessment: Assessment;
}

export const MedicalInfo = ({ assessment }: MedicalInfoProps) => {
  const formatBoolean = (value: boolean | null | undefined) => {
    if (value === null || value === undefined) return "Not specified";
    return value ? "Yes" : "No";
  };

  return (
    <div>
      <h3 className="font-medium mb-2">Medical Information</h3>
      <div className="space-y-3 text-sm">
        <div>
          <p className="text-muted-foreground">Height</p>
          <p>{assessment.patient_height ? `${Math.floor(assessment.patient_height / 12)}'${assessment.patient_height % 12}"` : 'Not provided'}</p>
        </div>
        <div>
          <p className="text-muted-foreground">Weight</p>
          <p>{assessment.patient_weight ? `${assessment.patient_weight} lbs` : 'Not provided'}</p>
        </div>
        <div>
          <p className="text-muted-foreground">Exercise Activity</p>
          <p className="capitalize">{assessment.exercise_activity || 'Not provided'}</p>
        </div>
        <div>
          <p className="text-muted-foreground">Medical Conditions</p>
          {assessment.medical_conditions && assessment.medical_conditions.length > 0 ? (
            <ul className="list-disc pl-4">
              {assessment.medical_conditions.map((condition, index) => (
                <li key={index}>{condition}</li>
              ))}
            </ul>
          ) : (
            <p>None reported</p>
          )}
        </div>
        {assessment.other_medical_conditions && (
          <div>
            <p className="text-muted-foreground">Other Medical Conditions</p>
            <p>{assessment.other_medical_conditions}</p>
          </div>
        )}
        <div>
          <p className="text-muted-foreground">Taking Medications</p>
          <p>{formatBoolean(assessment.taking_medications)}</p>
          {assessment.taking_medications && assessment.medications_list && (
            <p className="mt-1">{assessment.medications_list}</p>
          )}
        </div>
        <div>
          <p className="text-muted-foreground">Previous GLP-1 Medications</p>
          <p>{formatBoolean(assessment.previous_glp1)}</p>
          {assessment.previous_glp1 && (
            <p className="mt-1">Recent (8 weeks): {formatBoolean(assessment.recent_glp1)}</p>
          )}
        </div>
        <div>
          <p className="text-muted-foreground">Allergies</p>
          <p>{formatBoolean(assessment.has_allergies)}</p>
          {assessment.has_allergies && assessment.allergies_list && (
            <p className="mt-1">{assessment.allergies_list}</p>
          )}
        </div>
        <div>
          <p className="text-muted-foreground">Taking Blood Thinners</p>
          <p>{formatBoolean(assessment.taking_blood_thinners)}</p>
        </div>
        <div>
          <p className="text-muted-foreground">Medical History</p>
          <div className="space-y-1 mt-1">
            <p>Medullary Thyroid Cancer: {formatBoolean(assessment.medullary_thyroid_cancer)}</p>
            <p>Family MTC History: {formatBoolean(assessment.family_mtc_history)}</p>
            <p>MEN2: {formatBoolean(assessment.men2)}</p>
            <p>Pregnant/Breastfeeding: {formatBoolean(assessment.pregnant_or_breastfeeding)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};