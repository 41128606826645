
import { useState, useEffect } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";

interface SalesBannerConfig {
  id: string;
  message: string;
  is_active: boolean;
  background_color: string;
  text_color: string;
}

// Default banner configuration
const DEFAULT_BANNER = {
  message: "Limited time: Use Belle10 for 10% off",
  is_active: true,
  background_color: "#9b87f5",
  text_color: "#ffffff"
};

export const SalesBannerManagement = () => {
  const [bannerConfig, setBannerConfig] = useState<SalesBannerConfig | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const fetchBannerConfig = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("sales_banner")
          .select("*")
          .limit(1)
          .single();

        if (error) {
          console.log("Error fetching banner for management:", error);
          
          // Check if it's a "no rows returned" error
          if (error.code === 'PGRST116') {
            console.log("No banner configuration found, creating default for management");
            
            // Insert default banner configuration
            const { data: insertedData, error: insertError } = await supabase
              .from("sales_banner")
              .insert(DEFAULT_BANNER)
              .select()
              .single();
              
            if (insertError) {
              console.error("Failed to insert default banner:", insertError);
              throw insertError;
            } else if (insertedData) {
              console.log("Successfully created default banner for management:", insertedData);
              setBannerConfig(insertedData);
              return;
            }
          } else {
            throw error;
          }
        }

        if (data) {
          console.log("Banner configuration loaded for management:", data);
          setBannerConfig(data);
        }
      } catch (error) {
        console.error("Error fetching sales banner config:", error);
        toast({
          title: "Error",
          description: "Failed to load banner configuration",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchBannerConfig();
  }, [toast]);

  const handleSave = async () => {
    if (!bannerConfig) return;

    setIsSaving(true);
    try {
      const { error } = await supabase
        .from("sales_banner")
        .update({
          message: bannerConfig.message,
          is_active: bannerConfig.is_active,
          background_color: bannerConfig.background_color,
          text_color: bannerConfig.text_color,
        })
        .eq("id", bannerConfig.id);

      if (error) {
        throw error;
      }

      toast({
        title: "Success",
        description: "Banner configuration updated",
      });
      
      // Show a user-friendly toast message that displays across all pages
      toast({
        title: "Banner updated",
        description: "Changes are now visible across all pages",
      });
      
    } catch (error) {
      console.error("Error updating sales banner config:", error);
      toast({
        title: "Error",
        description: "Failed to update banner configuration",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Sales Banner</CardTitle>
          <CardDescription>Loading banner configuration...</CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center py-6">
          <Loader2 className="h-6 w-6 animate-spin text-primary" />
        </CardContent>
      </Card>
    );
  }

  if (!bannerConfig) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Sales Banner</CardTitle>
          <CardDescription>Banner configuration not found</CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Sales Banner</CardTitle>
        <CardDescription>
          Configure the promotional banner displayed at the top of the application
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center justify-between space-x-2">
          <Label htmlFor="banner-active" className="flex flex-col space-y-1">
            <span>Enable Banner</span>
            <span className="font-normal text-sm text-muted-foreground">
              Show or hide the banner across all pages
            </span>
          </Label>
          <Switch
            id="banner-active"
            checked={bannerConfig.is_active}
            onCheckedChange={(checked) =>
              setBannerConfig((prev) => prev ? { ...prev, is_active: checked } : null)
            }
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="banner-message">Banner Message</Label>
          <Input
            id="banner-message"
            value={bannerConfig.message}
            onChange={(e) =>
              setBannerConfig((prev) => prev ? { ...prev, message: e.target.value } : null)
            }
            placeholder="Enter promotional message"
            maxLength={100}
          />
          <p className="text-xs text-muted-foreground">
            {bannerConfig.message.length}/100 characters
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label htmlFor="background-color">Background Color</Label>
            <div className="flex items-center space-x-2">
              <div
                className="h-8 w-8 rounded-md border"
                style={{ backgroundColor: bannerConfig.background_color }}
              />
              <Input
                id="background-color"
                type="text"
                value={bannerConfig.background_color}
                onChange={(e) =>
                  setBannerConfig((prev) => prev ? { ...prev, background_color: e.target.value } : null)
                }
                placeholder="#000000"
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="text-color">Text Color</Label>
            <div className="flex items-center space-x-2">
              <div
                className="h-8 w-8 rounded-md border"
                style={{ backgroundColor: bannerConfig.text_color }}
              />
              <Input
                id="text-color"
                type="text"
                value={bannerConfig.text_color}
                onChange={(e) =>
                  setBannerConfig((prev) => prev ? { ...prev, text_color: e.target.value } : null)
                }
                placeholder="#ffffff"
              />
            </div>
          </div>
        </div>

        <div className="pt-4">
          <Button onClick={handleSave} disabled={isSaving} className="w-full">
            {isSaving ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        </div>

        <div className="rounded-md border p-4 mt-4">
          <h4 className="text-sm font-medium mb-2">Preview</h4>
          <div
            className="p-3 rounded-md text-center"
            style={{
              backgroundColor: bannerConfig.background_color,
              color: bannerConfig.text_color,
            }}
          >
            {bannerConfig.message}
          </div>
          <p className="text-xs text-muted-foreground mt-2">
            Note: Updates will display across all pages of the application
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
