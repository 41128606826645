import { createContext, useContext, useState } from "react";

interface PaymentContextType {
  paymentData: {
    cardNumber: string;
    expirationDate: string;
    cardCode: string;
    saveCard: boolean;
    savedMethodId?: string;
    firstName: string;
    lastName: string;
  };
  handleCardNumberChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleExpirationDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCardCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveCardChange: (checked: boolean) => void;
  handleSavedMethodSelect: (methodId: string) => void;
  handleFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSavedMethod: () => void;
}

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export const PaymentFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [paymentData, setPaymentData] = useState({
    cardNumber: "",
    expirationDate: "",
    cardCode: "",
    saveCard: true,
    savedMethodId: undefined,
    firstName: "",
    lastName: "",
  });

  console.log('DEBUG: Payment context data:', paymentData);

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "").replace(/(.{4})/g, "$1 ").trim();
    setPaymentData((prev) => ({ ...prev, cardNumber: value }));
  };

  const handleExpirationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 4) {
      const formattedValue = value.replace(/(\d{2})(\d{2})/, "$1/$2");
      setPaymentData((prev) => ({ ...prev, expirationDate: formattedValue }));
    }
  };

  const handleCardCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "");
    setPaymentData((prev) => ({ ...prev, cardCode: value }));
  };

  const handleSaveCardChange = (checked: boolean) => {
    setPaymentData((prev) => ({ ...prev, saveCard: checked }));
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    console.log('DEBUG: First name changed:', value);
    setPaymentData((prev) => ({ ...prev, firstName: value }));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    console.log('DEBUG: Last name changed:', value);
    setPaymentData((prev) => ({ ...prev, lastName: value }));
  };

  const handleSavedMethodSelect = (methodId: string) => {
    setPaymentData((prev) => ({
      ...prev,
      savedMethodId: methodId,
      cardNumber: "",
      expirationDate: "",
      cardCode: "",
    }));
  };

  const clearSavedMethod = () => {
    setPaymentData((prev) => ({
      ...prev,
      savedMethodId: undefined,
    }));
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentData,
        handleCardNumberChange,
        handleExpirationDateChange,
        handleCardCodeChange,
        handleSaveCardChange,
        handleSavedMethodSelect,
        handleFirstNameChange,
        handleLastNameChange,
        clearSavedMethod,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error("usePaymentContext must be used within a PaymentFormProvider");
  }
  return context;
};