
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useQueryClient } from "@tanstack/react-query";

export const usePaymentSuccess = ({ formData, onSuccess }: { formData: any, onSuccess: (assessment: any) => void }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  
  const handlePaymentSuccess = async (assessmentId: string) => {
    try {
      console.log('Updating assessment status for ID:', assessmentId);
      
      // First verify current status
      const { data: currentAssessment, error: fetchError } = await supabase
        .from("assessments")
        .select("*")
        .eq("id", assessmentId)
        .single();

      if (fetchError || !currentAssessment) {
        console.error('Error fetching assessment:', fetchError);
        throw new Error('Assessment not found');
      }

      console.log('Current assessment status:', currentAssessment.status);

      const { data, error } = await supabase
        .from("assessments")
        .update({ status: "completed" })
        .eq("id", assessmentId)
        .select()
        .single();

      if (error) {
        console.error('Error updating assessment:', error);
        throw error;
      }

      console.log('Successfully updated assessment:', data);

      await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });

      // Send confirmation email with the new support email as reply-to
      const { data: { user } } = await supabase.auth.getUser();
      if (user?.email && data) {
        const { error: emailError } = await supabase.functions.invoke('send-confirmation-email', {
          body: {
            to: user.email,
            subscription: data,
            replyTo: "support@mybellehealth.com"
          },
        });

        if (emailError) {
          console.error("Error sending confirmation email:", emailError);
        } else {
          console.log("Confirmation email sent successfully");
        }
      }

      toast({
        title: "Payment successful",
        description: "Your assessment has been submitted for review.",
      });

      if (data) {
        onSuccess(data);
      }
    } catch (error) {
      console.error("Error updating assessment status:", error);
      toast({
        title: "Error",
        description: "Failed to update assessment status. Please contact support.",
        variant: "destructive",
      });
    }
  };

  return { handlePaymentSuccess };
};

export const PaymentSuccessHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const assessmentId = searchParams.get("assessment_id");

  useEffect(() => {
    const updateAssessmentStatus = async () => {
      if (!assessmentId) {
        console.error('No assessment ID found in URL parameters');
        return;
      }

      try {
        console.log('Updating assessment status for ID:', assessmentId);
        
        // First verify current status
        const { data: currentAssessment, error: fetchError } = await supabase
          .from("assessments")
          .select("*")
          .eq("id", assessmentId)
          .single();

        if (fetchError || !currentAssessment) {
          console.error('Error fetching assessment:', fetchError);
          throw new Error('Assessment not found');
        }

        console.log('Current assessment status:', currentAssessment.status);

        // Update the assessment status to completed
        const { data, error } = await supabase
          .from("assessments")
          .update({ status: "completed" })
          .eq("id", assessmentId)
          .select()
          .single();

        if (error) {
          console.error('Error updating assessment:', error);
          throw error;
        }

        console.log('Successfully updated assessment:', data);

        // Invalidate queries to refresh the data
        await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });

        // Send confirmation email with the new support email as reply-to
        const { data: { user } } = await supabase.auth.getUser();
        if (user?.email && data) {
          const { error: emailError } = await supabase.functions.invoke('send-confirmation-email', {
            body: {
              to: user.email,
              subscription: data,
              replyTo: "support@mybellehealth.com"
            },
          });

          if (emailError) {
            console.error("Error sending confirmation email:", emailError);
          } else {
            console.log("Confirmation email sent successfully");
          }
        }

        toast({
          title: "Payment successful",
          description: "Your assessment has been submitted for review.",
        });

        // Navigate to confirmation screen with assessment data
        navigate("/confirmation", { 
          replace: true,
          state: { 
            subscription: data 
          }
        });
      } catch (error) {
        console.error("Error updating assessment status:", error);
        toast({
          title: "Error",
          description: "Failed to update assessment status. Please contact support.",
          variant: "destructive",
        });
        // Navigate to dashboard on error
        navigate("/dashboard", { replace: true });
      }
    };

    updateAssessmentStatus();
  }, [assessmentId, navigate, toast, queryClient]);

  return null;
};
