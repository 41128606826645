
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { PromoCode } from "./types";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { Checkbox } from "@/components/ui/checkbox";

const formSchema = z.object({
  code: z.string().min(3, "Code must be at least 3 characters"),
  description: z.string().optional(),
  discount_type: z.enum(["fixed", "percentage"]),
  discount_amount: z.coerce.number().positive("Discount must be positive"),
  max_uses: z.coerce.number().min(1, "Maximum uses must be at least 1").default(100).optional(),
  is_unlimited: z.boolean().default(false),
  is_active: z.boolean().default(true),
  expiration_date: z.date().nullable().default(null),
});

type FormValues = z.infer<typeof formSchema>;

interface PromoCodeDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  promoCode?: PromoCode | null;
  onSuccess: () => void;
}

export const PromoCodeDialog = ({
  open,
  onOpenChange,
  promoCode,
  onSuccess,
}: PromoCodeDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEditing = !!promoCode;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: promoCode?.code || "",
      description: promoCode?.description || "",
      discount_type: promoCode?.discount_type || "fixed",
      discount_amount: promoCode?.discount_amount || 0,
      max_uses: promoCode?.max_uses || 100,
      is_unlimited: promoCode?.max_uses === null,
      is_active: promoCode?.is_active ?? true,
      expiration_date: promoCode?.expiration_date ? new Date(promoCode.expiration_date) : null,
    },
  });

  console.log("PromoCodeDialog form defaults:", form.getValues());

  const onSubmit = async (values: FormValues) => {
    console.log("Form submission values:", values);
    
    try {
      setIsSubmitting(true);
      
      // Format the data for Supabase
      const formattedData = {
        code: values.code,
        description: values.description || null,
        discount_type: values.discount_type,
        discount_amount: values.discount_amount,
        max_uses: values.is_unlimited ? null : values.max_uses, // Set to null if unlimited is checked
        is_active: values.is_active,
        expiration_date: values.expiration_date ? values.expiration_date.toISOString() : null,
        updated_at: new Date().toISOString(),
      };
      
      console.log("Formatted data for Supabase:", formattedData);
      
      let result;
      
      if (isEditing && promoCode) {
        // Update existing promo code
        result = await supabase
          .from("promo_codes")
          .update(formattedData)
          .eq("id", promoCode.id);
      } else {
        // Create new promo code
        result = await supabase
          .from("promo_codes")
          .insert({
            ...formattedData,
            created_at: new Date().toISOString(),
            usage_count: 0,
          });
      }
      
      const { error } = result;
      
      if (error) {
        console.error("Supabase error:", error);
        throw error;
      }
      
      toast.success(isEditing ? "Promo code updated successfully" : "Promo code created successfully");
      onSuccess();
      onOpenChange(false);
    } catch (error) {
      console.error("Error saving promo code:", error);
      toast.error(`Failed to ${isEditing ? "update" : "create"} promo code`);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Watch the is_unlimited field to update form behavior
  const isUnlimited = form.watch("is_unlimited");

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>{isEditing ? "Edit" : "Create"} Promo Code</DialogTitle>
          <DialogDescription>
            {isEditing
              ? "Update the details of this promotional code."
              : "Create a new promotional code for your customers."}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Code</FormLabel>
                  <FormControl>
                    <Input placeholder="WELCOME10" {...field} />
                  </FormControl>
                  <FormDescription>
                    The code customers will enter at checkout.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description (Optional)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="10% off for new customers"
                      {...field}
                      value={field.value || ""}
                    />
                  </FormControl>
                  <FormDescription>
                    Internal description for this promo code.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="discount_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Discount Type</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="fixed">Fixed Amount ($)</SelectItem>
                        <SelectItem value="percentage">Percentage (%)</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="discount_amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Discount Amount</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step={
                          form.watch("discount_type") === "percentage"
                            ? "1"
                            : "0.01"
                        }
                        min="0"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="is_unlimited"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Unlimited Uses</FormLabel>
                    <FormDescription>
                      Allow this code to be used an unlimited number of times while active.
                    </FormDescription>
                  </div>
                </FormItem>
              )}
            />

            {!isUnlimited && (
              <FormField
                control={form.control}
                name="max_uses"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Maximum Uses</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        min="1"
                        step="1"
                        placeholder="100"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      Maximum number of times this code can be used.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="expiration_date"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Expiration Date (Optional)</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={`w-full justify-start text-left font-normal ${
                            !field.value && "text-muted-foreground"
                          }`}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>No expiration</span>
                          )}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value || undefined}
                        onSelect={field.onChange}
                        initialFocus
                        disabled={(date) =>
                          date < new Date(new Date().setHours(0, 0, 0, 0))
                        }
                      />
                    </PopoverContent>
                  </Popover>
                  <FormDescription>
                    The date after which this code will no longer be valid.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="is_active"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Active Status</FormLabel>
                    <FormDescription>
                      Enable or disable this promo code.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button 
                type="button" 
                variant="outline" 
                onClick={() => onOpenChange(false)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <span className="animate-spin mr-2">⟳</span>
                    {isEditing ? "Updating..." : "Creating..."}
                  </>
                ) : (
                  <>{isEditing ? "Update" : "Create"} Promo Code</>
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
