
import { useState, useEffect } from "react"
import { supabase } from "@/integrations/supabase/client"
import { UnreadCount, MessageCount } from "@/types/messaging"
import { useToast } from "@/hooks/use-toast"

export const useUnreadCounts = (userId: string | undefined) => {
  const [unreadCounts, setUnreadCounts] = useState<Record<string, number>>({})
  const [isLoading, setIsLoading] = useState(true)
  const { toast } = useToast()

  useEffect(() => {
    if (!userId) {
      setIsLoading(false)
      return
    }

    const fetchUnreadCounts = async () => {
      try {
        setIsLoading(true)
        console.log('Fetching unread counts for user:', userId)
        
        // First, get all assessments that are eligible for chat
        const { data: assessmentData, error: assessmentError } = await supabase
          .from("assessments")
          .select("id")
          .or('status.eq.completed,status.eq.prescribed')

        if (assessmentError) {
          console.error("Error fetching assessments for unread counts:", assessmentError)
          toast({
            title: "Error loading unread counts",
            description: "Could not fetch message unread counts. Please try again.",
            variant: "destructive",
          })
          setIsLoading(false)
          return
        }

        // If no assessments found, set empty counts and return
        if (!assessmentData || assessmentData.length === 0) {
          console.log('No assessments found for unread counts')
          setUnreadCounts({})
          setIsLoading(false)
          return
        }

        const assessmentIds = assessmentData.map((a) => a.id)
        console.log('Assessment IDs for unread counts:', assessmentIds.length)
        
        // Initialize the counts object with zeros
        const initialCounts: Record<string, number> = {}
        assessmentIds.forEach(id => {
          initialCounts[id] = 0
        })
        
        // Process in smaller batches
        const batchSize = 100;
        let allUnreadCounts: UnreadCount[] = [];
        
        for (let i = 0; i < assessmentIds.length; i += batchSize) {
          const batchIds = assessmentIds.slice(i, i + batchSize);
          console.log(`Fetching unread counts batch ${i/batchSize + 1}/${Math.ceil(assessmentIds.length/batchSize)}`);
          
          // Get unread message counts for each assessment
          const { data: batchData, error: batchError } = await supabase
            .rpc('get_unread_message_counts_for_user', {
              user_id: userId,
              assessment_ids: batchIds
            });
            
          if (batchError) {
            console.error(`Error fetching unread counts batch ${i/batchSize + 1}:`, batchError);
            continue; // Continue with next batch instead of failing completely
          }
          
          if (batchData) {
            allUnreadCounts = [...allUnreadCounts, ...batchData];
          }
        }

        console.log('Fetched unread counts:', allUnreadCounts.length);
        
        // Convert DB response to the expected format
        const countsMap = allUnreadCounts.reduce(
          (acc: Record<string, number>, count: UnreadCount) => {
            acc[count.assessment_id] = Number(count.count)
            return acc
          },
          initialCounts
        )

        console.log('Final unread counts map:', Object.keys(countsMap).length);
        setUnreadCounts(countsMap)
        setIsLoading(false)
      } catch (error) {
        console.error("Unexpected error in fetchUnreadCounts:", error)
        toast({
          title: "Error loading unread counts",
          description: "Could not fetch message unread counts. Please try again.",
          variant: "destructive",
        })
        setIsLoading(false)
      }
    }

    // Initial fetch
    fetchUnreadCounts()

    // Subscribe to messages table changes
    const messageChannel = supabase
      .channel("messages-read-status")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "messages",
        },
        (payload: any) => {
          const message = payload.new
          // Only update unread count for messages sent to the current user
          if (message.sender_id !== userId && message.read_at === null) {
            setUnreadCounts((prev) => ({
              ...prev,
              [message.assessment_id]: (prev[message.assessment_id] || 0) + 1,
            }))
          }
        }
      )
      .on(
        "postgres_changes",
        {
          event: "UPDATE",
          schema: "public",
          table: "messages",
          filter: `read_at=is.not.null`,
        },
        (payload: any) => {
          const message = payload.new
          // Message was marked as read, but only update if it was previously unread
          if (payload.old.read_at === null) {
            setUnreadCounts((prev) => ({
              ...prev,
              [message.assessment_id]: Math.max(0, (prev[message.assessment_id] || 0) - 1),
            }))
          }
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(messageChannel)
    }
  }, [userId, toast])

  return { unreadCounts, setUnreadCounts, isLoading }
}
