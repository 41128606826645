import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { formatCurrency } from "@/utils/formatCurrency";
import { useRenewalMetrics } from "./useRenewalMetrics";
import { DatePickerWithRange } from "@/components/ui/date-range-picker";
import { DateRange } from "react-day-picker";
import { useState } from "react";

interface RenewalMetricsCardProps {
  startDate: Date;
  endDate: Date;
}

export const RenewalMetricsCard = ({ startDate: defaultStartDate, endDate: defaultEndDate }: RenewalMetricsCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: defaultStartDate,
    to: defaultEndDate
  });

  const { data: metrics, isLoading } = useRenewalMetrics(
    dateRange?.from || defaultStartDate,
    dateRange?.to || defaultEndDate
  );

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Renewal Performance</CardTitle>
          <CardDescription>Track renewal rates and revenue</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-8">
            <Skeleton className="h-[200px] w-full" />
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>Renewal Performance</CardTitle>
            <CardDescription>Track renewal rates and revenue</CardDescription>
          </div>
          <DatePickerWithRange
            date={dateRange}
            setDate={setDateRange}
          />
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-8">
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Total All-Time Renewals</p>
                <p className="text-2xl font-bold">{metrics?.totalRenewals || 0}</p>
                <div className="text-sm text-muted-foreground">
                  <span>Auto: {metrics?.autoRenewals || 0}</span>
                  <span className="mx-2">|</span>
                  <span>Manual: {metrics?.manualRenewals || 0}</span>
                </div>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Due for Renewal (Period)</p>
                <p className="text-2xl font-bold">{metrics?.totalDueForRenewal || 0}</p>
                <p className="text-sm text-muted-foreground">
                  Renewed: {metrics?.renewedCount || 0}
                </p>
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-muted-foreground">Renewal Rate</p>
              <p className="text-2xl font-bold">
                {metrics?.renewalRate.toFixed(1)}%
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Renewal Revenue</p>
                <p className="text-2xl font-bold">
                  {formatCurrency(metrics?.renewalRevenue || 0)}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">New Patient Revenue</p>
                <p className="text-2xl font-bold">
                  {formatCurrency(metrics?.newPatientRevenue || 0)}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Average Renewal Time</p>
                <p className="text-2xl font-bold">{metrics?.averageRenewalTime}</p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Churn Rate</p>
                <p className="text-2xl font-bold">{metrics?.churnRate.toFixed(1)}%</p>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};