
import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DatePickerWithRange } from "@/components/ui/date-range-picker";
import { startOfWeek, endOfWeek } from "date-fns";
import { useConversionMetrics } from "./useConversionMetrics";
import { Loader } from "lucide-react";
import { DateRange } from "react-day-picker";

export const ConversionTrackingCard = () => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: startOfWeek(new Date(), { weekStartsOn: 1 }), // Start from Monday
    to: endOfWeek(new Date(), { weekStartsOn: 1 }), // End on Sunday
  });

  const { data: metrics, isLoading } = useConversionMetrics(
    date?.from || startOfWeek(new Date(), { weekStartsOn: 1 }), 
    date?.to || endOfWeek(new Date(), { weekStartsOn: 1 })
  );

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle>Conversion Analytics</CardTitle>
          <DatePickerWithRange date={date} setDate={setDate} />
        </div>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="flex justify-center py-8">
            <Loader className="h-6 w-6 animate-spin" />
          </div>
        ) : (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Total New Drafts</p>
                <p className="text-2xl font-bold">{metrics?.totalDrafts || 0}</p>
              </div>
              <div className="space-y-2">
                <p className="text-sm text-muted-foreground">Converted to Paying</p>
                <p className="text-2xl font-bold">{metrics?.convertedCount || 0}</p>
              </div>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-muted-foreground">Conversion Rate</p>
              <p className="text-2xl font-bold">
                {metrics?.conversionRate.toFixed(1)}%
              </p>
              <p className="text-sm text-muted-foreground">
                {metrics?.convertedCount} out of {metrics?.totalDrafts} drafts converted to paying patients
              </p>
            </div>
            <div className="border-t pt-4">
              <h4 className="font-semibold mb-4">Lead Time Analysis</h4>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <p className="text-sm text-muted-foreground">Average Time</p>
                  <p className="text-lg font-semibold">{metrics?.averageLeadTime}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Fastest</p>
                  <p className="text-lg font-semibold">{metrics?.fastestConversion}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Slowest</p>
                  <p className="text-lg font-semibold">{metrics?.slowestConversion}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
