import { Button } from "@/components/ui/button";

interface StepsNavigationProps {
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onPrevious: () => void;
  isNextDisabled?: boolean;
  nextButtonText?: string;
}

export const StepsNavigation = ({
  currentStep,
  totalSteps,
  onNext,
  onPrevious,
  isNextDisabled,
  nextButtonText = "Continue"
}: StepsNavigationProps) => {
  // Don't render navigation buttons on the payment step (step 8)
  if (currentStep === 8) {
    return null;
  }

  return (
    <div className="flex justify-between mt-6">
      {currentStep > 2 && (
        <Button
          variant="outline"
          onClick={onPrevious}
        >
          Previous
        </Button>
      )}
      <div className="flex-1" />
      {currentStep < totalSteps && (
        <Button
          onClick={onNext}
          disabled={isNextDisabled}
        >
          {currentStep === 5 ? "Select Your Plan" : nextButtonText}
        </Button>
      )}
    </div>
  );
};