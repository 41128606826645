
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { AssessmentsTable } from "./AssessmentsTable"
import { Assessment } from "./types"
import { Button } from "@/components/ui/button"
import { Download } from "lucide-react"
import { downloadCSV } from "@/utils/csvExport"
import { useAssessments } from "./hooks/useAssessments"
import { useStatusUpdate } from "./hooks/useStatusUpdate"
import { useAuthCheck } from "./hooks/useAuthCheck"
import { EmptyState } from "./EmptyState"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"

export const StatusTabs = () => {
  const { authChecked, isProvider } = useAuthCheck()
  const { 
    data, 
    isLoading, 
    fetchNextPage, 
    hasNextPage,
    isFetchingNextPage 
  } = useAssessments(isProvider, authChecked)

  const { ref, inView } = useInView()

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage])

  const { handleStatusUpdate } = useStatusUpdate()

  const allAssessments = data?.pages.flatMap(page => page.assessments) ?? []
  
  const draftAssessments = allAssessments.filter(a => a.status === "draft")
  const completedAssessments = allAssessments.filter(a => a.status === "completed")
  const prescribedAssessments = allAssessments.filter(a => a.status === "prescribed")
  const deniedAssessments = allAssessments.filter(a => a.status === "denied")

  const handleDownload = async (status: string) => {
    const timestamp = new Date().toISOString().split('T')[0];
    await downloadCSV(status.toLowerCase() as 'draft' | 'completed' | 'prescribed' | 'denied', 
      `${status}-assessments-${timestamp}.csv`);
  };

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!allAssessments.length) {
    return <EmptyState />
  }

  const renderTabHeader = (label: string, count: number, showCounter: boolean = false) => (
    <div className="relative flex items-center gap-2">
      <span>{label}</span>
      {showCounter && count > 0 && (
        <span className="absolute -top-2 -right-2 bg-primary text-primary-foreground w-5 h-5 rounded-full text-xs flex items-center justify-center">
          {count}
        </span>
      )}
      {count > 0 && (
        <Button
          variant="ghost"
          size="sm"
          className="ml-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDownload(label);
          }}
        >
          <Download className="h-4 w-4" />
        </Button>
      )}
    </div>
  );

  return (
    <Tabs defaultValue="completed" className="w-full">
      <TabsList className="grid w-full grid-cols-4">
        <TabsTrigger value="completed">
          {renderTabHeader("Completed", completedAssessments.length, true)}
        </TabsTrigger>
        <TabsTrigger value="prescribed">
          {renderTabHeader("Prescribed", prescribedAssessments.length)}
        </TabsTrigger>
        <TabsTrigger value="denied">
          {renderTabHeader("Denied", deniedAssessments.length)}
        </TabsTrigger>
        <TabsTrigger value="draft">
          {renderTabHeader("Draft", draftAssessments.length)}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="completed">
        <AssessmentsTable 
          assessments={completedAssessments} 
          showActions={true}
          onStatusUpdate={handleStatusUpdate}
          showDenialReason={false}
        />
      </TabsContent>
      <TabsContent value="prescribed">
        <AssessmentsTable 
          assessments={prescribedAssessments} 
          showActions={false}
          showDenialReason={false}
        />
      </TabsContent>
      <TabsContent value="denied">
        <AssessmentsTable 
          assessments={deniedAssessments} 
          showActions={true}
          onStatusUpdate={handleStatusUpdate}
          showDenialReason={true}
        />
      </TabsContent>
      <TabsContent value="draft">
        <AssessmentsTable 
          assessments={draftAssessments} 
          showActions={false}
          showDenialReason={false}
        />
      </TabsContent>

      {hasNextPage && (
        <div ref={ref} className="mt-4 flex justify-center">
          {isFetchingNextPage ? (
            <div>Loading more...</div>
          ) : null}
        </div>
      )}
    </Tabs>
  )
}
