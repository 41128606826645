
import { useState } from "react"
import { supabase } from "@/integrations/supabase/client"
import { useToast } from "@/hooks/use-toast"
import { useAuth } from "@/hooks/useAuth"

export const useChatMarkComplete = (
  setChatStatuses: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  setUnreadCounts: React.Dispatch<React.SetStateAction<Record<string, number>>>
) => {
  const [isMarkingComplete, setIsMarkingComplete] = useState(false)
  const { toast } = useToast()
  const { user } = useAuth()

  const handleMarkComplete = async (assessmentId: string) => {
    if (!user?.user_metadata?.is_provider) {
      toast({
        title: "Error",
        description: "Only providers can mark chats as complete",
        variant: "destructive",
      })
      return
    }

    if (isMarkingComplete) return
    setIsMarkingComplete(true)

    try {
      console.log(`Marking chat ${assessmentId} as complete`)
      
      // First, ensure all messages for this assessment are marked as read
      const { error: markReadError } = await supabase
        .from("messages")
        .update({ read_at: new Date().toISOString() })
        .eq("assessment_id", assessmentId)
        .is("read_at", null)
      
      if (markReadError) {
        console.error("Error marking messages as read:", markReadError)
        toast({
          title: "Error marking messages as read",
          description: "Some messages may still appear as unread",
          variant: "destructive",
        })
      }
      
      // Check if a chat_status record already exists
      const { data: existingStatus, error: fetchError } = await supabase
        .from("chat_status")
        .select("*")
        .eq("assessment_id", assessmentId)
        .maybeSingle()

      if (fetchError) {
        console.error("Error checking existing chat status:", fetchError)
        throw new Error("Error checking chat status")
      }

      let updateError = null
      const timestamp = new Date().toISOString();
      
      // Handle based on whether the record exists
      if (existingStatus) {
        console.log(`Updating existing chat_status for assessment ${assessmentId}`)
        const { error } = await supabase
          .from("chat_status")
          .update({ 
            is_complete: true,
            completed_at: timestamp,
            updated_at: timestamp
          })
          .eq("assessment_id", assessmentId)
        
        updateError = error
      } else {
        console.log(`Inserting new chat_status for assessment ${assessmentId}`)
        const { error } = await supabase
          .from("chat_status")
          .insert({
            assessment_id: assessmentId,
            is_complete: true,
            completed_at: timestamp,
            updated_at: timestamp
          })
        
        updateError = error
      }
      
      if (updateError) {
        console.error("Error updating chat status:", updateError)
        throw new Error("Error updating chat status")
      }

      // Update local state immediately for better UI responsiveness
      setChatStatuses((prev) => {
        console.log("Updating chat status in state from mark complete:", assessmentId, true)
        return {
          ...prev,
          [assessmentId]: true,
        };
      })
      
      // Also update unread counts for this assessment
      setUnreadCounts((prev) => ({
        ...prev,
        [assessmentId]: 0,
      }))

      toast({
        title: "Chat marked as complete",
        description: "The chat has been archived",
      })
    } catch (error) {
      console.error("Unexpected error in handleMarkComplete:", error)
      toast({
        title: "Error",
        description: "Failed to mark chat as complete. Please try again.",
        variant: "destructive",
      })
    } finally {
      setIsMarkingComplete(false)
    }
  }

  return {
    handleMarkComplete,
    isMarkingComplete
  }
}
