
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";

const formSchema = z.object({
  userId: z.string().uuid("Please enter a valid UUID")
});

export const ProviderPermissionManager = () => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userId: "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsSubmitting(true);
    try {
      const { data, error } = await supabase.functions.invoke('make-provider-by-uuid', {
        body: { userId: values.userId },
      });

      if (error) throw error;
      
      console.log('Provider permissions update result:', data);
      
      toast({
        title: "Provider permissions updated",
        description: "Successfully made user a provider with full access",
      });
      
      form.reset();
    } catch (error) {
      console.error("Error updating provider permissions:", error);
      toast({
        title: "Error updating permissions",
        description: "There was a problem updating provider permissions. Check console for details.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow border border-border">
      <h2 className="text-xl font-semibold mb-4">Grant Provider Permissions</h2>
      <p className="mb-4 text-muted-foreground">Enter a user's UUID to make them a provider with full access.</p>
      
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="userId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>User UUID</FormLabel>
                <FormControl>
                  <Input 
                    placeholder="e.g. 97049c13-20f7-4e1d-a334-b8f5274c836c" 
                    {...field} 
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <Button 
            type="submit" 
            disabled={isSubmitting} 
            className="w-full"
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processing...
              </>
            ) : (
              "Make Provider"
            )}
          </Button>
        </form>
      </Form>
    </div>
  );
};
