
import { Button } from "@/components/ui/button";
import { Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import { getUtmParams } from "@/utils/utmUtils";

interface HeroProps {
  onStart: () => void;
  onLogin: () => void;
}

export const Hero = ({
  onStart,
  onLogin
}: HeroProps) => {
  const [isNeosPartner, setIsNeosPartner] = useState(false);
  
  useEffect(() => {
    // Check UTM source for Neos partnership
    const utmParams = getUtmParams();
    setIsNeosPartner(utmParams.utm_source === 'neos');
  }, []);

  return <div className="min-h-[90vh] flex flex-col justify-center text-center space-y-6 py-8 md:py-20">
      <div className="mb-6 md:mb-12">
        {isNeosPartner ? (
          // Display partnership logos side by side
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center gap-4 mb-2">
              <img 
                src="/lovable-uploads/b43b0327-220f-4b66-903d-09f5add72b5e.png" 
                alt="NeosRx" 
                className="w-auto h-12 md:h-16" 
              />
              <div className="h-6 w-px bg-gray-300"></div>
              <img 
                src="/lovable-uploads/2a535174-fe56-4ae1-bfec-81017d75bb87.png" 
                alt="BelleHealth" 
                className="w-auto h-10 md:h-14" 
              />
            </div>
          </div>
        ) : (
          // Display the regular BelleHealth logo
          <img 
            src="/lovable-uploads/2a535174-fe56-4ae1-bfec-81017d75bb87.png" 
            alt="BelleHealth Logo" 
            className="w-auto h-14 md:h-24 mx-auto mb-3 md:mb-4" 
          />
        )}
        <p className="text-gray-600 italic text-sm md:text-sm font-light mt-2">Real Doctors. Real Clinics. Real support, in all 50 states.</p>
      </div>
      <h1 className="text-4xl md:text-5xl font-bold text-secondary px-6">
        GLP-1 Medication Assessment
      </h1>
      <p className="text-xl md:text-2xl text-gray-600 max-w-2xl mx-auto px-6">
        Complete our comprehensive assessment to determine if GLP-1 medications like Semaglutide or Tirzepatide are right for you.
      </p>
      <div className="flex flex-col items-center gap-4 px-6 mt-6 md:mt-8">
        <Button size="lg" onClick={onStart} className="w-full max-w-md bg-[#8BA89F] hover:bg-[#7A968C] text-white text-lg py-6">
          Start Assessment
        </Button>
        <div className="relative w-full max-w-md">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t border-[#8BA89F]/20" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-[#8BA89F]">
              Or
            </span>
          </div>
        </div>
        <Button variant="outline" size="lg" onClick={onLogin} className="w-full max-w-md border-[#8BA89F] text-[#8BA89F] hover:bg-[#8BA89F]/10 text-lg py-6">
          Already Started? Log In
        </Button>
        <div className="mt-4 space-y-3">
          <p className="text-gray-600">
            Prefer to speak with a real person first?
          </p>
          <Button variant="outline" size="sm" onClick={() => window.open("https://calendly.com/belle-medical/semaglutide-intro?preview_source=et_card&month=2025-01&utm_source=app.mybellehealth.com&utm_medium=homepage&utm_campaign=glp1_intake&utm_content=meet_with_real_person", "_blank")} className="border-[#8BA89F] text-[#8BA89F] hover:bg-[#8BA89F]/10">
            <Calendar className="w-4 h-4 mr-2" />
            Schedule Free Consultation
          </Button>
        </div>
      </div>
    </div>;
};
