
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Eye, EyeOff } from "lucide-react";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("ResetPassword component mounted");
    console.log("URL search params:", location.search);
    console.log("URL hash:", location.hash);
    console.log("Current location pathname:", location.pathname);
    console.log("Full URL:", window.location.href);
    
    // Extract the access token from the URL hash if present
    const extractHash = async () => {
      // First check for access_token in the hash (Supabase auth redirect format)
      const hashParams = new URLSearchParams(location.hash.substring(1));
      const accessToken = hashParams.get("access_token");
      
      if (accessToken) {
        setAccessToken(accessToken);
        console.log("Found access token in URL hash");
      } else {
        console.log("No access token found in URL hash");
        
        // Check if we have recovery token in the URL
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get("token");
        const type = urlParams.get("type");
        
        if (token && type === "recovery") {
          console.log("Found recovery token in URL");
          try {
            // Exchange the recovery token for an access token
            const { data, error } = await supabase.auth.verifyOtp({
              token_hash: token,
              type: "recovery",
            });
            
            if (error) {
              throw error;
            }
            
            if (data?.session?.access_token) {
              setAccessToken(data.session.access_token);
              console.log("Successfully exchanged recovery token for access token");
            }
          } catch (error) {
            console.error("Error exchanging recovery token:", error);
            toast({
              title: "Error",
              description: "Invalid or expired password reset link. Please request a new one.",
              variant: "destructive",
            });
          }
        } else {
          console.log("No recovery token found in URL or incorrect type");
          // Check if there's a refresh token or any other token in the URL that might help
          console.log("All URL params:", urlParams.toString());
        }
      }
    };
    
    extractHash();
  }, [location, toast]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (newPassword.length < 6) {
      toast({
        title: "Invalid Password",
        description: "Password must be at least 6 characters long",
        variant: "destructive",
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast({
        title: "Passwords Don't Match",
        description: "Please ensure both passwords match",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsSubmitting(true);
      console.log("Attempting to update password");
      
      const { error } = await supabase.auth.updateUser({ password: newPassword });

      if (error) {
        console.error('Password update error:', error);
        toast({
          title: "Error",
          description: "Unable to update password. Please try again.",
          variant: "destructive",
        });
        return;
      }

      console.log("Password updated successfully");
      toast({
        title: "Success",
        description: "Your password has been updated successfully.",
      });
      
      // Automatically sign in the user after password reset
      const { data: session } = await supabase.auth.getSession();
      
      if (session?.session) {
        console.log("User is authenticated after password reset");
        
        // Check if user is a provider and redirect accordingly
        const { data: { user } } = await supabase.auth.getUser();
        
        if (user?.user_metadata?.is_provider) {
          navigate("/provider");
        } else {
          navigate("/dashboard");
        }
      } else {
        // If no session, redirect to login
        navigate("/");
        console.log("No session after password reset, redirecting to login");
      }
    } catch (error) {
      console.error('Password update error:', error);
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-[#8BA89F]/5">
      <div className="container max-w-6xl mx-auto px-4 py-8">
        <div className="max-w-md mx-auto">
          <div className="bg-white p-8 rounded-lg shadow-lg border border-[#8BA89F]/10">
            <h2 className="text-2xl font-semibold text-[#8BA89F] mb-6 text-center">
              Reset Your Password
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="newPassword">New Password</Label>
                <div className="relative">
                  <Input
                    id="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="••••••••"
                    disabled={isSubmitting}
                    required
                    className="pr-10"
                  />
                  <button 
                    type="button"
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </button>
                </div>
                <p className="text-sm text-gray-500">Password must be at least 6 characters long</p>
              </div>
              <div className="space-y-2">
                <Label htmlFor="confirmPassword">Confirm Password</Label>
                <div className="relative">
                  <Input
                    id="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="••••••••"
                    disabled={isSubmitting}
                    required
                    className="pr-10"
                  />
                  <button 
                    type="button"
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <EyeOff className="h-4 w-4" />
                    ) : (
                      <Eye className="h-4 w-4" />
                    )}
                  </button>
                </div>
              </div>
              <Button type="submit" className="w-full" disabled={isSubmitting}>
                {isSubmitting ? "Resetting..." : "Reset Password"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
