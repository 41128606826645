import { Assessment } from "@/types/database/assessment"
import { format } from "date-fns"

interface PreviousAssessmentsProps {
  currentAssessmentId: string;
  assessments: Assessment[];
}

export const PreviousAssessments = ({ currentAssessmentId, assessments }: PreviousAssessmentsProps) => {
  if (assessments.length <= 1) return null

  return (
    <div className="mb-6">
      <h3 className="font-medium mb-2">Previous Assessments</h3>
      <div className="space-y-2">
        {assessments
          .filter(a => a.id !== currentAssessmentId)
          .map(prevAssessment => (
            <div key={prevAssessment.id} className="text-sm p-2 bg-background rounded">
              <p>
                {prevAssessment.medication} • #{prevAssessment.id.slice(-4)} • 
                {prevAssessment.created_at && format(new Date(prevAssessment.created_at), 'PPP')}
              </p>
            </div>
          ))}
      </div>
    </div>
  )
}