
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { PaymentForm } from "@/components/PaymentForm";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { Loader, ShieldCheck, Clock, CreditCard, BadgeCheck } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { Progress } from "@/components/ui/progress";
import { TestimonialCarousel } from "./testimonials/TestimonialCarousel";
import { Welcome } from "./Welcome";
import { useIsMobile } from "@/hooks/use-mobile";

interface PaymentStepProps {
  subscriptionId: string;
  onSuccess: () => void;
  onBack: () => void;
  amount: number;
}

export const PaymentStep = ({ subscriptionId, onSuccess, onBack, amount: initialAmount }: PaymentStepProps) => {
  const [validatedAmount, setValidatedAmount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const isMobile = useIsMobile();

  useEffect(() => {
    const validateAmount = async () => {
      try {
        setIsLoading(true);
        
        const numericAmount = Number(initialAmount);
        
        if (!isNaN(numericAmount) && numericAmount > 0) {
          setValidatedAmount(numericAmount);
          console.log('Amount validated from props:', numericAmount);
          return;
        }

        if (subscriptionId) {
          const { data: assessment, error } = await supabase
            .from('assessments')
            .select('amount, plan_type')
            .eq('id', subscriptionId)
            .single();

          if (error) {
            console.error('Error fetching assessment:', error);
            return;
          }

          if (assessment?.amount) {
            const assessmentAmount = Number(assessment.amount);
            if (!isNaN(assessmentAmount) && assessmentAmount > 0) {
              setValidatedAmount(assessmentAmount);
              console.log('Amount validated from assessment:', assessmentAmount);
              return;
            }
          }
        }

        console.error('No valid amount found');
      } catch (error) {
        console.error('Error validating amount:', error);
      } finally {
        setIsLoading(false);
      }
    };

    validateAmount();
  }, [initialAmount, subscriptionId]);

  if (!subscriptionId) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Payment Information</CardTitle>
        </CardHeader>
        <CardContent>
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>
              There was an error loading your assessment details. Please go back and try again.
            </AlertDescription>
          </Alert>
          <div className="flex justify-start">
            <Button variant="outline" onClick={onBack}>
              Back
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Payment Information</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex justify-center items-center py-8">
            <Loader className="h-6 w-6 animate-spin text-primary" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!validatedAmount) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Payment Information</CardTitle>
        </CardHeader>
        <CardContent>
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>
              Invalid payment amount. Please go back and select a valid plan.
            </AlertDescription>
          </Alert>
          <div className="flex justify-start">
            <Button variant="outline" onClick={onBack}>
              Back
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={`overflow-hidden ${isMobile ? 'border-0 shadow-none' : ''}`}>
      <CardHeader className={`${isMobile ? 'px-2 py-3' : ''}`}>
        <div className="w-full mb-2">
          <Progress value={90} className="h-2" />
          <p className="text-xs text-muted-foreground text-center mt-1">Final Step</p>
        </div>
        
        {isMobile && (
          <div className="flex items-center justify-center gap-1 mt-1">
            <ShieldCheck className="h-4 w-4 text-primary" />
            <span className="text-xs font-medium">Secure Checkout</span>
          </div>
        )}
      </CardHeader>
      
      <CardContent className={`space-y-4 ${isMobile ? 'p-2 pt-0' : ''}`}>
        <Welcome />
        
        <div className="md:grid md:grid-cols-5 gap-4">
          <div className={`md:col-span-3 space-y-4 ${isMobile ? 'mt-2' : ''}`}>
            {!isMobile && (
              <div className="space-y-2">
                <div className="flex items-center justify-center gap-2">
                  <ShieldCheck className="h-5 w-5 text-primary" />
                  <span className="font-medium">Secure Payment</span>
                </div>
                <div className="flex flex-wrap items-center justify-center gap-4 text-xs text-muted-foreground">
                  <span className="flex items-center gap-1">
                    <Clock className="h-3 w-3" />
                    24hr Approval
                  </span>
                  <span className="flex items-center gap-1">
                    <CreditCard className="h-3 w-3" />
                    Major Cards Accepted
                  </span>
                  <span className="flex items-center gap-1">
                    <BadgeCheck className="h-3 w-3" />
                    Charged After Approval
                  </span>
                </div>
              </div>
            )}
            
            <PaymentForm
              subscriptionId={subscriptionId}
              onSuccess={onSuccess}
              onCancel={onBack}
              amount={validatedAmount}
            />
          </div>
          
          <div className="hidden md:block md:col-span-2 bg-gradient-to-r from-muted/30 to-muted/50 rounded-lg p-4 mt-0">
            <div className="sticky top-4">
              <h3 className="text-lg font-medium mb-4 text-center">Patient Success Stories</h3>
              <TestimonialCarousel />
            </div>
          </div>
        </div>
        
        {isMobile && (
          <div className="bg-gradient-to-r from-muted/20 to-muted/40 rounded-lg p-3">
            <h3 className="text-sm font-medium mb-2 text-center">Patient Success Stories</h3>
            <TestimonialCarousel />
          </div>
        )}
        
        <div className="space-y-2">
          <div className="flex justify-center">
            <img 
              src="/lovable-uploads/12d57356-205a-4382-81bf-0840690cb904.png" 
              alt="LegitScript Certified" 
              className={`${isMobile ? 'h-10' : 'h-14'}`}
            />
          </div>
          <p className="text-xs text-center text-muted-foreground">
            Questions? Our care team is available 24/7
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
