
import { useState, useEffect } from "react"
import { supabase } from "@/integrations/supabase/client"
import { Assessment } from "@/types/database/assessment"

export const useAssessments = (userId: string | undefined) => {
  const [assessments, setAssessments] = useState<Assessment[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!userId) return

    const fetchAssessments = async () => {
      try {
        setIsLoading(true)
        console.log('Fetching assessments for user:', userId)
        const { data, error } = await supabase
          .from("assessments")
          .select(`
            *, 
            profiles(
              id,
              first_name,
              last_name,
              email,
              is_provider,
              utm_source,
              utm_medium,
              utm_campaign,
              utm_term,
              utm_content,
              created_at,
              updated_at
            ),
            messages!inner(*)
          `)
          .or('status.eq.completed,status.eq.prescribed')
          .order("created_at", { ascending: false })

        if (error) {
          console.error("Error fetching assessments:", error)
          return
        }

        console.log('Fetched assessments:', data?.length || 0)
        setAssessments(data || [])
      } catch (error) {
        console.error("Error in fetchAssessments:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAssessments()

    // Subscribe to message changes which might affect assessments
    const messageChannel = supabase
      .channel("assessment-message-updates")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "messages",
        },
        () => {
          fetchAssessments()
        }
      )
      .subscribe()

    return () => {
      supabase.removeChannel(messageChannel)
    }
  }, [userId])

  return { assessments, isLoading }
}
