import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { formatCompletionTime } from "../../dashboard/utils/formatTime";

interface ConversionMetrics {
  totalDrafts: number;
  convertedCount: number;
  conversionRate: number;
  averageLeadTime: string;
  fastestConversion: string;
  slowestConversion: string;
}

export const useConversionMetrics = (startDate: Date, endDate: Date) => {
  return useQuery<ConversionMetrics>({
    queryKey: ["conversion-metrics", startDate, endDate],
    queryFn: async () => {
      console.log('Fetching conversion metrics for date range:', { startDate, endDate });
      
      // Get all assessments created in the date range that are not renewals
      const { data: drafts, error: draftsError } = await supabase
        .from('assessments')
        .select('id, created_at')
        .eq('is_renewal', false)
        .gte('created_at', startDate.toISOString())
        .lte('created_at', endDate.toISOString());

      if (draftsError) throw draftsError;

      if (!drafts?.length) {
        console.log('No drafts found in date range');
        return {
          totalDrafts: 0,
          convertedCount: 0,
          conversionRate: 0,
          averageLeadTime: 'N/A',
          fastestConversion: 'N/A',
          slowestConversion: 'N/A'
        };
      }

      const draftIds = drafts.map(d => d.id);
      console.log('Found draft IDs:', draftIds);

      // Get the latest status and completion timestamps for each of these assessments
      const { data: conversions, error: conversionsError } = await supabase
        .from('assessments')
        .select('id, status, created_at, completed_at, prescribed_at')
        .in('id', draftIds);

      if (conversionsError) throw conversionsError;
      
      console.log('Conversion statuses:', conversions);

      const convertedAssessments = conversions?.filter(a => 
        ['completed', 'prescribed'].includes(a.status)
      ) || [];

      console.log('Converted assessments:', convertedAssessments);

      const totalDrafts = drafts.length;
      const convertedCount = convertedAssessments.length;
      const conversionRate = (convertedCount / totalDrafts) * 100;

      // Calculate lead times for converted assessments
      const leadTimes = convertedAssessments.map(assessment => {
        const startDate = new Date(assessment.created_at).getTime();
        const endDate = new Date(assessment.completed_at || assessment.prescribed_at).getTime();
        const leadTime = endDate - startDate;
        console.log('Lead time calculation:', {
          assessmentId: assessment.id,
          startDate: new Date(assessment.created_at),
          endDate: new Date(assessment.completed_at || assessment.prescribed_at),
          leadTimeMs: leadTime
        });
        return leadTime;
      }).filter(time => !isNaN(time) && time > 0);

      console.log('All lead times (ms):', leadTimes);

      // Calculate average, fastest, and slowest lead times
      const averageLeadTime = leadTimes.length 
        ? formatCompletionTime(leadTimes.reduce((a, b) => a + b, 0) / leadTimes.length)
        : 'N/A';

      const fastestConversion = leadTimes.length 
        ? formatCompletionTime(Math.min(...leadTimes))
        : 'N/A';

      const slowestConversion = leadTimes.length 
        ? formatCompletionTime(Math.max(...leadTimes))
        : 'N/A';

      console.log('Conversion metrics:', {
        totalDrafts,
        convertedCount,
        conversionRate,
        averageLeadTime,
        fastestConversion,
        slowestConversion,
        leadTimesCount: leadTimes.length
      });

      return {
        totalDrafts,
        convertedCount,
        conversionRate,
        averageLeadTime,
        fastestConversion,
        slowestConversion
      };
    },
  });
};