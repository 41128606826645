import { useQuery } from "@tanstack/react-query"
import { supabase } from "@/integrations/supabase/client"
import { Assessment, isValidAssessment } from "../types"

export const useAssessmentDetails = (patientId: string | null) => {
  return useQuery({
    queryKey: ["patient-assessments", patientId],
    queryFn: async () => {
      if (!patientId) return null
      
      console.log('Fetching assessments for patient:', patientId)
      
      const { data, error } = await supabase
        .from("assessments")
        .select(`
          *,
          profiles (
            id,
            first_name,
            last_name,
            email,
            is_provider,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
            created_at,
            updated_at
          )
        `)
        .eq("user_id", patientId)
        .order('created_at', { ascending: false })

      if (error) {
        console.error('Error fetching assessments:', error)
        throw error
      }

      console.log('Raw patient assessment data:', data)

      const validAssessments = data
        .map(assessment => {
          try {
            const validAssessment = { ...assessment } as Assessment
            if (isValidAssessment(validAssessment)) {
              return validAssessment
            }
            return null
          } catch (err) {
            console.error('Error validating assessment:', err)
            return null
          }
        })
        .filter((assessment): assessment is Assessment => assessment !== null)
      
      console.log('Valid patient assessments:', validAssessments)
      return validAssessments
    },
    enabled: !!patientId,
  })
}