import { format, differenceInDays } from "date-fns";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import { PaymentStep } from "@/components/PaymentStep";
import { useState, useEffect } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, Calendar, Info } from "lucide-react";
import { usePricingData } from "@/components/pricing/usePricingData";
import { DoseType } from "@/components/pricing/types";
import { Card, CardContent } from "@/components/ui/card";

interface SubscriptionDetailsModalProps {
  assessment: any;
  open: boolean;
  onClose: () => void;
}

export const SubscriptionDetailsModal = ({ assessment, open, onClose }: SubscriptionDetailsModalProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [showPayment, setShowPayment] = useState(false);
  const [selectedDoseType, setSelectedDoseType] = useState<DoseType>(assessment.dose_type || 'standard');
  const [currentAmount, setCurrentAmount] = useState<number | null>(null);

  const isNewPatient = !assessment.previous_glp1 || 
                      !assessment.recent_glp1 || 
                      assessment.previous_medication?.toLowerCase() !== assessment.medication.toLowerCase();

  const isContinuingMedication = !isNewPatient && 
    assessment.previous_medication?.toLowerCase() === assessment.medication.toLowerCase();

  const { pricingData: standardPricingData } = usePricingData({
    selectedMedication: assessment.medication,
    previousGlp1: "yes",
    previousMedication: assessment.medication,
    recentGlp1: "yes",
    doseType: "standard"
  });

  const { pricingData: maxPricingData } = usePricingData({
    selectedMedication: assessment.medication,
    previousGlp1: "yes",
    previousMedication: assessment.medication,
    recentGlp1: "yes",
    doseType: "max"
  });

  const planDuration = parseInt(assessment.plan_type);
  const standardPricing = standardPricingData.find(p => p.duration_months === planDuration);
  const maxPricing = maxPricingData.find(p => p.duration_months === planDuration);

  // Update currentAmount whenever dose type or pricing changes
  useEffect(() => {
    const pricing = selectedDoseType === 'standard' ? standardPricing : maxPricing;
    if (pricing) {
      setCurrentAmount(pricing.total_price);
      console.log('Updated current amount:', {
        doseType: selectedDoseType,
        amount: pricing.total_price
      });
    }
  }, [selectedDoseType, standardPricing, maxPricing]);

  const handleDoseTypeChange = (doseType: DoseType) => {
    setSelectedDoseType(doseType);
  };

  const handleAutoRenewToggle = async (checked: boolean) => {
    if (checked && !isContinuingMedication) {
      toast({
        title: "Auto-renew not available",
        description: "Auto-renewal is only available for existing patients continuing their current medication.",
        variant: "destructive",
      });
      return;
    }

    try {
      const { error } = await supabase
        .from('assessments')
        .update({ auto_renew: checked })
        .eq('id', assessment.id);

      if (error) throw error;

      await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });

      toast({
        title: checked ? "Auto-renew enabled" : "Auto-renew disabled",
        description: checked 
          ? "Your subscription will automatically renew before expiration" 
          : "Your subscription will not automatically renew",
      });
    } catch (error) {
      console.error('Error updating auto-renew:', error);
      toast({
        title: "Error",
        description: "Failed to update auto-renew setting. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      if (!currentAmount) {
        throw new Error('No pricing available');
      }

      const newEndDate = new Date();
      newEndDate.setMonth(newEndDate.getMonth() + planDuration);

      console.log('Processing payment with amount:', {
        doseType: selectedDoseType,
        amount: currentAmount,
        planDuration
      });

      const { error: updateError } = await supabase
        .from('assessments')
        .update({ 
          subscription_end_date: newEndDate.toISOString(),
          subscription_status: 'active',
          auto_renew: isContinuingMedication,
          is_renewal: true,
          renewal_count: (assessment.renewal_count || 0) + 1,
          status: 'completed',
          dose_type: selectedDoseType,
          amount: currentAmount,
          previous_glp1: true,
          recent_glp1: true,
          previous_medication: assessment.medication
        })
        .eq('id', assessment.id);

      if (updateError) throw updateError;

      const { error: historyError } = await supabase
        .from('subscription_history')
        .insert({
          assessment_id: assessment.id,
          start_date: new Date().toISOString(),
          end_date: newEndDate.toISOString(),
          amount: currentAmount,
          status: 'active'
        });

      if (historyError) {
        console.error('Error creating subscription history:', historyError);
      }

      await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });

      toast({
        title: "Subscription Updated",
        description: `Your subscription has been updated to ${selectedDoseType} dosing and will be reviewed by our medical team.`,
      });

      setShowPayment(false);
      onClose();
    } catch (error) {
      console.error('Error processing update:', error);
      toast({
        title: "Error",
        description: "Failed to process update. Please try again.",
        variant: "destructive",
      });
    }
  };

  const getSubscriptionStatus = () => {
    if (isNewPatient) {
      return <Badge className="bg-purple-100 text-purple-800">Ramping Up</Badge>;
    }
    if (assessment.subscription_status === 'active') {
      return <Badge className="bg-green-100 text-green-800">Active</Badge>;
    }
    if (assessment.subscription_status === 'payment_failed') {
      return <Badge variant="destructive">Payment Failed</Badge>;
    }
    return <Badge className="bg-gray-100 text-gray-800">Inactive</Badge>;
  };

  const getRenewalStatus = () => {
    if (!assessment.subscription_end_date) return null;

    const daysUntilRenewal = differenceInDays(
      new Date(assessment.subscription_end_date),
      new Date()
    );

    if (daysUntilRenewal <= 7 && daysUntilRenewal > 0) {
      return (
        <Alert className="mt-4">
          <Calendar className="h-4 w-4" />
          <AlertDescription>
            Your subscription will {assessment.auto_renew ? 'automatically renew' : 'expire'} in {daysUntilRenewal} days
          </AlertDescription>
        </Alert>
      );
    }

    if (assessment.subscription_status === 'payment_failed') {
      return (
        <Alert variant="destructive" className="mt-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Your payment method needs to be updated to continue your subscription
          </AlertDescription>
        </Alert>
      );
    }

    return null;
  };

  if (showPayment && currentAmount) {
    console.log('Showing payment step with amount:', currentAmount);
    return (
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent>
          <PaymentStep 
            subscriptionId={assessment.id}
            onSuccess={handlePaymentSuccess}
            onBack={() => setShowPayment(false)}
            amount={currentAmount}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Subscription Details</DialogTitle>
          <DialogDescription>
            Manage your subscription and dosing preferences
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-4">
            {!isContinuingMedication && (
              <Alert>
                <Info className="h-4 w-4" />
                <AlertDescription>
                  Auto-renewal is only available for existing patients continuing their current medication.
                </AlertDescription>
              </Alert>
            )}
            
            <div className="flex items-center justify-between">
              <span className="font-medium">Status</span>
              {getSubscriptionStatus()}
            </div>

            {getRenewalStatus()}
            
            <div className="flex items-center justify-between">
              <span className="font-medium">Medication</span>
              <span className="capitalize">{assessment.medication}</span>
            </div>

            <div className="flex items-center justify-between">
              <span className="font-medium">Current Plan</span>
              <span>{assessment.plan_type}</span>
            </div>

            {standardPricing && (
              <Card 
                className={`border-2 hover:border-primary cursor-pointer transition-all ${selectedDoseType === 'standard' ? 'border-primary' : ''}`} 
                onClick={() => handleDoseTypeChange('standard')}
              >
                <CardContent className="pt-4">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-semibold">Standard Dose</h4>
                    <Badge variant="outline">Recommended</Badge>
                  </div>
                  <div className="text-sm text-muted-foreground space-y-1">
                    <div className="flex justify-between">
                      <span>Monthly cost:</span>
                      <span className="font-medium">${Math.round(standardPricing.monthly_price)}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Upgrade cost:</span>
                      <span className="font-medium">${standardPricing.total_price}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {maxPricing && (
              <Card 
                className={`border-2 hover:border-primary cursor-pointer transition-all ${selectedDoseType === 'max' ? 'border-primary' : ''}`}
                onClick={() => handleDoseTypeChange('max')}
              >
                <CardContent className="pt-4">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className="font-semibold">Max Dose</h4>
                    <Badge variant="outline">Higher strength</Badge>
                  </div>
                  <div className="text-sm text-muted-foreground space-y-1">
                    <div className="flex justify-between">
                      <span>Monthly cost:</span>
                      <span className="font-medium">${Math.round(maxPricing.monthly_price)}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Upgrade cost:</span>
                      <span className="font-medium">${maxPricing.total_price}</span>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {assessment.subscription_started_at && (
              <div className="flex items-center justify-between">
                <span className="font-medium">Started</span>
                <span>{format(new Date(assessment.subscription_started_at), "PPP")}</span>
              </div>
            )}

            {assessment.subscription_end_date && (
              <div className="flex items-center justify-between">
                <span className="font-medium">Renews On</span>
                <span>{format(new Date(assessment.subscription_end_date), "PPP")}</span>
              </div>
            )}

            <div className="flex items-center justify-between space-x-2">
              <span className="font-medium">Auto-renew</span>
              <Switch
                checked={assessment.auto_renew}
                onCheckedChange={handleAutoRenewToggle}
                disabled={!isContinuingMedication || assessment.subscription_status === 'payment_failed'}
              />
            </div>

            <Button 
              onClick={() => setShowPayment(true)}
              className="w-full mt-4"
              variant={assessment.subscription_status === 'payment_failed' ? "destructive" : "default"}
            >
              {assessment.subscription_status === 'payment_failed' 
                ? "Update Payment Method" 
                : "Renew Subscription"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
