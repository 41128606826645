interface MedicalHistorySectionProps {
  assessment: any;
}

export const MedicalHistorySection = ({ assessment }: MedicalHistorySectionProps) => {
  const formatBoolean = (value: boolean | null) => {
    if (value === null) return "Not specified";
    return value ? "Yes" : "No";
  };

  return (
    <section className="space-y-4">
      <h3 className="text-lg font-semibold">Medical History</h3>
      <div className="grid gap-6">
        <div>
          <p className="text-sm font-medium text-muted-foreground">Medical Conditions</p>
          {assessment.medical_conditions && assessment.medical_conditions.length > 0 ? (
            <ul className="mt-1 list-disc pl-5 space-y-1">
              {assessment.medical_conditions.map((condition: string, index: number) => (
                <li key={index} className="text-sm">{condition}</li>
              ))}
            </ul>
          ) : (
            <p className="text-sm mt-1">No medical conditions reported</p>
          )}
        </div>
        
        <div>
          <p className="text-sm font-medium text-muted-foreground">Other Medical Conditions</p>
          <p className="text-sm mt-1">{assessment.other_medical_conditions || 'None specified'}</p>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm font-medium text-muted-foreground">Height</p>
            <p className="text-sm mt-1">
              {assessment.patient_height ? `${assessment.patient_height} inches` : 'Not specified'}
            </p>
          </div>
          <div>
            <p className="text-sm font-medium text-muted-foreground">Weight</p>
            <p className="text-sm mt-1">
              {assessment.patient_weight ? `${assessment.patient_weight} lbs` : 'Not specified'}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm font-medium text-muted-foreground">Medullary Thyroid Cancer (MTC)</p>
            <p className="text-sm mt-1">{formatBoolean(assessment.medullary_thyroid_cancer)}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-muted-foreground">Family History of MTC</p>
            <p className="text-sm mt-1">{formatBoolean(assessment.family_mtc_history)}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-muted-foreground">MEN2</p>
            <p className="text-sm mt-1">{formatBoolean(assessment.men2)}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-muted-foreground">Pregnant or Breastfeeding</p>
            <p className="text-sm mt-1">{formatBoolean(assessment.pregnant_or_breastfeeding)}</p>
          </div>
        </div>

        <div>
          <p className="text-sm font-medium text-muted-foreground">Exercise Activity</p>
          <p className="text-sm mt-1">{assessment.exercise_activity || 'Not specified'}</p>
        </div>
      </div>
    </section>
  );
};