
import { supabase } from "@/integrations/supabase/client";
import { AssessmentData } from "@/components/pricing/types/assessment";
import { useToast } from "@/hooks/use-toast";

export const useAssessmentManagement = () => {
  const { toast } = useToast();

  const saveAssessment = async (userId: string, formData: any, planData: { selectedPlan: string; doseType: "standard" | "max"; amount: number }) => {
    try {
      const assessmentData: AssessmentData = {
        user_id: userId,
        date_of_birth: formData.dateOfBirth || null,
        gender: formData.gender || null,
        cell_phone: formData.cellPhone || null,
        medical_conditions: formData.selectedConditions || [],
        other_medical_conditions: formData.otherCondition || null,
        medullary_thyroid_cancer: formData.medullaryThyroidCancer === "yes",
        family_mtc_history: formData.familyMtcHistory === "yes",
        men2: formData.men2 === "yes",
        pregnant_or_breastfeeding: formData.pregnantOrBreastfeeding === "yes",
        patient_height: formData.heightFeet ? parseInt(formData.heightFeet) * 12 + parseInt(formData.heightInches || '0') : null,
        patient_weight: formData.weight ? parseFloat(formData.weight) : null,
        exercise_activity: formData.exerciseActivity || null,
        taking_medications: formData.takingMedications === "yes",
        medications_list: formData.medicationsList || null,
        previous_glp1: formData.previousGlp1 === "yes",
        previous_medication: formData.previousMedication || null,
        recent_glp1: formData.recentGlp1 === "yes",
        has_allergies: formData.hasAllergies === "yes",
        allergies_list: formData.allergiesList || null,
        taking_blood_thinners: formData.takingBloodThinners === "yes",
        medication: formData.selectedMedication,
        plan_type: planData.selectedPlan,
        amount: planData.amount,
        shipping_address: formData.shippingAddress || null,
        shipping_city: formData.shippingCity || null,
        shipping_state: formData.shippingState || null,
        shipping_zip: formData.shippingZip || null,
        status: 'draft',
        dose_type: planData.doseType
      };

      console.log('Saving assessment data:', assessmentData);

      if (formData.id) {
        const { data, error } = await supabase
          .from('assessments')
          .update(assessmentData)
          .eq('id', formData.id)
          .select()
          .single();

        if (error) {
          console.error('Error updating assessment:', error);
          toast({
            title: "Error",
            description: "Failed to save your progress. Please try again.",
            variant: "destructive",
          });
          throw error;
        }
        
        console.log('Successfully updated assessment:', data);
        return data;
      } else {
        const { data, error } = await supabase
          .from('assessments')
          .insert(assessmentData)
          .select()
          .single();

        if (error) {
          console.error('Error creating assessment:', error);
          toast({
            title: "Error",
            description: "Failed to save your progress. Please try again.",
            variant: "destructive",
          });
          throw error;
        }

        console.log('Successfully created assessment:', data);
        return data;
      }
    } catch (error) {
      console.error('Error in saveAssessment:', error);
      throw error;
    }
  };

  return { saveAssessment };
};
