
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { useAssessmentDetails } from "./hooks/useAssessmentDetails"
import { RenewalHistorySection } from "./details/RenewalHistorySection"
import { ScrollArea } from "@/components/ui/scroll-area"
import { ShippingSection } from "./details/ShippingSection"
import { BasicInfoSection } from "./details/BasicInfoSection"
import { MedicalHistorySection } from "./details/MedicalHistorySection"
import { MedicationsSection } from "./details/MedicationsSection"

interface PatientDetailsModalProps {
  patientId: string | null
  assessmentId: string | null  // Added this prop
  isOpen: boolean
  onClose: () => void
}

export const PatientDetailsModal = ({ patientId, assessmentId, isOpen, onClose }: PatientDetailsModalProps) => {
  const { data: assessments, isLoading } = useAssessmentDetails(patientId)
  
  // Get the most recent assessment for patient details
  const currentAssessment = assessments?.[0]

  if (!currentAssessment || isLoading) {
    return null
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl h-[90vh] flex flex-col p-0">
        <DialogHeader className="px-6 py-4 border-b">
          <DialogTitle>Patient Details</DialogTitle>
        </DialogHeader>
        <ScrollArea className="flex-grow px-6">
          <div className="space-y-8 py-6">
            <ShippingSection assessment={currentAssessment} />
            <RenewalHistorySection 
              assessment={currentAssessment} 
              allAssessments={assessments || []} 
            />
            <BasicInfoSection assessment={currentAssessment} />
            <div className="space-y-8">
              <MedicalHistorySection assessment={currentAssessment} />
              <MedicationsSection assessment={currentAssessment} />
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
