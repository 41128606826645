
import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { DashboardHeader } from "@/components/dashboard/DashboardHeader";
import { DashboardContent } from "@/components/dashboard/DashboardContent";
import { AssessmentsList } from "@/components/dashboard/AssessmentsList";
import { ProfileSettings } from "@/components/profile/ProfileSettings";
import PatientMessages from "@/components/patient/messages/PatientMessages";
import { Loader } from "lucide-react";
import { AssessmentFormData } from "@/types/assessment";

const Dashboard = () => {
  const [currentStep, setCurrentStep] = useState(2);
  const [showAssessmentForm, setShowAssessmentForm] = useState(false);
  const totalSteps = 9; // Updated from 7 to 9 to account for the new GLP-1 question page
  
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState(false);
  
  const [formData, setFormData] = useState<AssessmentFormData>({
    dateOfBirth: "",
    gender: "",
    cellPhone: "",
    selectedConditions: [],
    otherCondition: "",
    medullaryThyroidCancer: "",
    familyMtcHistory: "",
    men2: "",
    pregnantOrBreastfeeding: "",
    weight: "",
    heightFeet: "",
    heightInches: "",
    exerciseActivity: "",
    takingMedications: "",
    medicationsList: "",
    previousGlp1: "",
    previousMedication: "",
    recentGlp1: "",
    recentGlp1Date: "", // Added this field
    hasAllergies: "",
    allergiesList: "",
    takingBloodThinners: "",
    selectedMedication: "",
    selectedPlan: "",
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingZip: "",
    assessment: null
  });

  // Enhanced auth check with proper session handling
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error("Session error:", sessionError);
          throw sessionError;
        }
        
        if (!session) {
          console.log("No active session found");
          navigate("/");
          return;
        }

        // Set up auth state change listener
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
          async (event, session) => {
            console.log("Auth state change:", event);
            
            if (event === 'TOKEN_REFRESHED') {
              console.log('Session token refreshed successfully');
            } else if (event === 'SIGNED_OUT' || !session) {
              console.log('User signed out or session expired');
              navigate("/");
            }
          }
        );

        setIsLoading(false);
        setAuthError(false);

        return () => {
          subscription.unsubscribe();
        };

      } catch (error) {
        console.error("Auth check error:", error);
        setAuthError(true);
        setIsLoading(false);
        navigate("/");
      }
    };

    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const state = location.state as { startNew?: boolean; continueAssessment?: boolean };
    if (state?.startNew || state?.continueAssessment) {
      setShowAssessmentForm(true);
    } else {
      setShowAssessmentForm(false);
    }
  }, [location.state]);

  // Added effect to handle step transitions and scrolling
  useEffect(() => {
    // Reset scroll position when changing steps
    const fixScrollPosition = () => {
      // Allow the DOM to update before scrolling
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    };
    
    // Watch for banner visibility changes
    const observer = new MutationObserver(() => {
      if (document.body.classList.contains('has-banner')) {
        fixScrollPosition();
      }
    });
    
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
    
    // Fix scroll position when step changes
    fixScrollPosition();
    
    return () => {
      observer.disconnect();
    };
  }, [currentStep]);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      toast({
        title: "Logged out successfully",
        description: "You have been logged out of your account.",
      });
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
      toast({
        title: "Error logging out",
        description: "There was a problem logging out. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleNext = async () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
      // Ensure proper scrolling when advancing steps
      window.scrollTo(0, 0);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      // Ensure proper scrolling when going back
      window.scrollTo(0, 0);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (authError) {
    return (
      <div className="container mx-auto p-6">
        <DashboardHeader onLogout={handleLogout} />
        <div className="flex flex-col items-center justify-center space-y-4 mt-8">
          <p className="text-gray-600">
            Experiencing connection issues. Please try signing in again.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <DashboardHeader onLogout={handleLogout} />
      <Routes>
        <Route
          index
          element={
            showAssessmentForm ? (
              <DashboardContent
                currentStep={currentStep}
                totalSteps={totalSteps}
                formData={formData}
                setFormData={setFormData}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                subscriptionId={subscriptionId}
                subscription={subscription}
              />
            ) : (
              <AssessmentsList />
            )
          }
        />
        <Route path="messages" element={<PatientMessages />} />
        <Route path="profile" element={<ProfileSettings />} />
      </Routes>
    </div>
  );
};

export default Dashboard;
