import { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { ShippingForm } from "@/components/ShippingForm";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

interface ShippingUpdateModalProps {
  assessment: any;
  open: boolean;
  onClose: () => void;
}

export const ShippingUpdateModal = ({
  assessment,
  open,
  onClose,
}: ShippingUpdateModalProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    shippingAddress: assessment.shipping_address || '',
    shippingCity: assessment.shipping_city || '',
    shippingState: assessment.shipping_state || '',
    shippingZip: assessment.shipping_zip || '',
  });

  const handleSubmit = async () => {
    try {
      const { error } = await supabase
        .from('assessments')
        .update({
          shipping_address: formData.shippingAddress,
          shipping_city: formData.shippingCity,
          shipping_state: formData.shippingState,
          shipping_zip: formData.shippingZip,
        })
        .eq('id', assessment.id);

      if (error) throw error;

      // Invalidate the user-assessments query to trigger a refresh
      await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });

      toast({
        title: "Address Updated",
        description: "Your shipping address has been updated successfully.",
      });
      onClose();
    } catch (error) {
      console.error('Error updating address:', error);
      toast({
        title: "Error",
        description: "Failed to update shipping address. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update Shipping Address</DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          <ShippingForm
            formData={formData}
            onChange={(data) => setFormData({ ...formData, ...data })}
          />
          <Button onClick={handleSubmit} className="w-full">
            Save Changes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};