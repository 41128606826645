import { format } from "date-fns"
import { cn } from "@/lib/utils"
import { UserRound } from "lucide-react"
import { ScrollArea } from "@/components/ui/scroll-area"

interface Message {
  id: string
  content: string
  created_at: string
  is_provider: boolean
  is_notification?: boolean
}

interface MessageContainerProps {
  message: Message
  isCurrentUser: boolean
}

export function MessageContainer({ message, isCurrentUser }: MessageContainerProps) {
  // For patient messages (not provider and not notification), align right
  const shouldAlignRight = !message.is_provider && !message.is_notification

  return (
    <div className={cn("flex w-full", shouldAlignRight ? "justify-end" : "justify-start")}>
      <div className="flex flex-col items-start max-w-[85%]">
        <div
          className={cn(
            "w-full rounded-lg p-4",
            message.is_notification
              ? "bg-muted text-muted-foreground text-sm"
              : shouldAlignRight
              ? "bg-primary text-primary-foreground"
              : "bg-secondary/10 text-secondary"
          )}
        >
          <p className="leading-relaxed break-words">{message.content}</p>
          <span className="text-xs opacity-70 mt-2 block">
            {format(new Date(message.created_at), "p")}
          </span>
        </div>
        {message.is_provider && !message.is_notification && (
          <div className="mt-1 ml-1 flex items-center gap-1">
            <UserRound className="h-4 w-4 text-muted-foreground" />
            <span className="text-xs text-muted-foreground">BelleHealth Provider</span>
          </div>
        )}
      </div>
    </div>
  )
}