
import { ReactNode } from "react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useSidebar } from "@/components/ui/sidebar"
import { Link, useLocation } from "react-router-dom"
import { TagIcon, MegaphoneIcon } from "lucide-react"

interface ProviderSidebarProps {
  children: ReactNode
}

export function ProviderSidebar({ children }: ProviderSidebarProps) {
  const { state } = useSidebar()
  const location = useLocation()

  const isActive = (path: string) => {
    return location.pathname === path || location.pathname.startsWith(`${path}/`)
  }

  // Get banner-adjusted top offset
  const hasBanner = document.body.classList.contains('has-banner');

  return (
    <>
      <aside
        className={cn(
          "fixed left-0 top-0 z-20 h-full w-64 border-r bg-background transition-all duration-300 ease-in-out",
          state === "collapsed" && "w-16",
          hasBanner && "top-[var(--banner-height)]"
        )}
        style={{ 
          height: hasBanner ? 'calc(100% - var(--banner-height))' : '100%' 
        }}
      >
        <div className="p-4">
          <Link to="/provider" className="flex items-center">
            <img src="/lovable-uploads/e347af71-91bb-4e2f-828a-70324d37a174.png" alt="Logo" className="h-8 w-auto" />
          </Link>
        </div>
        <ScrollArea className="h-[calc(100%-4rem)]">
          <div className="space-y-4 py-4">
            <div className="px-3 py-2">
              <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
                Dashboard
              </h2>
              <div className="space-y-1">
                <Button
                  variant={isActive("/provider") && !isActive("/provider/promo-codes") && !isActive("/provider/assessments") && !isActive("/provider/messages") && !isActive("/provider/patients") && !isActive("/provider/pricing") && !isActive("/provider/users") && !isActive("/provider/add-provider") && !isActive("/provider/banner") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider">Overview</Link>
                </Button>
                <Button
                  variant={isActive("/provider/assessments") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/assessments">Assessments</Link>
                </Button>
                <Button
                  variant={isActive("/provider/messages") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/messages">Messages</Link>
                </Button>
                <Button
                  variant={isActive("/provider/patients") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/patients">Patients</Link>
                </Button>
                <Button
                  variant={isActive("/provider/pricing") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/pricing">Pricing</Link>
                </Button>
                <Button
                  variant={isActive("/provider/promo-codes") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/promo-codes">
                    <TagIcon className="mr-2 h-4 w-4" />
                    Promo Codes
                  </Link>
                </Button>
                <Button
                  variant={isActive("/provider/banner") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/banner">
                    <MegaphoneIcon className="mr-2 h-4 w-4" />
                    Sales Banner
                  </Link>
                </Button>
                <Button
                  variant={isActive("/provider/users") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/users">Users</Link>
                </Button>
                <Button
                  variant={isActive("/provider/add-provider") ? "secondary" : "ghost"}
                  className="w-full justify-start"
                  asChild
                >
                  <Link to="/provider/add-provider">Add Provider</Link>
                </Button>
              </div>
            </div>
          </div>
        </ScrollArea>
      </aside>
      <main
        className={cn(
          "min-h-screen transition-all duration-300 ease-in-out",
          state === "collapsed" ? "ml-16" : "ml-64",
          hasBanner && "pt-[var(--banner-height)]"
        )}
      >
        {children}
      </main>
    </>
  )
}
