
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { User } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { useEffect } from "react";

interface BasicInfoFormProps {
  formData: {
    dateOfBirth: string;
    gender: string;
    cellPhone: string;
  };
  onChange: (data: Partial<{
    dateOfBirth: string;
    gender: string;
    cellPhone: string;
  }>) => void;
  onValidationChange?: (isValid: boolean) => void;
}

export const BasicInfoForm = ({ formData, onChange, onValidationChange }: BasicInfoFormProps) => {
  const isMobile = useIsMobile();
  
  const formatPhoneNumber = (value: string) => {
    const phoneNumber = value.replace(/\D/g, '');
    let formattedNumber = '';
    if (phoneNumber.length <= 3) {
      formattedNumber = phoneNumber;
    } else if (phoneNumber.length <= 6) {
      formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      formattedNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
    return formattedNumber;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    onChange({ cellPhone: formattedNumber });
  };

  // Validate form data and notify parent component
  useEffect(() => {
    const isDateValid = !!formData.dateOfBirth;
    const isGenderValid = !!formData.gender;
    const isPhoneValid = formData.cellPhone && formData.cellPhone.replace(/\D/g, '').length >= 10;
    
    const isFormValid = isDateValid && isGenderValid && isPhoneValid;
    
    if (onValidationChange) {
      onValidationChange(isFormValid);
    }
  }, [formData, onValidationChange]);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const handleDateChange = (type: 'month' | 'day' | 'year', value: string) => {
    const currentDate = formData.dateOfBirth ? new Date(formData.dateOfBirth) : new Date();
    let newDate = new Date(currentDate);

    switch (type) {
      case 'month':
        newDate.setMonth(months.indexOf(value));
        break;
      case 'day':
        newDate.setDate(parseInt(value));
        break;
      case 'year':
        newDate.setFullYear(parseInt(value));
        break;
    }

    onChange({ dateOfBirth: newDate.toISOString().split('T')[0] });
  };

  const getInitialValues = () => {
    if (!formData.dateOfBirth) return { month: '', day: '', year: '' };
    const date = new Date(formData.dateOfBirth);
    return {
      month: months[date.getMonth()],
      day: date.getDate().toString(),
      year: date.getFullYear().toString()
    };
  };

  const initialValues = getInitialValues();

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2 mb-4">
        <User className="w-6 h-6 text-primary" />
        <h3 className="text-xl font-semibold">Your Basic Info</h3>
      </div>
      <div className="space-y-4">
        <div>
          <Label htmlFor="dateOfBirth">Date of Birth*</Label>
          {isMobile ? (
            <div className="grid grid-cols-3 gap-2 mt-1">
              <select
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                value={initialValues.month}
                onChange={(e) => handleDateChange('month', e.target.value)}
                required
              >
                <option value="">Month</option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                value={initialValues.day}
                onChange={(e) => handleDateChange('day', e.target.value)}
                required
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day.toString()}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                value={initialValues.year}
                onChange={(e) => handleDateChange('year', e.target.value)}
                required
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year.toString()}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <Input
              id="dateOfBirth"
              type="date"
              value={formData.dateOfBirth}
              onChange={(e) => onChange({ dateOfBirth: e.target.value })}
              required
              className="mt-1"
            />
          )}
        </div>
        <div>
          <Label>Gender*</Label>
          <RadioGroup
            value={formData.gender}
            onValueChange={(value) => onChange({ gender: value })}
            className="flex gap-4 mt-1"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="male" id="male" />
              <Label htmlFor="male">Male</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="female" id="female" />
              <Label htmlFor="female">Female</Label>
            </div>
          </RadioGroup>
        </div>
        <div>
          <Label htmlFor="cellPhone">Cell Phone*</Label>
          <Input
            id="cellPhone"
            type="tel"
            value={formData.cellPhone}
            onChange={handlePhoneChange}
            required
            placeholder="(123) 456-7890"
            className="mt-1"
            maxLength={14}
          />
        </div>
        <div className="text-xs text-muted-foreground text-right mt-4">
          * Required fields
        </div>
      </div>
    </div>
  );
};
