
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const makeUserProvider = async (userId: string) => {
  try {
    console.log("Making user a provider:", userId);
    
    // Update the profile table first (this is more reliable from client-side)
    const { error: profileError } = await supabase
      .from('profiles')
      .update({ is_provider: true })
      .eq('id', userId);
    
    if (profileError) {
      console.error("Error updating profile:", profileError);
      throw profileError;
    }
    
    console.log("Successfully updated profile table");
    
    // Call the edge function to update user metadata (which requires admin privileges)
    const { error: metadataError } = await supabase.functions.invoke('set-provider-metadata', {
      body: { userId }
    });
    
    if (metadataError) {
      console.error("Error from edge function:", metadataError);
      toast.error("Failed to update provider metadata. Please try again.");
      // We'll still continue since we updated the profiles table
    } else {
      console.log("Successfully updated user metadata");
    }
    
    // Double-check that at least the profile was updated correctly
    const { data: profile, error: profileCheckError } = await supabase
      .from('profiles')
      .select('is_provider')
      .eq('id', userId)
      .single();
      
    if (profileCheckError) {
      console.warn("Could not verify profile update:", profileCheckError);
    } else {
      console.log("Verified profile update:", profile);
      if (!profile.is_provider) {
        throw new Error("Profile was not properly updated");
      }
    }
    
    toast.success("Successfully made user a provider");
    
    // Refresh the page to apply changes
    setTimeout(() => {
      window.location.reload();
    }, 1500);
    
    return true;
  } catch (error) {
    console.error("Error making user a provider:", error);
    toast.error("Failed to make user a provider");
    return false;
  }
};
