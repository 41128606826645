
import { Button } from "@/components/ui/button"
import { LogOut, UserCog } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useToast } from "@/hooks/use-toast"
import { supabase } from "@/integrations/supabase/client"
import { Profile } from "@/types/database/profile"
import { ProfileEditModal } from "../ProfileEditModal"
import { useState } from "react"

interface DashboardHeaderProps {
  profile: Profile | null;
  onProfileUpdate: () => void;
}

export const DashboardHeader = ({ profile, onProfileUpdate }: DashboardHeaderProps) => {
  const { toast } = useToast()
  const navigate = useNavigate()
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false)

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut()
      if (error) throw error
      
      toast({
        title: "Logged out successfully",
        description: "You have been logged out of your account.",
      })
      navigate("/provider-login", { replace: true })
    } catch (error) {
      console.error("Error logging out:", error)
      toast({
        title: "Error logging out",
        description: "There was a problem logging out. Please try again.",
        variant: "destructive",
      })
    }
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Provider Dashboard</h1>
        <div className="flex gap-2">
          <Button 
            variant="outline" 
            onClick={() => setIsProfileModalOpen(true)}
            className="gap-2"
          >
            <UserCog className="h-4 w-4" />
            Edit Profile
          </Button>
          <Button 
            variant="outline" 
            onClick={handleLogout}
            className="gap-2"
          >
            <LogOut className="h-4 w-4" />
            Logout
          </Button>
        </div>
      </div>

      <ProfileEditModal
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
        currentProfile={profile}
        onProfileUpdate={onProfileUpdate}
      />
    </>
  )
}
