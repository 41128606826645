
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { ProgressBar } from "@/components/ProgressBar";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { usePlanSelection } from "./PlanSelectionHandler";
import { usePaymentSuccess } from "./PaymentSuccessHandler";
import { AssessmentSteps } from "./AssessmentSteps";
import { StepsNavigation } from "@/components/StepsNavigation";
import { useDraftAssessment } from "@/hooks/useDraftAssessment";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

interface DashboardContentProps {
  currentStep: number;
  totalSteps: number;
  formData: any;
  setFormData: (data: any) => void;
  handleNext: () => void;
  handlePrevious: () => void;
  subscriptionId: string | null;
  subscription: any;
}

export const DashboardContent = ({
  currentStep,
  totalSteps,
  formData,
  setFormData,
  handleNext,
  handlePrevious,
  subscriptionId,
  subscription,
}: DashboardContentProps) => {
  const { toast } = useToast();
  const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
  const [hidePreviousButton, setHidePreviousButton] = useState(false);
  
  const { draftAssessmentId } = useDraftAssessment(formData, setFormData);

  // Add a query to fetch the latest assessment data
  const { data: latestAssessment } = useQuery({
    queryKey: ["assessment", draftAssessmentId],
    queryFn: async () => {
      if (!draftAssessmentId) return null;
      
      const { data, error } = await supabase
        .from("assessments")
        .select("*")
        .eq("id", draftAssessmentId)
        .single();

      if (error) {
        console.error("Error fetching assessment:", error);
        throw error;
      }

      return data;
    },
    enabled: !!draftAssessmentId,
  });

  // Update form data when latest assessment changes
  useEffect(() => {
    if (latestAssessment) {
      setFormData(prev => ({
        ...prev,
        selectedMedication: latestAssessment.medication,
        selectedPlan: latestAssessment.plan_type,
        assessment: latestAssessment,
        id: latestAssessment.id,
      }));
    }
  }, [latestAssessment, setFormData]);

  // Initialize validation state based on current step
  useEffect(() => {
    if (currentStep === 2) {
      // Basic Info step validation
      const isDateValid = !!formData.dateOfBirth;
      const isGenderValid = !!formData.gender;
      const isPhoneValid = formData.cellPhone && formData.cellPhone.replace(/\D/g, '').length >= 10;
      setIsCurrentStepValid(isDateValid && isGenderValid && isPhoneValid);
    } else {
      // For other steps, we'll set specific validation logic or default to true
      setIsCurrentStepValid(true);
    }
    
    // If we're on the confirmation screen, hide the previous button
    setHidePreviousButton(currentStep === 9);
  }, [currentStep, formData]);
  
  const { handlePlanSelect } = usePlanSelection({
    formData,
    onSuccess: (plan, assessmentId) => {
      setFormData({ ...formData, selectedPlan: plan });
      setFormData(prev => ({ ...prev, assessmentId }));
      handleNext();
    },
  });

  const { handlePaymentSuccess } = usePaymentSuccess({
    formData,
    onSuccess: (assessment) => {
      setFormData(prev => ({ ...prev, assessment }));
      handleNext();
    },
  });

  const handleMedicationSelect = async (medication: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Error",
          description: "Please sign in to continue",
          variant: "destructive",
        });
        return;
      }

      setFormData({ ...formData, selectedMedication: medication });
      handleNext();
    } catch (error) {
      console.error("Error selecting medication:", error);
      toast({
        title: "Error",
        description: "Failed to select medication. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleValidationChange = (isValid: boolean) => {
    setIsCurrentStepValid(isValid);
  };

  // Check for the hidden input to update the hidePreviousButton state
  useEffect(() => {
    const hiddenInput = document.getElementById('hide-previous-button');
    if (hiddenInput && hiddenInput.getAttribute('data-hide-previous') === 'true') {
      setHidePreviousButton(true);
    }
  }, [currentStep]);

  const isShippingComplete = () => {
    return (
      formData.shippingAddress?.trim() &&
      formData.shippingCity?.trim() &&
      formData.shippingState?.trim() &&
      formData.shippingZip?.trim()
    );
  };

  const isMedicationStepComplete = () => {
    return formData.selectedMedication && formData.selectedPlan;
  };

  // Get the button text based on the current step
  const getNextButtonText = () => {
    if (currentStep === 5) return "Select Your Plan";
    if (currentStep === 6) return "Continue";
    return "Continue";
  };

  const isNextDisabled = () => {
    if (currentStep === 2) {
      // Disable next button if basic info is not valid
      return !isCurrentStepValid;
    }
    if (currentStep === 5) {
      // Disable next button if no medication is selected
      return !formData.selectedMedication;
    }
    if (currentStep === 6) {
      // Disable next button if no plan is selected or plan is "pending"
      return !formData.selectedPlan || formData.selectedPlan === "pending";
    }
    if (currentStep === 7) {
      return !isShippingComplete();
    }
    return false;
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle>Patient Application</CardTitle>
        {currentStep < 7 && (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} className="mt-2" />
        )}
      </CardHeader>
      <CardContent>
        <AssessmentSteps
          currentStep={currentStep}
          formData={formData}
          onFormDataChange={setFormData}
          onMedicationSelect={handleMedicationSelect}
          onPlanSelect={handlePlanSelect}
          onPaymentSuccess={handlePaymentSuccess}
          onPrevious={handlePrevious}
          onValidationChange={handleValidationChange}
        />
        
        {/* Only render the StepsNavigation component if we're not on the confirmation screen (step 9) */}
        {currentStep !== 9 && (
          <StepsNavigation
            currentStep={currentStep}
            totalSteps={totalSteps}
            onNext={handleNext}
            onPrevious={handlePrevious}
            isNextDisabled={isNextDisabled()}
            nextButtonText={getNextButtonText()}
          />
        )}
      </CardContent>
    </Card>
  );
};
