import { useToast } from "@/hooks/use-toast"
import { supabase } from "@/integrations/supabase/client"
import { Assessment, isValidAssessment, isValidProfile } from "../types"
import { useQueryClient } from "@tanstack/react-query"

export const useStatusUpdate = () => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const handleStatusUpdate = async (
    assessmentId: string, 
    newStatus: "prescribed" | "denied" | "completed", 
    denialReason?: string
  ) => {
    try {
      console.log("Starting status update:", { assessmentId, newStatus, denialReason })
      
      const { data: fetchedAssessment, error: fetchError } = await supabase
        .from("assessments")
        .select(`
          *,
          profiles (
            id,
            first_name,
            last_name,
            email
          )
        `)
        .eq("id", assessmentId)
        .single()

      if (fetchError) {
        console.error("Error fetching assessment:", fetchError)
        throw new Error("Failed to fetch assessment")
      }

      if (!fetchedAssessment || !isValidAssessment(fetchedAssessment)) {
        console.error("Assessment not found or invalid with ID:", assessmentId)
        throw new Error("Assessment not found or no longer exists")
      }

      const assessment = fetchedAssessment as Assessment

      const updateData = { 
        status: newStatus,
        denial_reason: newStatus === "completed" ? null : (denialReason || assessment.denial_reason)
      }

      const { error: updateError } = await supabase
        .from("assessments")
        .update(updateData)
        .eq("id", assessmentId)

      if (updateError) {
        console.error("Supabase update error:", updateError)
        throw updateError
      }

      console.log("Status updated successfully")

      const automatedMessage = newStatus === "prescribed" 
        ? `Your ${assessment.medication} prescription has been approved and will be processed shortly.`
        : newStatus === "denied"
        ? `Your ${assessment.medication} prescription request has been denied. Reason: ${denialReason || "No reason provided"}`
        : `Your ${assessment.medication} prescription request is being reviewed by our medical team.`;

      const { error: messageError } = await supabase
        .from("messages")
        .insert({
          assessment_id: assessmentId,
          sender_id: assessment.user_id, // Using patient's ID as sender for system messages
          content: automatedMessage,
          is_provider: true,
          is_notification: true // Mark as a system notification
        })

      if (messageError) {
        console.error("Error sending automated message:", messageError)
        // Don't throw error here, as the status update was successful
      }

      await queryClient.invalidateQueries({ queryKey: ["provider-assessments"] })

      const userEmail = isValidProfile(assessment.profiles) ? assessment.profiles.email : null;
      if ((newStatus === "prescribed" || newStatus === "denied") && userEmail) {
        console.log('Sending email to:', userEmail)
        const { error: emailError } = await supabase.functions.invoke('send-status-email', {
          body: {
            to: userEmail,
            status: newStatus,
            denialReason: denialReason || "No reason provided",
            medication: assessment.medication,
            replyTo: "support@mybellehealth.com"
          }
        })

        if (emailError) {
          console.error("Error sending email:", emailError)
          toast({
            title: "Warning",
            description: "Status updated but failed to send email notification",
            variant: "destructive",
          })
        } else {
          console.log('Email sent successfully')
        }
      }

      toast({
        title: "Success",
        description: `Assessment has been ${newStatus}`,
      })
    } catch (error: any) {
      console.error("Error updating status:", error)
      toast({
        title: "Error",
        description: error.message || "Failed to update assessment status",
        variant: "destructive",
      })
      throw error
    }
  }

  return { handleStatusUpdate }
}
