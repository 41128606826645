
import { Profile, ProfileOrError, isValidProfile } from "@/types/database/profile";
import { Assessment as BaseAssessment } from "@/types/database/assessment";

export interface Assessment extends BaseAssessment {
  is_renewal?: boolean;
  renewal_count?: number;
  renewal_from_assessment_id?: string;
  renewal_status?: string;
  prescribed_at?: string;
  subscription_started_at?: string;
  subscription_end_date?: string;
  auto_renew?: boolean;
  subscription_status?: string;
  // We don't need to repeat discounted_amount and applied_promo_code here
  // since they're now in the BaseAssessment interface
}

export { isValidProfile };

// Type guard to validate assessment data
export function isValidAssessment(obj: any): obj is Assessment {
  if (!obj || typeof obj !== 'object' || !('id' in obj)) {
    console.log('Invalid assessment object structure:', obj);
    return false;
  }

  if (obj.profiles === null || obj.profiles === undefined) {
    return true;
  }

  if (obj.profiles && 'error' in obj.profiles) {
    console.log('Profile contains error, converting to null:', obj.profiles);
    obj.profiles = null;
    return true;
  }

  if (obj.profiles && typeof obj.profiles === 'object') {
    if (!isValidProfile(obj.profiles)) {
      console.log('Invalid profile structure, converting to null:', obj.profiles);
      obj.profiles = null;
    }
  }

  return true;
}
