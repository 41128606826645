
import { PaymentProvider } from "./payment/PaymentFormProvider";
import { usePaymentContext } from "./payment/PaymentFormContext";
import { usePaymentSubmission } from "./payment/usePaymentSubmission";
import { Button } from "@/components/ui/button";
import { PaymentFormFields } from "./PaymentFormFields";
import { useQueryClient } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Loader, LockIcon } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { format, addDays } from "date-fns";
import { OrderSummary } from "./payment/OrderSummary";
import { useIsMobile } from "@/hooks/use-mobile";
import { useState } from "react";

interface PaymentFormProps {
  subscriptionId: string;
  onSuccess: () => void;
  onCancel: () => void;
  amount: number;
}

const PaymentFormContent = ({ subscriptionId, onSuccess, amount, onCancel }: PaymentFormProps) => {
  const { paymentData, handleSavedMethodSelect, clearSavedMethod, handleSaveCardChange } = usePaymentContext();
  const { isProcessing, handleSubmit } = usePaymentSubmission(subscriptionId, onSuccess);
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const [discountedAmount, setDiscountedAmount] = useState(amount);
  const [appliedPromoCode, setAppliedPromoCode] = useState<string | null>(null);

  const { data: assessment, isLoading: assessmentLoading } = useQuery({
    queryKey: ["assessment-details", subscriptionId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("assessments")
        .select("*")
        .eq("id", subscriptionId)
        .single();

      if (error) throw error;
      return data;
    },
  });

  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: ["payment-methods"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("payment_methods")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const numericAmount = Number(discountedAmount);
    if (isNaN(numericAmount) || numericAmount <= 0) {
      console.error('Invalid payment amount:', { amount: discountedAmount, numericAmount });
      toast({
        title: "Invalid Amount",
        description: "Unable to process payment - invalid amount. Please try again.",
        variant: "destructive",
      });
      return;
    }

    console.log('Processing payment with amount:', numericAmount, 'Applied promo code:', appliedPromoCode);
    
    if (appliedPromoCode) {
      try {
        await supabase
          .from('assessments')
          .update({ 
            applied_promo_code: appliedPromoCode,
            discounted_amount: numericAmount 
          })
          .eq('id', subscriptionId);
      } catch (error) {
        console.error('Error updating assessment with promo code:', error);
      }
    }

    await handleSubmit(paymentData, numericAmount, handleSaveCardChange);
    await queryClient.invalidateQueries({ 
      queryKey: ["user-assessments"],
      refetchType: 'all'
    });
  };
  
  const handlePriceUpdate = (newAmount: number, promoCode: string | null) => {
    setDiscountedAmount(newAmount);
    setAppliedPromoCode(promoCode);
  };

  if (isLoading || assessmentLoading) {
    return (
      <div className="flex justify-center py-8">
        <Loader className="h-6 w-6 animate-spin text-primary" />
      </div>
    );
  }

  const formattedAmount = Number(amount).toFixed(2);
  const isValidAmount = !isNaN(Number(amount)) && Number(amount) > 0;

  const originalAmount = Number(amount) * 1.2;
  const savingsAmount = originalAmount - Number(amount);
  const offerEndDate = format(addDays(new Date(), 3), "MMM d, yyyy");

  return (
    <div>
      {isValidAmount && (
        <OrderSummary 
          medication={assessment?.medication || "Weight Management Medication"}
          planType={assessment?.plan_type || "3-Month Plan"}
          doseType={assessment?.dose_type || "Standard"}
          amount={Number(amount)}
          savingsAmount={savingsAmount}
          originalAmount={originalAmount}
          offerEndDate={offerEndDate}
          subscriptionId={subscriptionId}
          onPriceUpdate={handlePriceUpdate}
        />
      )}

      <form onSubmit={onSubmit} className="space-y-5">
        {paymentMethods && paymentMethods.length > 0 && (
          <div className="space-y-3">
            <h3 className="font-medium">Payment Method</h3>
            <RadioGroup
              defaultValue="new"
              onValueChange={(value) => {
                if (value === "new") {
                  clearSavedMethod();
                } else {
                  handleSavedMethodSelect(value);
                }
              }}
            >
              <div className="space-y-2">
                {paymentMethods.map((method) => (
                  <div 
                    key={method.id} 
                    className="flex items-center space-x-2 border rounded-md p-3 hover:bg-muted/30 transition-colors"
                  >
                    <RadioGroupItem value={method.id} id={method.id} />
                    <Label htmlFor={method.id} className="flex-1 cursor-pointer">
                      {method.card_brand} •••• {method.last_four} (expires {method.expiration_date})
                      {method.is_default && (
                        <span className="ml-2 text-xs text-muted-foreground">(Default)</span>
                      )}
                    </Label>
                  </div>
                ))}
                <div className="flex items-center space-x-2 border rounded-md p-3 hover:bg-muted/30 transition-colors">
                  <RadioGroupItem value="new" id="new" />
                  <Label htmlFor="new" className="flex-1 cursor-pointer">Use a new card</Label>
                </div>
              </div>
            </RadioGroup>
          </div>
        )}

        {(!paymentMethods?.length || !paymentData.savedMethodId) && (
          <div className="bg-muted/30 rounded-lg p-4">
            <h3 className="font-medium mb-4">Card Information</h3>
            <PaymentFormFields />
          </div>
        )}

        <div className="space-y-3 pt-2">
          <div className="flex flex-col space-y-2 sm:flex-row-reverse sm:justify-between sm:space-y-0 sm:space-x-4">
            <Button 
              type="submit" 
              disabled={isProcessing || !isValidAmount}
              variant="modern"
              size="modern"
              className="w-full sm:w-auto text-white rounded-md transition-all flex items-center justify-center shadow-md"
            >
              <LockIcon className="mr-1.5 h-3.5 w-3.5" />
              <span className="font-light tracking-wide text-xs">
                {isProcessing ? "Processing..." : "Complete Secure Payment"}
              </span>
            </Button>
            
            <Button 
              type="button" 
              variant="outline" 
              onClick={onCancel}
              disabled={isProcessing}
              className="w-full sm:w-auto sm:order-first"
              size={isMobile ? "lg" : "default"}
            >
              Back
            </Button>
          </div>
          
          <p className="text-xs text-center text-muted-foreground pt-2">
            By completing this payment, you agree to our <a href="#" className="underline">Terms of Service</a> and <a href="#" className="underline">Privacy Policy</a>
          </p>
        </div>
      </form>
    </div>
  );
};

export const PaymentForm = (props: PaymentFormProps) => {
  return (
    <PaymentProvider>
      <PaymentFormContent {...props} />
    </PaymentProvider>
  );
};
