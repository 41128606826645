
import { useState, useEffect } from "react"
import { supabase } from "@/integrations/supabase/client"
import { ChatStatus } from "@/types/messaging"
import { useToast } from "@/hooks/use-toast"

export const useChatStatuses = (userId: string | undefined) => {
  const [chatStatuses, setChatStatuses] = useState<Record<string, boolean>>({})
  const [isLoading, setIsLoading] = useState(true)
  const { toast } = useToast()
  
  useEffect(() => {
    if (!userId) {
      setIsLoading(false)
      return
    }

    const fetchChatStatuses = async () => {
      try {
        setIsLoading(true)
        console.log('Fetching chat statuses for user:', userId)
        
        // First, get all assessments that are eligible for chat
        const { data: assessmentData, error: assessmentError } = await supabase
          .from("assessments")
          .select("id")
          .or('status.eq.completed,status.eq.prescribed')

        if (assessmentError) {
          console.error("Error fetching assessments for chat status:", assessmentError)
          toast({
            title: "Error loading chat statuses",
            description: "Could not fetch chat completion status. Please try again.",
            variant: "destructive",
          })
          setIsLoading(false)
          return
        }

        // If no assessments found, set empty status map and return
        if (!assessmentData || assessmentData.length === 0) {
          console.log('No assessments found for chat status')
          setChatStatuses({})
          setIsLoading(false)
          return
        }

        const assessmentIds = assessmentData.map((a) => a.id)
        console.log('Assessment IDs for chat status:', assessmentIds.length)
        
        // Create a default status map where all chats are not complete
        const defaultStatusMap: Record<string, boolean> = {}
        assessmentIds.forEach(id => {
          defaultStatusMap[id] = false
        })

        // Now fetch chat_status records - fetch in smaller batches to avoid request size limits
        const batchSize = 100;
        let allStatusData: ChatStatus[] = [];
        
        for (let i = 0; i < assessmentIds.length; i += batchSize) {
          const batchIds = assessmentIds.slice(i, i + batchSize);
          console.log(`Fetching chat statuses batch ${i/batchSize + 1}/${Math.ceil(assessmentIds.length/batchSize)}`);
          
          const { data: batchData, error: batchError } = await supabase
            .from("chat_status")
            .select("assessment_id, is_complete")
            .in("assessment_id", batchIds);
            
          if (batchError) {
            console.error(`Error fetching chat statuses batch ${i/batchSize + 1}:`, batchError);
            continue; // Continue with next batch instead of failing completely
          }
          
          if (batchData) {
            allStatusData = [...allStatusData, ...batchData];
          }
        }

        console.log('Fetched chat statuses:', allStatusData.length);
        
        // Then override with actual statuses from database
        const statusMap = allStatusData.reduce(
          (acc: Record<string, boolean>, status: ChatStatus) => {
            acc[status.assessment_id] = status.is_complete
            return acc
          },
          defaultStatusMap
        )

        console.log('Final chat status map:', Object.keys(statusMap).length);
        setChatStatuses(statusMap)
        setIsLoading(false)
      } catch (error) {
        console.error("Unexpected error in fetchChatStatuses:", error)
        toast({
          title: "Error loading chat statuses",
          description: "Could not fetch chat completion status. Please try again.",
          variant: "destructive",
        })
        setIsLoading(false)
      }
    }

    // Initial fetch
    fetchChatStatuses()

    // Subscribe to chat status changes
    const statusChannel = supabase
      .channel("chat-status-changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "chat_status",
        },
        (payload) => {
          console.log('Chat status realtime update received:', payload)
          
          if (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') {
            const newStatus = payload.new as ChatStatus
            console.log('Updating chat status in state:', newStatus.assessment_id, newStatus.is_complete)
            
            // Update local state with the new status
            setChatStatuses(prev => ({
              ...prev,
              [newStatus.assessment_id]: newStatus.is_complete
            }))
          }
        }
      )
      .subscribe((status) => {
        console.log('Chat status subscription status:', status)
      })

    return () => {
      console.log('Cleaning up chat status subscription')
      supabase.removeChannel(statusChannel)
    }
  }, [userId, toast])

  return { chatStatuses, setChatStatuses, isLoading }
}
