
import { useState } from "react";
import { PlusIcon, SearchIcon } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { toast } from "sonner";
import { EmptyState } from "@/components/provider/EmptyState";
import { PromoCodeDialog } from "./PromoCodeDialog";
import { PromoCodeDetailsDialog } from "./PromoCodeDetailsDialog";
import { PromoCode } from "./types";

export const PromoCodesPage = () => {
  const [search, setSearch] = useState("");
  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCode | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  console.log("Rendering PromoCodesPage component");

  const { data: promoCodes, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["promo-codes"],
    queryFn: async () => {
      console.log("Fetching promo codes");
      try {
        const { data, error } = await supabase
          .from("promo_codes")
          .select("*")
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching promo codes:", error);
          toast.error("Failed to load promo codes");
          throw error;
        }
        
        console.log("Fetched promo codes:", data);
        return data as PromoCode[];
      } catch (err) {
        console.error("Exception fetching promo codes:", err);
        toast.error("An error occurred while loading promo codes");
        throw err;
      }
    },
  });

  // Fetch successful usage counts for each promo code
  const { data: successfulUsageCounts } = useQuery({
    queryKey: ["promo-code-successful-usages"],
    queryFn: async () => {
      if (!promoCodes?.length) return {};
      
      try {
        const { data, error } = await supabase
          .from("promo_code_usages")
          .select(`
            promo_code_id,
            assessments!inner(status)
          `)
          .in("assessments.status", ["completed", "prescribed"]);
          
        if (error) {
          console.error("Error fetching successful usages:", error);
          return {};
        }
        
        // Count successful usages per promo code
        const counts: Record<string, number> = {};
        data.forEach(usage => {
          counts[usage.promo_code_id] = (counts[usage.promo_code_id] || 0) + 1;
        });
        
        return counts;
      } catch (err) {
        console.error("Exception fetching successful usages:", err);
        return {};
      }
    },
    enabled: !!promoCodes?.length,
  });

  console.log("Query state:", { isLoading, isError, hasData: !!promoCodes, count: promoCodes?.length });
  console.log("Successful usage counts:", successfulUsageCounts);

  const filteredPromoCodes = promoCodes?.filter((code) =>
    code.code.toLowerCase().includes(search.toLowerCase()) ||
    (code.description?.toLowerCase() || "").includes(search.toLowerCase())
  );

  const handleToggleStatus = async (promoCode: PromoCode) => {
    try {
      console.log("Toggling status for promo code:", promoCode.id);
      const { error } = await supabase
        .from("promo_codes")
        .update({ 
          is_active: !promoCode.is_active,
          updated_at: new Date().toISOString()
        })
        .eq("id", promoCode.id);
      
      if (error) {
        console.error("Error toggling promo code status:", error);
        toast.error("Failed to update promo code status");
        throw error;
      }
      
      console.log("Status toggled successfully");
      toast.success("Promo code status updated");
      refetch();
    } catch (error) {
      console.error("Error toggling promo code status:", error);
      toast.error("An error occurred while updating the promo code");
    }
  };

  if (isError) {
    console.error("Error in promo codes query:", error);
    return (
      <div className="container mx-auto py-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Promo Codes</h1>
          <Button onClick={() => {
            setSelectedPromoCode(null);
            setIsDialogOpen(true);
          }}>
            <PlusIcon className="mr-2 h-4 w-4" />
            Create Promo Code
          </Button>
        </div>
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-md">
          <p>Failed to load promo codes. Please try again later.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Promo Codes</h1>
        <Button onClick={() => {
          setSelectedPromoCode(null);
          setIsDialogOpen(true);
        }}>
          <PlusIcon className="mr-2 h-4 w-4" />
          Create Promo Code
        </Button>
      </div>

      <div className="relative mb-6">
        <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search promo codes..."
          className="pl-10"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center py-10">
          <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
        </div>
      ) : filteredPromoCodes?.length === 0 ? (
        <EmptyState 
          title="No Promo Codes Found" 
          description="Create your first promo code by clicking the 'Create Promo Code' button above."
        />
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Code</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Discount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usage</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiration</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredPromoCodes?.map((promoCode) => {
                const successfulUsages = successfulUsageCounts?.[promoCode.id] || 0;
                
                return (
                  <tr key={promoCode.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap font-medium">{promoCode.code}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{promoCode.description || "-"}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {promoCode.discount_type === "percentage" 
                        ? `${promoCode.discount_amount}%` 
                        : `$${promoCode.discount_amount.toFixed(2)}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex flex-col">
                        <span>{successfulUsages} successful</span>
                        <span className="text-xs text-gray-500">
                          {promoCode.usage_count} total attempts
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Badge variant={promoCode.is_active ? "success" : "destructive"}>
                        {promoCode.is_active ? "Active" : "Inactive"}
                      </Badge>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {promoCode.expiration_date 
                        ? format(new Date(promoCode.expiration_date), "MMM d, yyyy")
                        : "No expiration"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => handleToggleStatus(promoCode)}
                      >
                        {promoCode.is_active ? "Deactivate" : "Activate"}
                      </Button>
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => {
                          setSelectedPromoCode(promoCode);
                          setIsDialogOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button 
                        variant="outline" 
                        size="sm"
                        onClick={() => {
                          setSelectedPromoCode(promoCode);
                          setIsDetailsOpen(true);
                        }}
                      >
                        Details
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <PromoCodeDialog 
        open={isDialogOpen} 
        onOpenChange={setIsDialogOpen} 
        promoCode={selectedPromoCode}
        onSuccess={() => refetch()}
      />

      {selectedPromoCode && (
        <PromoCodeDetailsDialog
          open={isDetailsOpen}
          onOpenChange={setIsDetailsOpen}
          promoCodeId={selectedPromoCode.id}
        />
      )}
    </div>
  );
};

export default PromoCodesPage;
