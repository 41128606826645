export interface Profile {
  id: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_term: string | null;
  utm_content: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface ProfileError {
  error: true;
}

export type ProfileOrError = Profile | ProfileError | null;

// Type guard to check if a profile is valid
export function isValidProfile(profile: ProfileOrError): profile is Profile {
  if (!profile || 'error' in profile) {
    return false;
  }
  return true;
}