
import { useState, useEffect } from "react";
import { DateRange } from "react-day-picker";
import { DatePickerWithRange } from "@/components/ui/date-range-picker";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { Loader } from "lucide-react";
import { formatCurrency } from "@/utils/formatCurrency";
import { formatDate } from "@/utils/formatDate";

export const DateRangeAnalytics = () => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date(),
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState<{
    totalStarted: number;
    totalCompleted: number;
    totalRevenue: number;
  }>({
    totalStarted: 0,
    totalCompleted: 0,
    totalRevenue: 0,
  });

  useEffect(() => {
    const fetchMetrics = async () => {
      if (!date?.from) return;
      
      setIsLoading(true);
      try {
        const fromDate = date.from.toISOString();
        const toDate = date.to ? new Date(date.to.setHours(23, 59, 59, 999)).toISOString() : new Date().toISOString();
        
        // Fetch assessments started in date range
        const { count: startedCount, error: startedError } = await supabase
          .from("assessments")
          .select("*", { count: 'exact', head: true })
          .gte("started_at", fromDate)
          .lte("started_at", toDate);
          
        if (startedError) throw startedError;
        
        // Fetch completed assessments in date range
        const { count: completedCount, error: completedError } = await supabase
          .from("assessments")
          .select("*", { count: 'exact', head: true })
          .in("status", ["completed", "prescribed"])
          .gte("completed_at", fromDate)
          .lte("completed_at", toDate);
          
        if (completedError) throw completedError;
        
        // Fetch revenue for the date range
        const { data: revenueData, error: revenueError } = await supabase
          .from("assessments")
          .select("amount")
          .in("status", ["completed", "prescribed"])
          .gte("completed_at", fromDate)
          .lte("completed_at", toDate);
          
        if (revenueError) throw revenueError;
        
        const totalRevenue = revenueData.reduce((sum, item) => sum + Number(item.amount), 0);
        
        setMetrics({
          totalStarted: startedCount || 0,
          totalCompleted: completedCount || 0,
          totalRevenue: totalRevenue,
        });
      } catch (error) {
        console.error("Error fetching date range metrics:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchMetrics();
  }, [date]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Custom Date Range Analytics</CardTitle>
        <CardDescription>
          Select a date range to view metrics for that specific period
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-6">
          <DatePickerWithRange date={date} setDate={setDate} />
        </div>
        
        {isLoading ? (
          <div className="flex justify-center p-6">
            <Loader className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-slate-50 p-4 rounded-lg">
              <h3 className="text-sm font-medium text-muted-foreground">Assessments Started</h3>
              <p className="text-2xl font-bold">{metrics.totalStarted}</p>
              <p className="text-xs text-muted-foreground mt-1">
                {formatDate(date?.from?.toISOString() || null)} - {formatDate(date?.to?.toISOString() || null)}
              </p>
            </div>
            
            <div className="bg-slate-50 p-4 rounded-lg">
              <h3 className="text-sm font-medium text-muted-foreground">Assessments Completed</h3>
              <p className="text-2xl font-bold">{metrics.totalCompleted}</p>
              <p className="text-xs text-muted-foreground mt-1">
                {formatDate(date?.from?.toISOString() || null)} - {formatDate(date?.to?.toISOString() || null)}
              </p>
            </div>
            
            <div className="bg-slate-50 p-4 rounded-lg">
              <h3 className="text-sm font-medium text-muted-foreground">Revenue</h3>
              <p className="text-2xl font-bold">{formatCurrency(metrics.totalRevenue)}</p>
              <p className="text-xs text-muted-foreground mt-1">
                {formatDate(date?.from?.toISOString() || null)} - {formatDate(date?.to?.toISOString() || null)}
              </p>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
