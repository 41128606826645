import { useMessageQuery } from "./useMessageQuery"
import { useMessageSend } from "./useMessageSend"
import { useMessageSubscription } from "./useMessageSubscription"

export const useMessages = (assessmentId: string) => {
  const { messages, refetch, isLoading: isLoadingMessages } = useMessageQuery(assessmentId)
  const { sendMessage, isLoading: isSendingMessage } = useMessageSend(assessmentId)
  
  useMessageSubscription(assessmentId, refetch)

  return {
    messages,
    sendMessage,
    isLoading: isLoadingMessages || isSendingMessage,
    refetch
  }
}