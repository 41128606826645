
import { Assessment } from "../types"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Badge } from "@/components/ui/badge"
import { formatDate } from "@/utils/formatDate"

interface OrderDetailsModalProps {
  assessment: Assessment | null
  isOpen: boolean
  onClose: () => void
}

export const OrderDetailsModal = ({ assessment, isOpen, onClose }: OrderDetailsModalProps) => {
  if (!assessment) return null

  // Calculate the final price after discount (if any)
  const finalPrice = assessment.discounted_amount 
    ? assessment.discounted_amount 
    : assessment.amount;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Order Details</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-6 py-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-semibold mb-2">Order Information</h3>
              <div className="space-y-2">
                <p><span className="font-medium">Date:</span> {formatDate(assessment.created_at || '')}</p>
                <p><span className="font-medium">Status:</span> <Badge variant="outline" className="capitalize">{assessment.status}</Badge></p>
                <p><span className="font-medium">Medication:</span> {assessment.medication}</p>
                <p><span className="font-medium">Plan:</span> {assessment.plan_type}</p>
                <div>
                  <p className="font-medium mb-1">Price:</p>
                  {assessment.applied_promo_code ? (
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2">
                        <p>${finalPrice}</p>
                        <Badge variant="outline" className="text-green-600 border-green-200 bg-green-50">
                          Promo: {assessment.applied_promo_code}
                        </Badge>
                      </div>
                      {assessment.amount !== finalPrice && (
                        <p className="text-sm text-muted-foreground line-through">${assessment.amount}</p>
                      )}
                    </div>
                  ) : (
                    <p>${assessment.amount}</p>
                  )}
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="font-semibold mb-2">Shipping Details</h3>
              <div className="space-y-2">
                <p><span className="font-medium">Address:</span> {assessment.shipping_address}</p>
                <p><span className="font-medium">City:</span> {assessment.shipping_city}</p>
                <p><span className="font-medium">State:</span> {assessment.shipping_state}</p>
                <p><span className="font-medium">ZIP:</span> {assessment.shipping_zip}</p>
              </div>
            </div>
          </div>

          <div>
            <h3 className="font-semibold mb-2">Subscription Details</h3>
            <div className="space-y-2">
              <p><span className="font-medium">Subscription Status:</span> <Badge variant="outline" className="capitalize">{assessment.subscription_status}</Badge></p>
              {assessment.subscription_started_at && (
                <p><span className="font-medium">Started:</span> {formatDate(assessment.subscription_started_at)}</p>
              )}
              {assessment.subscription_end_date && (
                <p><span className="font-medium">Ends:</span> {formatDate(assessment.subscription_end_date)}</p>
              )}
              <p><span className="font-medium">Auto Renew:</span> {assessment.auto_renew ? 'Yes' : 'No'}</p>
            </div>
          </div>

          {assessment.is_renewal && (
            <div>
              <h3 className="font-semibold mb-2">Renewal Information</h3>
              <div className="space-y-2">
                <p><span className="font-medium">Renewal Count:</span> {assessment.renewal_count}</p>
                <p><span className="font-medium">Renewal Status:</span> <Badge variant="outline" className="capitalize">{assessment.renewal_status}</Badge></p>
                {assessment.previous_medication && (
                  <p><span className="font-medium">Previous Medication:</span> {assessment.previous_medication}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
