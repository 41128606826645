import { Assessment } from "@/types/database/assessment"
import { isValidProfile } from "@/types/database/profile"
import { format } from "date-fns"

interface PatientHeaderProps {
  assessment: Assessment;
}

export const PatientHeader = ({ assessment }: PatientHeaderProps) => {
  const profile = assessment.profiles
  const name = isValidProfile(profile)
    ? `${profile.first_name || ''} ${profile.last_name || ''}`.trim()
    : 'Unknown Patient'

  return (
    <div>
      <h2 className="text-lg font-semibold">{name}</h2>
      <p className="text-sm text-muted-foreground">
        Current: {assessment.medication} • Assessment #{assessment.id.slice(-4)} • 
        {assessment.created_at && format(new Date(assessment.created_at), 'PPP')}
      </p>
    </div>
  )
}