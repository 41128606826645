import { Button } from "@/components/ui/button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ClipboardList, MessageCircle, MessageSquare, Phone, UserCircle } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/hooks/useAuth";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/hooks/use-toast";

interface DashboardHeaderProps {
  onLogout: () => void;
}

export const DashboardHeader = ({ onLogout }: DashboardHeaderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0);
  const { toast } = useToast();
  
  const fetchUnreadCount = async () => {
    if (!user) return;

    const { data: assessments } = await supabase
      .from("assessments")
      .select("id")
      .eq("user_id", user.id)
      .in("status", ["completed", "prescribed"]);

    if (!assessments?.length) return;

    const { count } = await supabase
      .from("messages")
      .select("*", { count: "exact", head: true })
      .in("assessment_id", assessments.map(a => a.id))
      .eq("is_provider", true)
      .is("read_at", null);

    setUnreadCount(count || 0);
  };

  const markMessagesAsRead = async () => {
    if (!user) return;

    const { data: assessments } = await supabase
      .from("assessments")
      .select("id")
      .eq("user_id", user.id)
      .in("status", ["completed", "prescribed"]);

    if (!assessments?.length) return;

    const { error } = await supabase
      .from("messages")
      .update({ read_at: new Date().toISOString() })
      .in("assessment_id", assessments.map(a => a.id))
      .eq("is_provider", true)
      .is("read_at", null);

    if (!error) {
      setUnreadCount(0);
    }
  };

  const handleLogout = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        navigate("/");
        return;
      }

      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.error('Logout error:', error);
        if (error.message.includes('session_not_found')) {
          navigate("/");
          return;
        }
        
        toast({
          title: "Error signing out",
          description: "Please try again",
          variant: "destructive",
        });
        return;
      }

      onLogout();
      navigate("/");
      
    } catch (error) {
      console.error('Logout error:', error);
      toast({
        title: "Error signing out",
        description: "Please try again",
        variant: "destructive",
      });
    }
  };

  const navigationItems = [
    {
      title: "Assessments",
      icon: ClipboardList,
      path: "/dashboard",
    },
    {
      title: "Message Provider",
      icon: MessageSquare,
      path: "/dashboard/messages",
      badge: unreadCount > 0 ? unreadCount : undefined,
    },
    {
      title: "Profile",
      icon: UserCircle,
      path: "/dashboard/profile",
    },
  ];

  const NavLinks = () => (
    <>
      {navigationItems.map((item) => (
        <Link
          key={item.path}
          to={item.path}
          onClick={() => item.path === '/dashboard/messages' && markMessagesAsRead()}
          className={`flex items-center gap-2 text-sm font-medium transition-colors hover:text-primary ${
            location.pathname === item.path ? "text-primary" : "text-muted-foreground"
          }`}
        >
          <item.icon className="h-4 w-4" />
          <span>{item.title}</span>
          {item.badge && (
            <Badge variant="destructive" className="h-5 w-5 flex items-center justify-center p-0">
              {item.badge}
            </Badge>
          )}
        </Link>
      ))}
    </>
  );

  return (
    <header className="sticky top-0 z-50 w-full bg-white border-b border-border/40 shadow-sm">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <Link to="/dashboard" className="shrink-0">
            <img 
              src="/lovable-uploads/2a535174-fe56-4ae1-bfec-81017d75bb87.png" 
              alt="BelleHealth Logo" 
              className="h-8 object-contain"
            />
          </Link>

          <div className="hidden md:flex items-center gap-4">
            <div className="flex items-center gap-4">
              <a 
                href="tel:888-817-8857" 
                className="flex items-center gap-2 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
              >
                <Phone className="h-4 w-4" />
                <span>Call us</span>
              </a>
              <a 
                href="sms:888-817-8857" 
                className="flex items-center gap-2 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
              >
                <MessageCircle className="h-4 w-4" />
                <span>Text us</span>
              </a>
            </div>
            <Button 
              variant="outline" 
              onClick={handleLogout}
              className="hover:bg-destructive/10 hover:text-destructive"
            >
              Sign Out
            </Button>
          </div>

          {isMobile && (
            <Button 
              variant="outline" 
              onClick={handleLogout}
              className="hover:bg-destructive/10 hover:text-destructive"
            >
              Sign Out
            </Button>
          )}
        </div>

        {isMobile ? (
          <div className="pb-4">
            <div className="flex items-center justify-center gap-4 py-2">
              <a 
                href="tel:888-817-8857" 
                className="flex items-center gap-2 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
              >
                <Phone className="h-4 w-4" />
                <span>Call us</span>
              </a>
              <a 
                href="sms:888-817-8857" 
                className="flex items-center gap-2 text-sm font-medium text-primary hover:text-primary/80 transition-colors"
              >
                <MessageCircle className="h-4 w-4" />
                <span>Text us</span>
              </a>
            </div>
            <Tabs 
              defaultValue={location.pathname} 
              className="w-full"
              onValueChange={(value) => navigate(value)}
            >
              <TabsList className="w-full grid grid-cols-3 h-auto p-1 bg-muted/50">
                {navigationItems.map((item) => (
                  <TabsTrigger
                    key={item.path}
                    value={item.path}
                    className="flex flex-col items-center gap-1 px-2 py-3 data-[state=active]:bg-background relative"
                  >
                    <item.icon className="h-4 w-4" />
                    <span className="text-xs font-medium">{item.title}</span>
                    {item.badge && (
                      <Badge 
                        variant="destructive" 
                        className="absolute -top-1 -right-1 h-5 w-5 flex items-center justify-center p-0"
                      >
                        {item.badge}
                      </Badge>
                    )}
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
          </div>
        ) : (
          <nav className="hidden md:flex items-center gap-6 py-4">
            <NavLinks />
          </nav>
        )}
      </div>
    </header>
  );
};