import { MessageContainer } from "@/components/messages/MessageContainer"
import { useEffect, useRef } from "react"

interface Message {
  id: string
  content: string
  sender_id: string
  created_at: string
  is_provider: boolean
  is_notification?: boolean
}

interface MessageListProps {
  messages: Message[]
  currentUserId: string | undefined
}

export function MessageList({ messages, currentUserId }: MessageListProps) {
  const messagesEndRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 space-y-4">
        {messages.map((message) => (
          <MessageContainer
            key={message.id}
            message={message}
            isCurrentUser={message.sender_id === currentUserId}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  )
}