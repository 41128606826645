import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Card, CardContent } from "@/components/ui/card";
import { AssessmentFormData } from "@/types/assessment";
import { motion } from "framer-motion";

interface Glp1QuestionFormProps {
  formData: AssessmentFormData;
  onChange: (data: Partial<AssessmentFormData>) => void;
}

export const Glp1QuestionForm = ({ formData, onChange }: Glp1QuestionFormProps) => {
  return (
    <div className="space-y-8">
      <Card className="border-2 border-primary/20 overflow-hidden">
        <CardContent className="pt-6">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-6"
          >
            <div>
              <Label className="text-lg font-semibold mb-4 block text-secondary tracking-tight leading-relaxed">
                Have you ever taken <span className="text-primary font-bold">Semaglutide</span> or <span className="text-orange-400 font-bold">Tirzepatide</span> before?
              </Label>
              <RadioGroup
                value={formData.previousGlp1}
                onValueChange={(value) => onChange({ previousGlp1: value })}
                className="flex gap-4 mt-4"
              >
                <motion.div 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="flex-1"
                >
                  <Label
                    htmlFor="glp1-yes"
                    className="flex items-center justify-center p-4 border-2 border-primary/20 rounded-lg hover:border-primary/40 transition-colors cursor-pointer"
                  >
                    <RadioGroupItem value="yes" id="glp1-yes" className="border-2" />
                    <span className="ml-2 text-lg">Yes</span>
                  </Label>
                </motion.div>
                <motion.div 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="flex-1"
                >
                  <Label
                    htmlFor="glp1-no"
                    className="flex items-center justify-center p-4 border-2 border-primary/20 rounded-lg hover:border-primary/40 transition-colors cursor-pointer"
                  >
                    <RadioGroupItem value="no" id="glp1-no" className="border-2" />
                    <span className="ml-2 text-lg">No</span>
                  </Label>
                </motion.div>
              </RadioGroup>
            </div>

            {formData.previousGlp1 === "yes" && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="space-y-6"
              >
                <div className="pt-4">
                  <Label className="text-lg font-semibold mb-4 block text-secondary tracking-tight leading-relaxed">
                    Which medication did you take?
                  </Label>
                  <RadioGroup
                    value={formData.previousMedication}
                    onValueChange={(value) => onChange({ previousMedication: value })}
                    className="grid grid-cols-2 gap-4 mt-4"
                  >
                    <motion.div 
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Label
                        htmlFor="med-semaglutide"
                        className="flex items-center justify-center p-4 border-2 border-primary/20 rounded-lg hover:border-primary/40 transition-colors cursor-pointer"
                      >
                        <RadioGroupItem 
                          value="semaglutide" 
                          id="med-semaglutide" 
                          className="border-2"
                        />
                        <span className="ml-2 text-lg text-primary font-bold">Semaglutide</span>
                      </Label>
                    </motion.div>
                    <motion.div 
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <Label
                        htmlFor="med-tirzepatide"
                        className="flex items-center justify-center p-4 border-2 border-orange-300/20 rounded-lg hover:border-orange-300/40 transition-colors cursor-pointer"
                      >
                        <RadioGroupItem 
                          value="tirzepatide" 
                          id="med-tirzepatide" 
                          className="border-2"
                        />
                        <span className="ml-2 text-lg text-orange-400 font-bold">Tirzepatide</span>
                      </Label>
                    </motion.div>
                  </RadioGroup>
                </div>

                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className="pt-4"
                >
                  <Label className="text-lg font-semibold mb-4 block text-secondary tracking-tight leading-relaxed">
                    Was it in the last 8 weeks?
                  </Label>
                  <RadioGroup
                    value={formData.recentGlp1}
                    onValueChange={(value) => onChange({ recentGlp1: value })}
                    className="flex gap-4 mt-4"
                  >
                    <motion.div 
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="flex-1"
                    >
                      <Label
                        htmlFor="recent-glp1-yes"
                        className="flex items-center justify-center p-4 border-2 border-primary/20 rounded-lg hover:border-primary/40 transition-colors cursor-pointer"
                      >
                        <RadioGroupItem 
                          value="yes" 
                          id="recent-glp1-yes" 
                          className="border-2"
                        />
                        <span className="ml-2 text-lg">Yes</span>
                      </Label>
                    </motion.div>
                    <motion.div 
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="flex-1"
                    >
                      <Label
                        htmlFor="recent-glp1-no"
                        className="flex items-center justify-center p-4 border-2 border-primary/20 rounded-lg hover:border-primary/40 transition-colors cursor-pointer"
                      >
                        <RadioGroupItem 
                          value="no" 
                          id="recent-glp1-no" 
                          className="border-2"
                        />
                        <span className="ml-2 text-lg">No</span>
                      </Label>
                    </motion.div>
                  </RadioGroup>
                </motion.div>
              </motion.div>
            )}
          </motion.div>
        </CardContent>
      </Card>
    </div>
  );
};