
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { XIcon } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "sonner";

interface SalesBannerConfig {
  message: string;
  is_active: boolean;
  background_color: string;
  text_color: string;
}

const DEFAULT_BANNER = {
  message: "Limited time: Use Belle10 for 10% off",
  is_active: true,
  background_color: "#9b87f5",
  text_color: "#ffffff"
};

export const SalesBanner = () => {
  const [bannerConfig, setBannerConfig] = useState<SalesBannerConfig | null>(DEFAULT_BANNER);
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchBannerConfig = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("sales_banner")
          .select("*")
          .limit(1)
          .single();

        if (error) {
          console.log("Error fetching banner configuration:", error);
          
          if (error.code === 'PGRST116') {
            console.log("No banner configuration found, using default");
          }
          return;
        }

        if (data) {
          console.log("Banner configuration loaded:", data);
          setBannerConfig(data);
          // Reset visibility when banner config changes
          setIsVisible(true);
        }
      } catch (err) {
        console.error("Unexpected error fetching banner:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBannerConfig();

    // Subscribe to changes in the sales_banner table
    const channel = supabase
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'sales_banner'
        },
        (payload) => {
          console.log("Banner update detected:", payload);
          if (payload.new) {
            setBannerConfig(payload.new as SalesBannerConfig);
            // Reset visibility when banner config changes
            setIsVisible(true);
          }
        }
      )
      .subscribe((status) => {
        if (status !== 'SUBSCRIBED') {
          console.log("Failed to subscribe to banner updates:", status);
        } else {
          console.log("Successfully subscribed to banner updates");
        }
      });

    // Set body class when banner is visible
    if (bannerConfig && bannerConfig.is_active && isVisible) {
      document.body.classList.add('has-banner');
    } else {
      document.body.classList.remove('has-banner');
    }

    return () => {
      supabase.removeChannel(channel);
    };
  }, [bannerConfig?.is_active, isVisible]);

  // Update body class when banner visibility changes
  useEffect(() => {
    if (bannerConfig && bannerConfig.is_active && isVisible) {
      document.body.classList.add('has-banner');
    } else {
      document.body.classList.remove('has-banner');
    }
  }, [bannerConfig?.is_active, isVisible]);

  // Don't render anything if banner is inactive or user closed it
  if (!bannerConfig || !bannerConfig.is_active || !isVisible) {
    return null;
  }

  return (
    <AnimatePresence>
      <motion.div 
        className="fixed top-0 left-0 right-0 z-50 w-full shadow-md"
        style={{ 
          backgroundColor: bannerConfig.background_color || "#9b87f5",
          color: bannerConfig.text_color || "#ffffff"
        }}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -100, opacity: 0 }}
        transition={{ 
          type: "spring", 
          stiffness: 260, 
          damping: 20,
          duration: 0.5 
        }}
      >
        <div className="container mx-auto flex items-center justify-center relative py-3 px-4">
          <motion.p 
            className={isMobile ? "text-sm font-medium" : "text-base font-medium"}
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
          >
            {bannerConfig.message}
          </motion.p>
          <motion.button 
            onClick={() => setIsVisible(false)} 
            className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1.5 rounded-full hover:bg-white/20 transition-colors"
            aria-label="Close banner"
            whileHover={{ scale: 1.1, rotate: 5 }}
            whileTap={{ scale: 0.9 }}
          >
            <XIcon className="h-4 w-4" />
          </motion.button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
