
import { Label } from "@/components/ui/label";
import { RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Check, AlertCircle, Loader2 } from "lucide-react";
import { useLowestMedicationPrice } from "@/hooks/useLowestMedicationPrice";

interface MedicationCardProps {
  medication: "semaglutide" | "tirzepatide";
  isSelected: boolean;
  onSelect: () => void;
  needsRampUp: boolean;
  imagePath: string;
  isCurrent?: boolean;
}

export const MedicationCard = ({
  medication,
  isSelected,
  onSelect,
  needsRampUp,
  imagePath,
  isCurrent,
}: MedicationCardProps) => {
  const displayName = medication.charAt(0).toUpperCase() + medication.slice(1);
  
  const { data: lowestPrice, isLoading: isPriceLoading } = useLowestMedicationPrice(
    medication,
    needsRampUp
  );

  const getMedicationDescription = (med: string) => {
    if (med === "semaglutide") {
      return needsRampUp 
        ? "The most widely prescribed GLP-1 medication. Known for its proven track record of effectiveness and well-understood safety profile."
        : "Continue with your current semaglutide treatment plan, maintaining the effectiveness you've experienced.";
    }
    return med === "tirzepatide" 
      ? needsRampUp 
        ? "A dual-action medication that targets both GLP-1 and GIP receptors, showing up to 30% more weight loss with fewer reported side effects."
        : "Continue with your current tirzepatide treatment plan, maintaining the effectiveness you've experienced."
      : "";
  };

  return (
    <div 
      className={cn(
        "relative flex flex-col items-center p-6 border rounded-xl transition-all hover:border-primary cursor-pointer",
        isSelected && "border-primary ring-2 ring-primary bg-primary/5"
      )}
      onClick={onSelect}
    >
      <RadioGroupItem 
        value={medication} 
        id={medication} 
        className="absolute top-4 right-4" 
      />
      
      {isCurrent ? (
        <Badge variant="secondary" className="absolute top-4 left-4 flex items-center gap-1">
          <Check className="h-3 w-3" />
          Currently Taking
        </Badge>
      ) : needsRampUp && (
        <Badge variant="secondary" className="absolute top-4 left-4 flex items-center gap-1 bg-orange-100 text-orange-800">
          <AlertCircle className="h-3 w-3" />
          New Patient Medication
        </Badge>
      )}
      
      <div className="w-48 h-48 mb-4">
        <img 
          src={imagePath}
          alt={`${displayName} Medication`}
          className="w-full h-full object-contain"
          loading="lazy"
          width={192}
          height={192}
        />
      </div>
      <Label htmlFor={medication} className="text-center">
        <div className="font-semibold text-xl mb-2">{displayName}</div>
        <p className="text-sm text-muted-foreground mb-4">
          {getMedicationDescription(medication)}
        </p>
        {needsRampUp && (
          <p className="text-sm font-medium text-orange-700 mb-4">
            For new patients, it requires a gradual dose increase over several weeks for optimal results
          </p>
        )}
      </Label>
      <div className="mt-auto flex flex-col items-center">
        <span className="text-sm font-medium text-muted-foreground">Starting from</span>
        {isPriceLoading ? (
          <div className="flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span>Loading price...</span>
          </div>
        ) : lowestPrice ? (
          <span className="text-2xl font-bold text-primary">${lowestPrice}/month</span>
        ) : (
          <span className="text-sm text-muted-foreground">Price not available</span>
        )}
      </div>
    </div>
  );
};
