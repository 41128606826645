import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { EmptyAssessmentState } from "./EmptyAssessmentState";
import { CompletedAssessmentModal } from "./CompletedAssessmentModal";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { AssessmentCard } from "./AssessmentCard";

export const AssessmentsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const { user, loading: authLoading } = useAuth();
  const queryClient = useQueryClient();
  const [selectedAssessment, setSelectedAssessment] = useState<any>(null);

  const { data: assessments, isLoading, error } = useQuery({
    queryKey: ["user-assessments", user?.id],
    queryFn: async () => {
      try {
        if (!user?.id) {
          console.log("No user ID available for query");
          return [];
        }

        // Add a small delay to ensure auth is fully initialized
        await new Promise(resolve => setTimeout(resolve, 500));

        const { data, error } = await supabase
          .from("assessments")
          .select("*")
          .eq("user_id", user.id)
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching assessments:", error);
          throw error;
        }

        return data || [];
      } catch (error) {
        console.error("Error in assessment query:", error);
        // Return empty array instead of throwing to prevent error state
        return [];
      }
    },
    enabled: !!user?.id && !authLoading,
    staleTime: 1000,
    retry: 5,
    retryDelay: (attemptIndex) => Math.min(1000 * (2 ** attemptIndex), 10000),
  });

  const handleStartAssessment = async () => {
    try {
      if (!user?.id) {
        toast({
          title: "Error",
          description: "Please sign in to start an assessment",
          variant: "destructive",
        });
        return;
      }

      // First check for existing draft assessments
      const { data: existingDrafts, error: draftError } = await supabase
        .from("assessments")
        .select("*")
        .eq("user_id", user.id)
        .eq("status", "draft")
        .order("created_at", { ascending: false })
        .limit(1);

      if (draftError) {
        console.error("Error checking existing drafts:", draftError);
        throw draftError;
      }

      // If there's an existing draft, navigate to it
      if (existingDrafts && existingDrafts.length > 0) {
        console.log('Found existing draft:', existingDrafts[0]);
        navigate("/dashboard", {
          state: { 
            startNew: true,
            assessmentId: existingDrafts[0].id,
            continueExisting: true
          },
          replace: true
        });
        toast({
          title: "Draft Found",
          description: "Continuing with your existing assessment draft.",
        });
        return;
      }

      // Check for recently completed assessments
      const { data: recentAssessments, error: recentError } = await supabase
        .from("assessments")
        .select("completed_at")
        .eq("user_id", user.id)
        .eq("status", "completed")
        .order("completed_at", { ascending: false })
        .limit(1);

      if (recentError) {
        console.error("Error checking recent assessments:", recentError);
        throw recentError;
      }

      // If there's a recent assessment, check if enough time has passed (24 hours)
      if (recentAssessments && recentAssessments.length > 0 && recentAssessments[0].completed_at) {
        const completedAt = new Date(recentAssessments[0].completed_at);
        const now = new Date();
        const hoursSinceCompletion = (now.getTime() - completedAt.getTime()) / (1000 * 60 * 60);

        if (hoursSinceCompletion < 24) {
          toast({
            title: "Please wait",
            description: "You can start a new assessment 24 hours after completing your last one.",
            variant: "destructive",
          });
          return;
        }
      }

      // Create a new draft assessment only if no existing draft was found
      const { data: newAssessment, error: createError } = await supabase
        .from("assessments")
        .insert({
          user_id: user.id,
          status: "draft",
          medication: "pending",
          plan_type: "pending",
          amount: 399,
          dose_type: "standard"
        })
        .select()
        .single();

      if (createError) {
        console.error("Error creating new assessment:", createError);
        throw createError;
      }

      // Invalidate the assessments query to refresh the list
      await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });

      // Navigate to dashboard with new assessment state
      navigate("/dashboard", {
        state: { 
          startNew: true,
          assessmentId: newAssessment.id
        },
        replace: true
      });
    } catch (error) {
      console.error("Error starting assessment:", error);
      toast({
        title: "Error",
        description: "Failed to start assessment. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Show loading state while auth is initializing
  if (authLoading) {
    return (
      <div className="space-y-4">
        <div className="h-8 bg-gray-200 animate-pulse rounded" />
        <div className="h-32 bg-gray-200 animate-pulse rounded" />
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="space-y-4">
        <EmptyAssessmentState onStartAssessment={handleStartAssessment} />
      </div>
    );
  }

  // Show empty state if no assessments
  if (!assessments?.length) {
    return <EmptyAssessmentState onStartAssessment={handleStartAssessment} />;
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Your Assessments</h2>
        <Button onClick={handleStartAssessment}>Start New Assessment</Button>
      </div>
      <div className="grid gap-4">
        {assessments.map((assessment) => (
          <AssessmentCard
            key={assessment.id}
            assessment={assessment}
            onViewAssessment={(assessment) => setSelectedAssessment(assessment)}
          />
        ))}
        {selectedAssessment && (
          <CompletedAssessmentModal
            assessment={selectedAssessment}
            open={!!selectedAssessment}
            onClose={() => setSelectedAssessment(null)}
          />
        )}
      </div>
    </div>
  );
};