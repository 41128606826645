import { useQuery } from "@tanstack/react-query"
import { supabase } from "@/integrations/supabase/client"
import { useAuth } from "@/hooks/useAuth"

export const useMessageQuery = (assessmentId: string) => {
  const { user } = useAuth()

  const query = useQuery({
    queryKey: ["messages", assessmentId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("messages")
        .select("*")
        .eq("assessment_id", assessmentId)
        .order("created_at", { ascending: true })

      if (error) throw error

      // Only mark messages as read if they're from other users and haven't been read yet
      const unreadMessages = data.filter(
        (msg) => !msg.read_at && msg.sender_id !== user?.id
      )

      if (unreadMessages.length > 0) {
        const { error: updateError } = await supabase
          .from("messages")
          .update({ read_at: new Date().toISOString() })
          .in(
            "id",
            unreadMessages.map((msg) => msg.id)
          )

        if (updateError) {
          console.error("Error marking messages as read:", updateError)
        }
      }

      return data
    },
    enabled: !!assessmentId && !!user,
  })

  return {
    messages: query.data || [],
    isLoading: query.isLoading,
    error: query.error,
    refetch: query.refetch
  }
}