
import { useEffect, useState } from "react"
import { useSearchParams, Link } from "react-router-dom"
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import { CheckCircle } from "lucide-react"

const UnsubscribeConfirmation = () => {
  const [searchParams] = useSearchParams()
  const [status, setStatus] = useState<"loading" | "success" | "error">("loading")
  const [message, setMessage] = useState("")
  
  useEffect(() => {
    const assessmentId = searchParams.get("assessmentId")
    
    if (!assessmentId) {
      setStatus("error")
      setMessage("Missing assessment ID. Unable to process your request.")
      return
    }
    
    // Call the update-email-preferences edge function
    const updateEmailPreferences = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_SUPABASE_URL}/functions/v1/update-email-preferences`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              assessmentId,
              optOut: true,
            }),
          }
        )
        
        const data = await response.json()
        
        if (response.ok) {
          setStatus("success")
          setMessage(data.message || "You have been successfully unsubscribed from assessment emails.")
        } else {
          setStatus("error")
          setMessage(data.error || "An error occurred while processing your request.")
        }
      } catch (error) {
        setStatus("error")
        setMessage("An error occurred while processing your request.")
        console.error("Error updating email preferences:", error)
      }
    }
    
    updateEmailPreferences()
  }, [searchParams])
  
  return (
    <div className="min-h-screen flex items-center justify-center bg-muted p-4">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
        {status === "loading" && (
          <div className="text-center">
            <p className="text-lg">Processing your request...</p>
          </div>
        )}
        
        {status === "success" && (
          <div className="text-center space-y-4">
            <CheckCircle className="mx-auto h-12 w-12 text-primary" />
            <h1 className="text-2xl font-semibold text-gray-800">Unsubscribed Successfully</h1>
            <Alert>
              <AlertTitle>Email Preferences Updated</AlertTitle>
              <AlertDescription>{message}</AlertDescription>
            </Alert>
            <div className="mt-6">
              <Link to="/dashboard">
                <Button className="w-full">Back to Dashboard</Button>
              </Link>
            </div>
          </div>
        )}
        
        {status === "error" && (
          <div className="text-center space-y-4">
            <h1 className="text-2xl font-semibold text-gray-800">Error Processing Request</h1>
            <Alert variant="destructive">
              <AlertTitle>Something went wrong</AlertTitle>
              <AlertDescription>{message}</AlertDescription>
            </Alert>
            <div className="mt-6">
              <Link to="/dashboard">
                <Button className="w-full">Back to Dashboard</Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default UnsubscribeConfirmation
