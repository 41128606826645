
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useQueryClient } from "@tanstack/react-query";

interface PricingData {
  id: string;
  medication: string;
  dose_type: "standard" | "max";
  duration_months: number;
  monthly_price: number;
  total_price: number;
  patient_type: string;
}

interface EditPricingDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  pricing: PricingData | null;
}

export const EditPricingDialog = ({
  open,
  onOpenChange,
  pricing,
}: EditPricingDialogProps) => {
  const [monthlyPrice, setMonthlyPrice] = useState(pricing?.monthly_price.toString() || "");
  const [totalPrice, setTotalPrice] = useState(pricing?.total_price.toString() || "");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!pricing) return;

    setIsSubmitting(true);
    try {
      const { error } = await supabase
        .from("medication_pricing")
        .update({
          monthly_price: parseFloat(monthlyPrice),
          total_price: parseFloat(totalPrice),
          updated_by: (await supabase.auth.getUser()).data.user?.id,
          effective_date: new Date().toISOString(),
        })
        .eq("id", pricing.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Pricing has been updated successfully.",
      });

      queryClient.invalidateQueries({ queryKey: ["medication-pricing"] });
      onOpenChange(false);
    } catch (error) {
      console.error("Error updating pricing:", error);
      toast({
        title: "Error",
        description: "Failed to update pricing. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Pricing</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="medication">Medication</Label>
            <Input
              id="medication"
              value={pricing?.medication || ""}
              disabled
              className="capitalize"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="patientType">Patient Type</Label>
              <Input
                id="patientType"
                value={pricing?.patient_type || ""}
                disabled
                className="capitalize"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="doseType">Dose Type</Label>
              <Input
                id="doseType"
                value={pricing?.dose_type || ""}
                disabled
                className="capitalize"
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="duration">Duration</Label>
            <Input
              id="duration"
              value={`${pricing?.duration_months || ""} months`}
              disabled
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="monthlyPrice">Monthly Price</Label>
              <Input
                id="monthlyPrice"
                type="number"
                step="0.01"
                value={monthlyPrice}
                onChange={(e) => setMonthlyPrice(e.target.value)}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="totalPrice">Total Price</Label>
              <Input
                id="totalPrice"
                type="number"
                step="0.01"
                value={totalPrice}
                onChange={(e) => setTotalPrice(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
