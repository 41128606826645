
import { format } from "date-fns";
import { MessageSquare, CheckCircle } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Assessment } from "@/types/database/assessment";
import { isValidProfile } from "@/types/database/profile";

interface ChatCardProps {
  assessment: Assessment;
  isCompleted: boolean;
  unreadCount?: number;
  onMarkComplete: (assessmentId: string) => void;
  onClick: () => void;
  isSelected: boolean;
}

export const ChatCard = ({
  assessment,
  isCompleted,
  unreadCount = 0,
  onMarkComplete,
  onClick,
  isSelected,
}: ChatCardProps) => {
  const profile = assessment.profiles;
  const name = isValidProfile(profile)
    ? `${profile.first_name || ''} ${profile.last_name || ''}`.trim()
    : 'Unknown Patient';

  console.log(`Rendering ChatCard for ${assessment.id}:`, { isCompleted, unreadCount });

  return (
    <Card
      className={`p-4 cursor-pointer hover:bg-accent/50 ${
        isCompleted ? 'opacity-70' : ''
      } ${isSelected ? "border-primary" : ""}`}
      onClick={onClick}
    >
      <div className="flex justify-between items-start">
        <div>
          <h4 className="font-medium">{name}</h4>
          <p className="text-sm text-muted-foreground">
            {assessment.medication} - {format(new Date(assessment.created_at || ""), "PPP")}
          </p>
        </div>
        <div className="flex items-center gap-2">
          {!isCompleted && unreadCount > 0 && (
            <Badge variant="destructive">{unreadCount}</Badge>
          )}
          {!isCompleted && (
            <Button
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onMarkComplete(assessment.id);
              }}
              title="Mark as complete"
            >
              <CheckCircle className="h-4 w-4" />
            </Button>
          )}
          {isCompleted && <MessageSquare className="h-4 w-4 text-muted-foreground" />}
        </div>
      </div>
    </Card>
  );
};
