
import { useAuth } from "@/hooks/useAuth"
import { useAssessments } from "./useAssessments"
import { useChatStatuses } from "./useChatStatuses"
import { useUnreadCounts } from "./useUnreadCounts"
import { useChatMarkComplete } from "./useChatMarkComplete"
import { useEffect } from "react"

export const useChats = () => {
  const { user } = useAuth()
  const { assessments, isLoading: isLoadingAssessments } = useAssessments(user?.id)
  const { chatStatuses, setChatStatuses, isLoading: isLoadingStatuses } = useChatStatuses(user?.id)
  const { unreadCounts, setUnreadCounts, isLoading: isLoadingUnread } = useUnreadCounts(user?.id)
  const { handleMarkComplete, isMarkingComplete } = useChatMarkComplete(setChatStatuses, setUnreadCounts)
  
  // Add debugging to track state changes
  useEffect(() => {
    if (Object.keys(chatStatuses).length > 0) {
      console.log("Chat statuses updated in useChats:", Object.keys(chatStatuses).length)
      const completeCount = Object.values(chatStatuses).filter(status => status === true).length;
      console.log(`Complete chats: ${completeCount} out of ${Object.keys(chatStatuses).length}`);
    }
  }, [chatStatuses])

  useEffect(() => {
    if (assessments.length > 0 && Object.keys(chatStatuses).length > 0) {
      const completedCount = assessments.filter(a => chatStatuses[a.id] === true).length;
      const activeCount = assessments.filter(a => chatStatuses[a.id] !== true).length;
      console.log(`Chat status summary: ${completedCount} completed, ${activeCount} active out of ${assessments.length} total`);
    }
  }, [assessments, chatStatuses])

  const isLoading = isLoadingAssessments || isLoadingStatuses || isLoadingUnread

  return {
    assessments,
    chatStatuses,
    unreadCounts,
    handleMarkComplete,
    isMarkingComplete,
    isLoading
  }
}
