interface PricingFooterProps {
  needsRampUp: boolean;
}

export const PricingFooter = ({ needsRampUp }: PricingFooterProps) => {
  return (
    <div className="mt-8 text-sm text-muted-foreground text-center space-y-2 max-w-2xl mx-auto">
      <p>All plans include free shipping, 24/7 medical support, and our satisfaction guarantee.</p>
      {!needsRampUp && (
        <p>
          Your subscription will automatically renew at the end of your plan period. 
          You can manage your subscription settings at any time in your dashboard.
        </p>
      )}
    </div>
  );
};