
import { Button } from "@/components/ui/button";
import { ConfirmationHeader } from "./confirmation/ConfirmationHeader";
import { OrderSummary } from "./confirmation/OrderSummary";
import { useConfirmationStatus } from "./confirmation/useConfirmationStatus";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface ConfirmationScreenProps {
  subscription: {
    id: string;
    medication: string;
    plan_type: string;
    amount: number;
    status: string;
    dose_type?: "standard" | "max";
    discounted_amount?: number;
    applied_promo_code?: string;
  };
}

export const ConfirmationScreen = ({ subscription }: ConfirmationScreenProps) => {
  useConfirmationStatus(subscription);
  const navigate = useNavigate();

  useEffect(() => {
    if (subscription) {
      // Send purchase data to GTM
      const purchaseData = {
        event: 'purchase',
        ecommerce: {
          purchase: {
            transaction_id: subscription.id,
            value: subscription.discounted_amount || subscription.amount,
            currency: 'USD',
            items: [{
              item_name: subscription.medication,
              item_variant: subscription.dose_type || 'standard',
              price: subscription.discounted_amount || subscription.amount,
              quantity: 1
            }]
          }
        },
        user_journey_completed: true,
        plan_selected: subscription.plan_type,
        medication_selected: subscription.medication,
        promo_applied: subscription.applied_promo_code ? true : false
      };

      // Safely push to dataLayer
      if (window.dataLayer) {
        console.log('Sending purchase event to GTM:', purchaseData);
        window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object
        window.dataLayer.push(purchaseData);
      }
    }
  }, [subscription]); // Only run once when subscription data is available

  const handleReturnToDashboard = () => {
    navigate('/dashboard', { replace: true });
  };

  if (!subscription) {
    return (
      <div className="text-center">
        <p className="text-red-500">Error loading order details. Please contact support if this persists.</p>
        <Button
          variant="link"
          onClick={handleReturnToDashboard}
          className="mt-4"
        >
          Return to Dashboard
        </Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <ConfirmationHeader medication={subscription.medication} />
      <OrderSummary 
        medication={subscription.medication}
        planType={subscription.plan_type}
        amount={subscription.amount}
        doseType={subscription.dose_type}
        discountedAmount={subscription.discounted_amount}
        appliedPromoCode={subscription.applied_promo_code}
      />
      <div className="text-center space-y-4">
        <Button
          variant="link"
          onClick={handleReturnToDashboard}
        >
          Return to Dashboard
        </Button>
        <p className="text-sm text-muted-foreground">
          Have any questions? Call or text <a href="tel:888-817-8857" className="text-[#8BA89F] hover:underline">888-817-8857</a>
        </p>
      </div>
    </div>
  );
};
