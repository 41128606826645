import { format, differenceInDays } from "date-fns";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { useState } from "react";
import { SubscriptionDetailsModal } from "./SubscriptionDetailsModal";
import { ShippingUpdateModal } from "@/components/ShippingUpdateModal";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, BadgePercent, Calendar, Info, Pill } from "lucide-react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface AssessmentCardProps {
  assessment: any;
  onViewAssessment: (assessment: any) => void;
}

export const AssessmentCard = ({ assessment, onViewAssessment }: AssessmentCardProps) => {
  const navigate = useNavigate();
  const [showSubscription, setShowSubscription] = useState(false);
  const [showShippingUpdate, setShowShippingUpdate] = useState(false);
  const [showRampingDialog, setShowRampingDialog] = useState(false);

  const getMedicationImage = (medication: string) => {
    switch (medication.toLowerCase()) {
      case 'semaglutide':
        return "/lovable-uploads/e347af71-91bb-4e2f-828a-70324d37a174.png";
      case 'tirzepatide':
        return "/lovable-uploads/7cf5461e-63a4-44e6-af8a-5dfcdef7b6a8.png";
      default:
        return null;
    }
  };

  const getStatusDisplay = (status: string) => {
    switch (status) {
      case 'completed':
        return 'Provider Reviewing';
      case 'prescribed':
        return 'Prescribed';
      case 'denied':
        return 'Denied';
      default:
        return status;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-orange-100 text-orange-800';
      case 'prescribed':
        return 'bg-green-100 text-green-800';
      case 'denied':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const isExistingPatient = assessment.previous_glp1 === true && 
                           assessment.recent_glp1 === true && 
                           assessment.previous_medication?.toLowerCase() === assessment.medication.toLowerCase();

  const getDoseTypeDisplay = (assessment: any) => {
    if (!isExistingPatient) {
      return 'Ramping Up';
    }
    
    if (!assessment.dose_type) return 'Standard';
    return assessment.dose_type.charAt(0).toUpperCase() + assessment.dose_type.slice(1);
  };

  const getMonthlyAmount = (planType: string, totalAmount: number) => {
    const months = parseInt(planType.split(' ')[0]);
    return months ? Math.round(totalAmount / months) : totalAmount;
  };

  const handleCardClick = () => {
    onViewAssessment(assessment);
  };

  const startNewAssessment = () => {
    navigate("/dashboard", { 
      state: { 
        startNew: true 
      },
      replace: true
    });
  };

  const hasPaymentFailure = assessment.subscription_status === 'payment_failed';
  const hasUpcomingRenewal = assessment.subscription_end_date && 
    differenceInDays(new Date(assessment.subscription_end_date), new Date()) <= 7;

  const getRenewalStatus = () => {
    if (!assessment.subscription_end_date) return null;

    const daysUntilRenewal = differenceInDays(
      new Date(assessment.subscription_end_date),
      new Date()
    );

    if (daysUntilRenewal <= 0) {
      if (!isExistingPatient && assessment.status === 'prescribed') {
        return (
          <Alert className="mt-4 bg-muted/50">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              Your starter dose has expired. Please complete a medical assessment to continue on your new dosing plan or start a new medication.
            </AlertDescription>
          </Alert>
        );
      }
      
      return (
        <Alert className="mt-4 bg-muted/50">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Your prescription has expired. Please complete a new medical assessment.
          </AlertDescription>
        </Alert>
      );
    }

    if (daysUntilRenewal <= 7) {
      if (!isExistingPatient && assessment.status === 'prescribed') {
        return (
          <Alert className="mt-4 bg-muted/50">
            <AlertCircle className="h-4 w-4 text-yellow-600" />
            <AlertDescription className="text-yellow-800">
              Your starter dose will end in {daysUntilRenewal} days. Complete a medical assessment now to continue treatment or increase your dose.
            </AlertDescription>
          </Alert>
        );
      }
      
      return (
        <Alert className="mt-4 bg-muted/50">
          <Calendar className="h-4 w-4" />
          <AlertDescription>
            Your subscription will {assessment.auto_renew ? 'automatically renew' : 'expire'} in {daysUntilRenewal} days
          </AlertDescription>
        </Alert>
      );
    }

    // Show early reminder for ramping patients
    if (!isExistingPatient && assessment.status === 'prescribed' && daysUntilRenewal <= 14) {
      return (
        <Alert className="mt-4 bg-muted/50">
          <Calendar className="h-4 w-4" />
          <AlertDescription>
            Your starter dose prescription will end soon. Plan ahead and complete a medical assessment to continue your treatment.
          </AlertDescription>
        </Alert>
      );
    }

    return null;
  };

  const renewalAlert = getRenewalStatus();

  const hasPromoCode = !!assessment.applied_promo_code;
  const finalAmount = assessment.discounted_amount || assessment.amount;
  const discountAmount = hasPromoCode ? assessment.amount - finalAmount : 0;

  return (
    <Card key={assessment.id}>
      <CardHeader>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Pill className="h-5 w-5 text-primary" />
              <CardTitle className="text-lg capitalize">
                {assessment.medication}
              </CardTitle>
            </div>
            <div className="flex items-center gap-2">
              {assessment.subscription_status === 'active' && (
                <Badge variant="secondary" className="bg-blue-100 text-blue-800">
                  Active Subscription
                </Badge>
              )}
              {hasPaymentFailure && (
                <Badge variant="destructive">
                  Payment Failed
                </Badge>
              )}
              <Badge variant="secondary" className={getStatusColor(assessment.status)}>
                {getStatusDisplay(assessment.status)}
              </Badge>
              <Button 
                variant="ghost" 
                size="sm"
                className="h-8 w-8 p-0"
                onClick={() => onViewAssessment(assessment)}
              >
                <Info className="h-4 w-4" />
                <span className="sr-only">View Assessment Info</span>
              </Button>
            </div>
          </div>
          <span className="text-xs text-muted-foreground">
            Assessment from {format(new Date(assessment.created_at), "PPP")}
          </span>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid gap-4">
          {hasPaymentFailure && (
            <Alert variant="destructive" className="bg-muted/50">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                Your payment method needs to be updated to continue your subscription.
              </AlertDescription>
            </Alert>
          )}
          
          {renewalAlert}
          
          <div className="flex items-center gap-4">
            {getMedicationImage(assessment.medication) && (
              <img
                src={getMedicationImage(assessment.medication)}
                alt={assessment.medication}
                className="w-16 h-16 object-contain rounded-lg"
                loading="lazy"
                width={64}
                height={64}
              />
            )}
            <div className="flex-1 space-y-1">
              <div className="flex justify-between">
                <span className="text-muted-foreground">Plan:</span>
                <span className="font-medium">{assessment.plan_type}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-muted-foreground">Dose Type:</span>
                <Badge variant="outline" className="font-medium">
                  {getDoseTypeDisplay(assessment)}
                </Badge>
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="flex justify-between">
                  <span className="text-muted-foreground">Total Amount:</span>
                  {hasPromoCode ? (
                    <div className="flex items-center gap-1">
                      <BadgePercent className="h-3.5 w-3.5 text-green-600" />
                      <span className="font-medium">${finalAmount}</span>
                    </div>
                  ) : (
                    <span className="font-medium">${assessment.amount}</span>
                  )}
                </div>
                {hasPromoCode && (
                  <div className="flex justify-between text-sm">
                    <span className="text-muted-foreground">Original:</span>
                    <span className="line-through text-muted-foreground">${assessment.amount}</span>
                  </div>
                )}
                {hasPromoCode && (
                  <div className="flex justify-between text-sm">
                    <span className="text-green-600">Promo Code:</span>
                    <span className="text-green-600 font-medium">{assessment.applied_promo_code}</span>
                  </div>
                )}
                <div className="flex justify-between text-sm">
                  <span className="text-muted-foreground">Monthly Payment:</span>
                  <span className="font-medium">
                    ${getMonthlyAmount(assessment.plan_type, finalAmount)}/month
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          {assessment.status === "prescribed" && (
            <div className="flex gap-2">
              {isExistingPatient && (
                <>
                  <Button 
                    variant="outline"
                    className="flex-1"
                    onClick={() => setShowShippingUpdate(true)}
                  >
                    Update Address
                  </Button>
                  <Button 
                    variant={hasPaymentFailure ? "destructive" : "outline"}
                    className="flex-1"
                    onClick={() => setShowSubscription(true)}
                  >
                    {hasPaymentFailure ? "Update Payment Method" : "Modify Subscription"}
                  </Button>
                </>
              )}
              {!isExistingPatient && (
                <Button 
                  variant="outline"
                  className="flex-1"
                  onClick={() => setShowRampingDialog(true)}
                >
                  Increase Dose / Continue Protocol
                </Button>
              )}
            </div>
          )}

          {assessment.status === "draft" && (
            <Button 
              variant="outline"
              onClick={() => {
                navigate("/dashboard", { 
                  state: { 
                    continueAssessment: true, 
                    assessmentId: assessment.id 
                  } 
                });
              }}
            >
              Continue Assessment
            </Button>
          )}
        </div>
      </CardContent>

      <Dialog open={showRampingDialog} onOpenChange={setShowRampingDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Medical Assessment Required</DialogTitle>
            <DialogDescription className="pt-4">
              To increase your dose from the starter dose or continue your treatment protocol, you'll need to complete a brief medical assessment. This helps ensure your treatment plan remains safe and effective for you.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowRampingDialog(false)}>
              Cancel
            </Button>
            <Button onClick={startNewAssessment}>
              Start Medical Assessment
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <SubscriptionDetailsModal
        assessment={assessment}
        open={showSubscription}
        onClose={() => setShowSubscription(false)}
      />

      <ShippingUpdateModal
        assessment={assessment}
        open={showShippingUpdate}
        onClose={() => setShowShippingUpdate(false)}
      />
    </Card>
  );
};
