import { supabase } from "@/integrations/supabase/client";

export const calculateAmount = async (medication: string, plan: string, doseType: 'standard' | 'max' = 'standard'): Promise<number> => {
  console.log('Calculating amount for:', { medication, plan, doseType });

  if (medication === 'pending' || plan === 'pending') {
    console.log('Using default amount for pending medication/plan');
    return 399;
  }

  if (!medication || !plan) {
    console.error('Missing medication or plan:', { medication, plan });
    return 399;
  }

  // Extract the number of months from the plan string (e.g., "3 months" -> 3)
  const monthsMatch = plan.match(/^(\d+)/);
  if (!monthsMatch) {
    console.error('Invalid plan format:', plan);
    return 399;
  }

  const months = parseInt(monthsMatch[1]);

  try {
    const { data: pricingData, error } = await supabase
      .from('medication_pricing')
      .select('total_price')
      .eq('medication', medication.toLowerCase())
      .eq('duration_months', months)
      .eq('dose_type', doseType)
      .maybeSingle();

    if (error) {
      console.error('Error fetching pricing:', error);
      return 399;
    }

    if (!pricingData) {
      console.error('No pricing found for:', { medication, months, doseType });
      return 399;
    }

    console.log('Found pricing:', pricingData);
    return pricingData.total_price;
  } catch (error) {
    console.error('Error calculating amount:', error);
    return 399;
  }
};