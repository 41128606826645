import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";

export const medicalConditions = [
  "High/Low Blood Pressure",
  "Type 1 Diabetes",
  "Gastroparesis",
  "Low Blood Sugar",
  "Type 2 Diabetes",
  "History of Thyroid Cancer",
  "History of Pancreatitis",
  "Kidney Problems",
  "None of the above"
];

interface MedicalConditionsListProps {
  selectedConditions: string[];
  onChange: (conditions: string[]) => void;
}

export const MedicalConditionsList = ({ selectedConditions, onChange }: MedicalConditionsListProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-3">
      {medicalConditions.map((condition) => (
        <div key={condition} className="flex items-start space-x-2">
          <Checkbox
            id={condition}
            checked={selectedConditions.includes(condition)}
            onCheckedChange={(checked) => {
              const newConditions = checked
                ? [...selectedConditions, condition]
                : selectedConditions.filter((c) => c !== condition);
              onChange(newConditions);
            }}
          />
          <Label htmlFor={condition} className="text-sm leading-none pt-0.5">
            {condition}
          </Label>
        </div>
      ))}
    </div>
  );
};