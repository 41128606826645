import { formatCurrency } from "@/utils/formatCurrency";
import { Assessment } from "../types";

interface OrderHistoryHeaderProps {
  lifetimeSpend: number;
  totalOrders: number;
}

export const OrderHistoryHeader = ({ lifetimeSpend, totalOrders }: OrderHistoryHeaderProps) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Complete Order History</h3>
      <div className="flex items-center gap-4">
        <div>
          <span className="font-medium">Total Lifetime Spend:</span>
          <span className="ml-2">{formatCurrency(lifetimeSpend)}</span>
        </div>
        <div>
          <span className="font-medium">Total Orders:</span>
          <span className="ml-2">{totalOrders}</span>
        </div>
      </div>
    </div>
  );
};