
import { TableCell, TableRow } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Assessment } from "./types"
import { AssessmentActions } from "./AssessmentActions"
import { formatDate } from "@/utils/formatDate"
import { Profile } from "@/types/database/profile"
import { Badge } from "@/components/ui/badge"
import { BadgePercent } from "lucide-react"
import { formatCurrency } from "@/utils/formatCurrency"

interface AssessmentTableRowProps {
  assessment: Assessment
  showDenialReason?: boolean
  showActions?: boolean
  onPatientSelect: (userId: string, assessmentId: string) => void
  onStatusUpdate?: (assessmentId: string, newStatus: "prescribed" | "denied" | "completed", denialReason?: string) => Promise<void>
}

const isValidProfile = (profile: any): profile is Profile => {
  console.log("Validating profile:", profile);
  if (!profile || 'error' in profile) return false;
  return true;
};

export const AssessmentTableRow = ({
  assessment,
  showDenialReason = false,
  showActions = false,
  onPatientSelect,
  onStatusUpdate
}: AssessmentTableRowProps) => {
  console.log("Rendering AssessmentTableRow:", {
    assessmentId: assessment.id,
    userId: assessment.user_id,
    profiles: assessment.profiles,
  });

  const patientName = assessment.profiles && isValidProfile(assessment.profiles) 
    ? `${assessment.profiles.first_name || ''} ${assessment.profiles.last_name || ''}`.trim() || 'Unknown Patient'
    : 'Unknown Patient';

  console.log("Computed patient name:", patientName);

  const handleStatusUpdate = async (newStatus: "prescribed" | "denied" | "completed", denialReason?: string) => {
    if (onStatusUpdate) {
      await onStatusUpdate(assessment.id, newStatus, denialReason);
    }
  };

  // Check if a promo code was applied
  const hasPromoCode = !!assessment.applied_promo_code;
  // Get the final price (discounted if applicable)
  const finalPrice = assessment.discounted_amount || assessment.amount;

  return (
    <TableRow>
      <TableCell>
        <Button
          className="p-0 h-auto font-normal hover:underline"
          variant="link"
          onClick={() => onPatientSelect(assessment.user_id, assessment.id)}
        >
          {patientName}
        </Button>
      </TableCell>
      <TableCell>{assessment.plan_type}</TableCell>
      <TableCell>{assessment.medication}</TableCell>
      <TableCell>
        <div className="flex flex-col gap-1">
          <span>{assessment.status}</span>
          {assessment.is_renewal && (
            <Badge variant="secondary" className="w-fit">
              Renewal #{assessment.renewal_count || 0}
            </Badge>
          )}
        </div>
      </TableCell>
      {showDenialReason && (
        <TableCell>{assessment.denial_reason || 'N/A'}</TableCell>
      )}
      <TableCell>
        {assessment.created_at ? formatDate(assessment.created_at) : 'N/A'}
      </TableCell>
      <TableCell>
        {hasPromoCode ? (
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <BadgePercent className="h-3.5 w-3.5 text-green-600" />
              <span>{formatCurrency(finalPrice)}</span>
            </div>
            <span className="text-xs text-muted-foreground line-through">
              {formatCurrency(assessment.amount)}
            </span>
            <span className="text-xs text-muted-foreground">
              Code: {assessment.applied_promo_code}
            </span>
          </div>
        ) : (
          formatCurrency(assessment.amount)
        )}
      </TableCell>
      {showActions && (
        <TableCell>
          <AssessmentActions
            status={assessment.status}
            onStatusUpdate={handleStatusUpdate}
          />
        </TableCell>
      )}
    </TableRow>
  );
};
