import { DoseType } from "./types";
import { supabase } from "@/integrations/supabase/client";

interface CalculateAmountParams {
  medication: string;
  plan: string | { selectedPlan: string };
  doseType: DoseType;
}

export const calculateAmount = async ({ 
  medication, 
  plan, 
  doseType 
}: CalculateAmountParams): Promise<number | null> => {
  if (!medication || !plan) {
    console.error('Missing required parameters:', { medication, plan, doseType });
    return null;
  }

  // Handle both string and object plan formats
  const planString = typeof plan === 'object' ? plan.selectedPlan : plan;

  // Extract months from plan string (e.g., "3 months" -> 3)
  const monthsMatch = planString.match(/^(\d+)/);
  if (!monthsMatch) {
    console.error('Invalid plan format:', plan);
    return null;
  }

  const months = parseInt(monthsMatch[1]);

  const { data: pricingData, error } = await supabase
    .from('medication_pricing')
    .select('total_price')
    .eq('medication', medication.toLowerCase())
    .eq('duration_months', months)
    .eq('dose_type', doseType)
    .maybeSingle();

  if (error) {
    console.error('Error fetching pricing:', error);
    return null;
  }

  if (!pricingData) {
    console.error('No pricing found for:', { medication, months, doseType });
    return null;
  }

  return pricingData.total_price;
};