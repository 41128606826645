import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Assessment } from "../types"
import { OrderTableRow } from "./OrderTableRow"

interface OrderHistoryTableProps {
  assessments: Assessment[]
  onSelectOrder: (order: Assessment) => void
}

export const OrderHistoryTable = ({ assessments, onSelectOrder }: OrderHistoryTableProps) => {
  // Sort assessments by date, newest first
  const sortedAssessments = [...assessments].sort((a, b) => 
    new Date(b.created_at || '').getTime() - new Date(a.created_at || '').getTime()
  )

  console.log('Sorted assessments for order history:', sortedAssessments)

  return (
    <ScrollArea className="h-[400px] border rounded-md">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Request Type</TableHead>
            <TableHead>Medication</TableHead>
            <TableHead>Dose Type</TableHead>
            <TableHead>Duration</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedAssessments.map((assessment) => (
            <OrderTableRow
              key={assessment.id}
              assessment={assessment}
              onClick={() => onSelectOrder(assessment)}
            />
          ))}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}