
import { Assessment } from "@/components/provider/types"
import { supabase } from "@/integrations/supabase/client"
import { isValidProfile } from "@/types/database/profile"
import { formatDate } from "@/utils/formatDate"

const PAGE_SIZE = 1000;

const fetchAssessmentsByStatus = async (status: 'draft' | 'completed' | 'prescribed' | 'denied') => {
  let allAssessments: Assessment[] = [];
  let currentPage = 0;
  let hasMore = true;

  while (hasMore) {
    const from = currentPage * PAGE_SIZE;
    const to = from + PAGE_SIZE - 1;

    const { data, error, count } = await supabase
      .from("assessments")
      .select(`
        *,
        profiles (
          id,
          first_name,
          last_name,
          email,
          is_provider,
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
          created_at,
          updated_at
        )
      `, { count: 'exact' })
      .eq('status', status)
      .order('created_at', { ascending: false })
      .range(from, to);

    if (error) {
      console.error('Error fetching assessments:', error);
      throw error;
    }

    if (data) {
      allAssessments = [...allAssessments, ...(data as Assessment[])];
    }

    hasMore = count ? (from + PAGE_SIZE) < count : false;
    currentPage++;
  }

  return allAssessments;
}

export const convertAssessmentsToCSV = (assessments: Assessment[]) => {
  if (!assessments || assessments.length === 0) return '';

  const headers = [
    'Patient Name',
    'Email',
    'Date of Birth',
    'Phone',
    'Status',
    'Medication',
    'Plan Type',
    'Original Amount',
    'Promo Code',
    'Final Amount',
    'Submission Date',
    'Medical Conditions',
    'Height',
    'Weight',
    'Address',
    'City',
    'State',
    'ZIP',
    'UTM Source',
    'UTM Medium',
    'UTM Campaign',
    'UTM Term',
    'UTM Content'
  ];

  const rows = assessments.map(assessment => {
    const profile = assessment.profiles;
    const submissionDate = assessment.assessment_date || assessment.created_at;
    const finalAmount = assessment.discounted_amount || assessment.amount;

    return [
      isValidProfile(profile) ? `${profile.first_name || ''} ${profile.last_name || ''}` : 'Unknown Patient',
      isValidProfile(profile) ? profile.email || '' : '',
      assessment.date_of_birth || '',
      assessment.cell_phone || '',
      assessment.status || '',
      assessment.medication || '',
      assessment.plan_type || '',
      assessment.amount || '',
      assessment.applied_promo_code || '',
      finalAmount || '',
      submissionDate ? formatDate(submissionDate) : '',
      Array.isArray(assessment.medical_conditions) ? assessment.medical_conditions.join('; ') : '',
      assessment.patient_height || '',
      assessment.patient_weight || '',
      assessment.shipping_address || '',
      assessment.shipping_city || '',
      assessment.shipping_state || '',
      assessment.shipping_zip || '',
      isValidProfile(profile) ? profile.utm_source || assessment.utm_source || '' : assessment.utm_source || '',
      isValidProfile(profile) ? profile.utm_medium || assessment.utm_medium || '' : assessment.utm_medium || '',
      isValidProfile(profile) ? profile.utm_campaign || assessment.utm_campaign || '' : assessment.utm_campaign || '',
      isValidProfile(profile) ? profile.utm_term || assessment.utm_term || '' : assessment.utm_term || '',
      isValidProfile(profile) ? profile.utm_content || assessment.utm_content || '' : assessment.utm_content || ''
    ];
  });

  const csvContent = [
    headers.join(','),
    ...rows.map(row => row.map(cell => `"${String(cell).replace(/"/g, '""')}"`).join(','))
  ].join('\n');

  return csvContent;
};

export const downloadCSV = async (status: 'draft' | 'completed' | 'prescribed' | 'denied', filename: string) => {
  try {
    const assessments = await fetchAssessmentsByStatus(status);
    const csvContent = convertAssessmentsToCSV(assessments);
    
    if (!csvContent) {
      console.warn('No data to export');
      return;
    }
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CSV:', error);
    throw error;
  }
};
