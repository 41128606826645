import { useState } from "react"
import { MessageList } from "./MessageList"
import { MessageThread } from "./MessageThread"
import { PatientInfo } from "./PatientInfo"
import { Assessment } from "@/types/database/assessment"
import { ResizablePanelGroup, ResizablePanel, ResizableHandle } from "@/components/ui/resizable"

const ProviderMessages = () => {
  const [selectedAssessment, setSelectedAssessment] = useState<Assessment | null>(null)

  return (
    <div className="flex h-screen overflow-hidden">
      <main className="flex-1 overflow-y-auto bg-background">
        <ResizablePanelGroup direction="horizontal" className="h-full">
          {/* Left Sidebar - Message List */}
          <ResizablePanel defaultSize={30} minSize={20}>
            <div className="h-full border-r bg-white p-4">
              <MessageList 
                onSelectAssessment={setSelectedAssessment}
                selectedAssessmentId={selectedAssessment?.id}
              />
            </div>
          </ResizablePanel>

          <ResizableHandle withHandle />

          {/* Middle Section - Message Thread */}
          <ResizablePanel defaultSize={40} minSize={30}>
            <div className="h-full flex flex-col bg-white">
              {selectedAssessment ? (
                <div className="h-[95vh] p-4">
                  <MessageThread assessment={selectedAssessment} />
                </div>
              ) : (
                <div className="flex items-center justify-center h-full text-muted-foreground">
                  <p>Select a conversation to start messaging</p>
                </div>
              )}
            </div>
          </ResizablePanel>

          {/* Right Sidebar - Patient Info */}
          {selectedAssessment && (
            <>
              <ResizableHandle withHandle />
              <ResizablePanel defaultSize={30} minSize={20}>
                <div className="h-full border-l bg-white overflow-y-auto">
                  <PatientInfo assessment={selectedAssessment} />
                </div>
              </ResizablePanel>
            </>
          )}
        </ResizablePanelGroup>
      </main>
    </div>
  )
}

export default ProviderMessages