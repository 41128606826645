
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { AssessmentFormData } from "@/types/assessment";

const initialFormData: AssessmentFormData = {
  dateOfBirth: "",
  gender: "",
  cellPhone: "",
  selectedConditions: [],
  otherCondition: "",
  medullaryThyroidCancer: "no",
  familyMtcHistory: "no",
  men2: "no",
  pregnantOrBreastfeeding: "no",
  weight: "",
  heightFeet: "",
  heightInches: "",
  exerciseActivity: "",
  takingMedications: "no",
  medicationsList: "",
  previousGlp1: "no",
  previousMedication: "",
  recentGlp1: "no",
  recentGlp1Date: "", // Added this field
  hasAllergies: "no",
  allergiesList: "",
  takingBloodThinners: "no",
  selectedMedication: "",
  selectedPlan: "",
  shippingAddress: "",
  shippingCity: "",
  shippingState: "",
  shippingZip: "",
  assessment: null
};

const createNewDraftAssessment = async (userId: string) => {
  try {
    const { data, error } = await supabase
      .from('assessments')
      .insert({
        user_id: userId,
        status: 'draft',
        medication: 'pending',
        plan_type: 'pending',
        amount: 499,
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating draft assessment:', error);
    throw error;
  }
};

const loadExistingDraft = async (userId: string) => {
  try {
    // Get all draft assessments for the user, ordered by creation date
    const { data, error } = await supabase
      .from('assessments')
      .select('*')
      .eq('user_id', userId)
      .eq('status', 'draft')
      .order('created_at', { ascending: false })
      .limit(1);  // Only get the most recent one

    if (error) throw error;
    
    // Return the most recent draft or null if none exists
    return data && data.length > 0 ? data[0] : null;
  } catch (error) {
    console.error('Error loading draft assessment:', error);
    throw error;
  }
};

const mapAssessmentToFormData = (assessment: any): AssessmentFormData => ({
  dateOfBirth: assessment.date_of_birth || "",
  gender: assessment.gender || "",
  cellPhone: assessment.cell_phone || "",
  selectedConditions: assessment.medical_conditions || [],
  otherCondition: assessment.other_medical_conditions || "",
  medullaryThyroidCancer: assessment.medullary_thyroid_cancer ? "yes" : "no",
  familyMtcHistory: assessment.family_mtc_history ? "yes" : "no",
  men2: assessment.men2 ? "yes" : "no",
  pregnantOrBreastfeeding: assessment.pregnant_or_breastfeeding ? "yes" : "no",
  weight: assessment.patient_weight?.toString() || "",
  heightFeet: Math.floor((assessment.patient_height || 0) / 12).toString(),
  heightInches: ((assessment.patient_height || 0) % 12).toString(),
  exerciseActivity: assessment.exercise_activity || "",
  takingMedications: assessment.taking_medications ? "yes" : "no",
  medicationsList: assessment.medications_list || "",
  previousGlp1: assessment.previous_glp1 ? "yes" : "no",
  previousMedication: assessment.previous_medication || "",
  recentGlp1: assessment.recent_glp1 ? "yes" : "no",
  recentGlp1Date: assessment.recent_glp1_date || "", // Added this field
  hasAllergies: assessment.has_allergies ? "yes" : "no",
  allergiesList: assessment.allergies_list || "",
  takingBloodThinners: assessment.taking_blood_thinners ? "yes" : "no",
  selectedMedication: assessment.medication !== 'pending' ? assessment.medication : "",
  selectedPlan: assessment.plan_type !== 'pending' ? assessment.plan_type : "",
  shippingAddress: assessment.shipping_address || "",
  shippingCity: assessment.shipping_city || "",
  shippingState: assessment.shipping_state || "",
  shippingZip: assessment.shipping_zip || "",
  assessment: assessment
});

export const useLoadDraftAssessment = (setFormData: (data: AssessmentFormData) => void) => {
  const [draftAssessmentId, setDraftAssessmentId] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const loadDraftAssessment = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          console.log('No authenticated user found');
          return;
        }

        // Check if we're starting a new assessment from state
        const state = window.history.state?.usr;
        const isStartingNew = state?.startNew === true;
        const continueExisting = state?.continueExisting === true;
        const existingAssessmentId = state?.assessmentId;

        // If continuing existing draft, load it directly
        if (continueExisting && existingAssessmentId) {
          console.log('Continuing existing draft:', existingAssessmentId);
          const { data: existingDraft, error } = await supabase
            .from('assessments')
            .select('*')
            .eq('id', existingAssessmentId)
            .single();

          if (error) {
            console.error('Error loading existing draft:', error);
            throw error;
          }

          if (existingDraft) {
            console.log('Loaded existing draft:', existingDraft);
            setDraftAssessmentId(existingDraft.id);
            setFormData(mapAssessmentToFormData(existingDraft));
            return;
          }
        }

        // If starting new and not continuing existing
        if (isStartingNew && !continueExisting) {
          console.log('Starting new assessment');
          // Delete any existing drafts first
          await supabase
            .from('assessments')
            .delete()
            .eq('user_id', user.id)
            .eq('status', 'draft');

          const newAssessment = await createNewDraftAssessment(user.id);
          setDraftAssessmentId(newAssessment.id);
          setFormData(initialFormData);
          return;
        }

        // Try to load existing draft
        const existingDraft = await loadExistingDraft(user.id);
        
        if (existingDraft) {
          console.log('Found existing draft:', existingDraft);
          setDraftAssessmentId(existingDraft.id);
          setFormData(mapAssessmentToFormData(existingDraft));
        } else {
          console.log('No existing draft found');
        }
      } catch (error) {
        console.error('Error in loadDraftAssessment:', error);
        toast({
          title: "Error",
          description: "An unexpected error occurred. Please try again.",
          variant: "destructive",
        });
      }
    };

    loadDraftAssessment();
  }, [setFormData, toast]);

  return { draftAssessmentId };
};
