interface PricingHeaderProps {
  headerText: string;
  descriptionText: string;
}

export const PricingHeader = ({ headerText, descriptionText }: PricingHeaderProps) => {
  return (
    <div className="text-center space-y-4 mb-8">
      <h3 className="text-2xl font-semibold">{headerText}</h3>
      <p className="text-muted-foreground max-w-2xl mx-auto">
        {descriptionText}
      </p>
    </div>
  );
};