
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { AuthForm } from "@/components/AuthForm";
import { Button } from "@/components/ui/button";

const ProviderLogin = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  // Check initial auth state
  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      
      // First check user metadata
      const isProviderMeta = user?.user_metadata?.is_provider === true;
      
      if (isProviderMeta) {
        navigate("/provider");
        return;
      }
      
      // If not in metadata, check profiles table as fallback
      if (user) {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('is_provider')
          .eq('id', user.id)
          .single();
          
        if (!profileError && profile && profile.is_provider) {
          navigate("/provider");
          return;
        }
      }
    };
    checkAuth();
  }, [navigate]);

  // Add auth state change listener
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("Auth state change in login:", event, session?.user?.user_metadata)
      if (event === 'SIGNED_IN') {
        // First check user metadata
        const isProviderMeta = session?.user?.user_metadata?.is_provider === true;
        
        if (isProviderMeta) {
          navigate("/provider");
          return;
        }
        
        // If not in metadata, check profiles table as fallback
        if (session?.user) {
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('is_provider')
            .eq('id', session.user.id)
            .single();
            
          if (!profileError && profile && profile.is_provider) {
            navigate("/provider");
            return;
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    toast({
      title: "Logged out",
      description: "You have been successfully logged out.",
    });
  };

  const handleSubmit = async (data: { email: string; password: string }) => {
    try {
      setIsSubmitting(true);
      const { error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

      if (error) throw error;

      const { data: { user } } = await supabase.auth.getUser();
      
      // Check provider status in user metadata
      const isProviderMeta = user?.user_metadata?.is_provider === true;
      
      if (!isProviderMeta) {
        // Check profiles table as fallback
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('is_provider')
          .eq('id', user?.id)
          .single();
        
        if (profileError || !profile || !profile.is_provider) {
          toast({
            title: "Access Denied",
            description: "You don't have provider access.",
            variant: "destructive",
          });
          await supabase.auth.signOut();
          return;
        }
      }

      navigate("/provider");
    } catch (error: any) {
      console.error('Login error:', error);
      toast({
        title: "Login Failed",
        description: error.message || "An error occurred during login.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetPassword = async (email: string) => {
    try {
      setIsSubmitting(true);
      const resetUrl = `${window.location.origin}/reset-password`;
      console.log('Sending provider password reset with redirect URL:', resetUrl);
      
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: resetUrl,
      });

      if (error) throw error;

      toast({
        title: "Reset Link Sent",
        description: "Check your email for the password reset link.",
      });
      setShowResetPassword(false);
    } catch (error: any) {
      console.error('Reset password error:', error);
      toast({
        title: "Reset Failed",
        description: error.message || "Failed to send reset link.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-[#8BA89F]/5">
      <div className="container max-w-6xl mx-auto px-4 py-8">
        <div className="flex justify-end mb-4">
          <Button 
            variant="outline" 
            onClick={handleLogout}
            className="border-[#8BA89F] text-[#8BA89F] hover:bg-[#8BA89F]/10"
          >
            Logout
          </Button>
        </div>
        <div className="max-w-md mx-auto">
          <div className="bg-white p-8 rounded-lg shadow-lg border border-[#8BA89F]/10">
            <h2 className="text-2xl font-semibold text-[#8BA89F] mb-6 text-center">
              Provider Login
            </h2>
            <AuthForm
              mode="login"
              onSubmit={handleSubmit}
              onToggleMode={() => {}}
              disabled={isSubmitting}
              onResetPassword={handleResetPassword}
              showResetPassword={showResetPassword}
              onToggleResetPassword={() => setShowResetPassword(!showResetPassword)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderLogin;
