
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { PromoCodeUsage } from "./types";
import { EmptyState } from "@/components/provider/EmptyState";
import { ScrollArea } from "@/components/ui/scroll-area";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { DownloadIcon } from "lucide-react";

interface PromoCodeDetailsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  promoCodeId: string;
}

export const PromoCodeDetailsDialog = ({
  open,
  onOpenChange,
  promoCodeId,
}: PromoCodeDetailsDialogProps) => {
  const [activeTab, setActiveTab] = useState("usage");

  console.log("PromoCodeDetailsDialog rendering with ID:", promoCodeId);

  const { data: promoCode, isLoading: isLoadingPromoCode } = useQuery({
    queryKey: ["promo-code-details", promoCodeId],
    queryFn: async () => {
      console.log("Fetching promo code details for ID:", promoCodeId);
      try {
        const { data, error } = await supabase
          .from("promo_codes")
          .select("*")
          .eq("id", promoCodeId)
          .single();

        if (error) {
          console.error("Error fetching promo code details:", error);
          throw error;
        }

        console.log("Fetched promo code details:", data);
        return data;
      } catch (err) {
        console.error("Exception fetching promo code details:", err);
        toast.error("Failed to load promo code details");
        throw err;
      }
    },
    enabled: open && !!promoCodeId,
  });

  // Fetch successful usage counts
  const { data: successfulUsageCount } = useQuery({
    queryKey: ["promo-code-successful-count", promoCodeId],
    queryFn: async () => {
      try {
        const { count, error } = await supabase
          .from("promo_code_usages")
          .select("*", { count: 'exact', head: false })
          .eq("promo_code_id", promoCodeId)
          .not('assessments.status', 'is', null)
          .in('assessments.status', ['completed', 'prescribed']);
        
        if (error) {
          console.error("Error fetching successful usage count:", error);
          return 0;
        }
        
        return count || 0;
      } catch (err) {
        console.error("Exception fetching successful usage count:", err);
        return 0;
      }
    },
    enabled: open && !!promoCodeId,
  });

  const { data: usageData, isLoading: isLoadingUsage } = useQuery({
    queryKey: ["promo-code-usage", promoCodeId],
    queryFn: async () => {
      console.log("Fetching promo code usage for ID:", promoCodeId);
      try {
        const { data, error } = await supabase
          .from("promo_code_usages")
          .select(`
            *,
            assessments(user_id, status)
          `)
          .eq("promo_code_id", promoCodeId)
          .in("assessments.status", ["completed", "prescribed"])
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching promo code usage:", error);
          throw error;
        }

        console.log("Fetched promo code usage data:", data);
        
        const usageWithUserData = await Promise.all(
          data.map(async (usage) => {
            if (!usage.assessments?.user_id) return usage;
            
            const { data: userData, error: userError } = await supabase
              .from("profiles")
              .select("first_name, last_name, email")
              .eq("id", usage.assessments.user_id)
              .single();
            
            if (userError) {
              console.error("Error fetching user details:", userError);
              return {
                ...usage,
                user_name: "Unknown",
                user_email: "Unknown"
              };
            }
            
            return {
              ...usage,
              user_name: userData ? `${userData.first_name || ''} ${userData.last_name || ''}`.trim() || "Unknown" : "Unknown",
              user_email: userData?.email || "Unknown",
            };
          })
        );
        
        console.log("Usage data with user info:", usageWithUserData);
        return usageWithUserData as PromoCodeUsage[];
      } catch (err) {
        console.error("Exception fetching promo code usage:", err);
        toast.error("Failed to load usage data");
        throw err;
      }
    },
    enabled: open && !!promoCodeId && activeTab === "usage",
  });

  useEffect(() => {
    if (open) {
      setActiveTab("usage");
    }
  }, [open]);

  const downloadCSV = () => {
    if (!usageData) return;
    
    const headers = ["ID", "Assessment ID", "User Name", "User Email", "Used At", "Discount Amount"];
    
    const rows = usageData.map((usage) => [
      usage.id,
      usage.assessment_id,
      usage.user_name || "Unknown",
      usage.user_email || "Unknown",
      usage.created_at ? new Date(usage.created_at).toLocaleString() : "Unknown",
      usage.discount_amount ? `$${usage.discount_amount.toFixed(2)}` : "$0.00",
    ]);
    
    const csvContent = [
      headers.join(","),
      ...rows.map(row => row.join(","))
    ].join("\n");
    
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `promo-code-usage-${promoCode?.code || promoCodeId}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[700px] max-h-[90vh] overflow-hidden">
        <DialogHeader>
          <DialogTitle>Promo Code Details</DialogTitle>
          <DialogDescription>
            View detailed information about this promotional code.
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="max-h-[calc(80vh-120px)] overflow-y-auto pr-4">
          {isLoadingPromoCode ? (
            <div className="space-y-4">
              <Skeleton className="h-8 w-1/2" />
              <Skeleton className="h-20 w-full" />
              <Skeleton className="h-8 w-1/3" />
            </div>
          ) : promoCode ? (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Code</h3>
                  <p className="text-lg font-bold">{promoCode.code}</p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Status</h3>
                  <Badge variant={promoCode.is_active ? "success" : "destructive"}>
                    {promoCode.is_active ? "Active" : "Inactive"}
                  </Badge>
                </div>
              </div>

              <div>
                <h3 className="text-sm font-medium text-gray-500">Description</h3>
                <p className="text-gray-700">
                  {promoCode.description || "No description"}
                </p>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Discount</h3>
                  <p className="text-gray-700">
                    {promoCode.discount_type === "percentage"
                      ? `${promoCode.discount_amount}%`
                      : `$${promoCode.discount_amount.toFixed(2)}`}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Usage</h3>
                  <p className="text-gray-700">
                    {successfulUsageCount} successful / {promoCode.max_uses === null ? "∞" : promoCode.max_uses} max
                    <span className="block text-xs text-gray-500">
                      {promoCode.usage_count} total attempts
                    </span>
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Created</h3>
                  <p className="text-gray-700">
                    {format(new Date(promoCode.created_at), "PPP")}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Expires</h3>
                  <p className="text-gray-700">
                    {promoCode.expiration_date
                      ? format(new Date(promoCode.expiration_date), "PPP")
                      : "No expiration"}
                  </p>
                </div>
              </div>

              <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="grid w-full grid-cols-1">
                  <TabsTrigger value="usage">Usage History</TabsTrigger>
                </TabsList>
                <TabsContent value="usage" className="pt-4">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Successful Usage History</h2>
                    {usageData && usageData.length > 0 && (
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={downloadCSV}
                      >
                        <DownloadIcon className="h-4 w-4 mr-2" />
                        Export CSV
                      </Button>
                    )}
                  </div>

                  {isLoadingUsage ? (
                    <div className="space-y-2">
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-10 w-full" />
                    </div>
                  ) : usageData && usageData.length > 0 ? (
                    <div className="border rounded-md overflow-hidden">
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>User</TableHead>
                            <TableHead>Date Used</TableHead>
                            <TableHead>Discount</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {usageData.map((usage) => (
                            <TableRow key={usage.id}>
                              <TableCell>
                                <div>
                                  <p className="font-medium">{usage.user_name || "Unknown"}</p>
                                  <p className="text-xs text-gray-500">{usage.user_email || "Unknown email"}</p>
                                </div>
                              </TableCell>
                              <TableCell>
                                {usage.created_at 
                                  ? format(new Date(usage.created_at), "PPP p") 
                                  : "Unknown date"}
                              </TableCell>
                              <TableCell>
                                ${usage.discount_amount?.toFixed(2) || "0.00"}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    <EmptyState
                      title="No Successful Usage History"
                      description="This promo code hasn't been successfully used in completed orders yet."
                    />
                  )}
                </TabsContent>
              </Tabs>
            </div>
          ) : (
            <div className="p-4 text-center text-red-500">
              Failed to load promo code details.
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
