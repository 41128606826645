import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { startOfDay, startOfWeek, startOfMonth, startOfQuarter, startOfYear, subWeeks, isSameDay } from "date-fns";
import { DashboardMetrics, TimeFilter } from "./types";

const PAGE_SIZE = 1000;

const fetchAllAssessments = async (timeRange: Date) => {
  let allAssessments = [];
  let currentPage = 0;
  let hasMore = true;

  while (hasMore) {
    const from = currentPage * PAGE_SIZE;
    const to = from + PAGE_SIZE - 1;

    const { data, error, count } = await supabase
      .from('assessments')
      .select('status, started_at, completed_at, prescribed_at, is_renewal', { count: 'exact' })
      .eq('is_renewal', false)
      .gte('started_at', timeRange.toISOString())
      .range(from, to);

    if (error) throw error;

    if (data) {
      allAssessments = [...allAssessments, ...data];
    }

    hasMore = count ? (from + PAGE_SIZE) < count : false;
    currentPage++;
  }

  return allAssessments;
};

const fetchRevenueForPeriod = async (startDate: Date) => {
  let allRevenue = [];
  let currentPage = 0;
  let hasMore = true;

  while (hasMore) {
    const from = currentPage * PAGE_SIZE;
    const to = from + PAGE_SIZE - 1;

    const { data, error, count } = await supabase
      .from('assessments')
      .select('amount, is_renewal')
      .gte('completed_at', startDate.toISOString())
      .in('status', ['completed', 'prescribed'])
      .range(from, to);

    if (error) throw error;

    if (data) {
      allRevenue = [...allRevenue, ...data];
    }

    hasMore = count ? (from + PAGE_SIZE) < count : false;
    currentPage++;
  }

  return allRevenue
    ?.filter(a => !a.is_renewal)
    ?.reduce((sum, assessment) => sum + Number(assessment.amount), 0) || 0;
};

export const useMetrics = (timeFilter: TimeFilter) => {
  const getTimeRange = () => {
    const now = new Date();
    switch (timeFilter) {
      case 'day':
        return startOfDay(now);
      case 'week':
        return startOfWeek(now, { weekStartsOn: 1 }); // Monday
      case 'lastWeek':
        return startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }); // Last Monday
      case 'month':
        return startOfMonth(now);
      case 'quarter':
        return startOfQuarter(now);
      case 'year':
        return startOfYear(now);
      default:
        return startOfDay(now);
    }
  };

  return useQuery({
    queryKey: ["dashboard-metrics", timeFilter],
    queryFn: async () => {
      const timeRange = getTimeRange();
      console.log('Fetching metrics for time range:', timeRange);

      // Always get weekly data for the fixed weekly section
      const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
      const weeklyData = await fetchAllAssessments(weekStart);
      const weeklyAssessmentCount = weeklyData?.length || 0;

      // Get filtered data for the selected time period
      const statusData = await fetchAllAssessments(timeRange);

      // Calculate status counts
      const statusCounts = {
        completed: 0,
        prescribed: 0,
        draft: 0
      };

      // Calculate same-day completion metrics
      let sameDayCompletions = 0;
      let totalAssessments = 0;

      statusData?.forEach(assessment => {
        totalAssessments++;

        if (assessment.status === 'completed') statusCounts.completed++;
        else if (assessment.status === 'prescribed') statusCounts.prescribed++;
        else statusCounts.draft++;

        const startDate = new Date(assessment.started_at);
        const completionDate = assessment.completed_at 
          ? new Date(assessment.completed_at)
          : assessment.prescribed_at 
            ? new Date(assessment.prescribed_at)
            : null;

        if (completionDate && isSameDay(startDate, completionDate)) {
          sameDayCompletions++;
        }
      });

      // Get weekly conversion data
      const weeklyDrafts = weeklyData?.filter(a => new Date(a.started_at) >= weekStart) || [];
      const weeklyConversions = weeklyData?.filter(a => 
        new Date(a.started_at) >= weekStart && 
        ['completed', 'prescribed'].includes(a.status)
      ) || [];

      const weeklyConversionRate = weeklyDrafts.length > 0 
        ? (weeklyConversions.length / weeklyDrafts.length) * 100 
        : 0;

      // Get monthly conversion data
      const monthStart = startOfMonth(new Date());
      const monthData = await fetchAllAssessments(monthStart);

      const monthlyDrafts = monthData?.filter(a => new Date(a.started_at) >= monthStart) || [];
      const monthlyConversions = monthData?.filter(a => 
        new Date(a.started_at) >= monthStart && 
        ['completed', 'prescribed'].includes(a.status)
      ) || [];

      const monthlyConversionRate = monthlyDrafts.length > 0 
        ? (monthlyConversions.length / monthlyDrafts.length) * 100 
        : 0;

      // Get revenue metrics
      const today = startOfDay(new Date());
      const todayTotalRevenue = await fetchRevenueForPeriod(today);
      const weeklyRevenue = await fetchRevenueForPeriod(weekStart);
      const monthTotalRevenue = await fetchRevenueForPeriod(monthStart);
      const filteredRevenue = await fetchRevenueForPeriod(timeRange);

      // Get unread messages count
      const { count: unreadCount, error: unreadError } = await supabase
        .from('messages')
        .select('*', { count: 'exact' })
        .is('read_at', null)
        .eq('is_provider', false);

      if (unreadError) throw unreadError;

      return {
        statusCounts: Object.entries(statusCounts).map(([status, count]) => ({
          status: status.charAt(0).toUpperCase() + status.slice(1),
          count
        })),
        todayCount: statusData?.length || 0,
        unreadMessages: unreadCount || 0,
        todayRevenue: todayTotalRevenue,
        weeklyRevenue: weeklyRevenue,
        monthRevenue: monthTotalRevenue,
        filteredRevenue: filteredRevenue,
        weeklyAssessmentCount,
        completionMetrics: {
          averageCompletionTime: 0,
          sameDay: sameDayCompletions,
          total: totalAssessments,
          weeklyConversionRate,
          monthlyConversionRate
        }
      };
    }
  });
};
