
import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { supabase } from "@/integrations/supabase/client"
import { useToast } from "@/hooks/use-toast"
import { useAuth } from "@/hooks/useAuth"

export const useMessageSend = (assessmentId: string) => {
  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useToast()
  const queryClient = useQueryClient()
  const { user } = useAuth()

  const sendMessage = async (content: string) => {
    setIsLoading(true)
    try {
      if (!user) throw new Error("No user found")

      const isProvider = user.user_metadata?.is_provider || false

      // First, get the patient's email if this is a provider sending a message
      let patientEmail = null
      if (isProvider) {
        const { data: assessment } = await supabase
          .from("assessments")
          .select(`
            profiles (
              email
            )
          `)
          .eq("id", assessmentId)
          .single()

        patientEmail = assessment?.profiles?.email
      }

      const { error } = await supabase.from("messages").insert({
        assessment_id: assessmentId,
        sender_id: user.id,
        content,
        is_provider: isProvider,
      })

      if (error) throw error

      // If this is a provider message, send an email notification
      if (isProvider && patientEmail) {
        const { error: emailError } = await supabase.functions.invoke("send-message-email", {
          body: {
            to: patientEmail,
            message: content,
            replyTo: "support@mybellehealth.com"
          },
        })

        if (emailError) {
          console.error("Error sending email notification:", emailError)
          toast({
            title: "Warning",
            description: "Message sent but email notification failed",
            variant: "destructive",
          })
        }
      }

      queryClient.invalidateQueries({ queryKey: ["messages", assessmentId] })
    } catch (error) {
      console.error("Error sending message:", error)
      toast({
        title: "Error sending message",
        description: "Please try again",
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    sendMessage,
    isLoading
  }
}
