
import { CheckCircle2, Star } from "lucide-react";

export const Welcome = () => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-center gap-2 text-primary">
        <CheckCircle2 className="h-5 w-5" />
        <h3 className="text-lg font-semibold">Final Step - Complete Your Order</h3>
      </div>
      
      <div className="bg-gradient-to-r from-primary/5 to-primary/10 rounded-lg p-4 space-y-3">
        <div className="flex items-center justify-center gap-3 text-primary">
          {/* Stacked images effect */}
          <div className="relative flex items-center">
            {/* First patient image (bottom of stack) */}
            <div className="relative h-12 w-12 rounded-full overflow-hidden border-2 border-white shadow-md z-10 -mr-3">
              <img 
                src="/lovable-uploads/c9573a86-a204-4079-823a-23fc22aab767.png" 
                alt="Satisfied patient 1" 
                className="object-cover w-full h-full"
              />
            </div>
            
            {/* Second patient image (middle of stack) */}
            <div className="relative h-12 w-12 rounded-full overflow-hidden border-2 border-white shadow-md z-20 -mr-3">
              <img 
                src="/lovable-uploads/bdab4e5c-c169-4099-ae63-b13919db12c5.png" 
                alt="Satisfied patient 2" 
                className="object-cover w-full h-full"
              />
            </div>
            
            {/* Third patient image (top of stack) */}
            <div className="relative h-12 w-12 rounded-full overflow-hidden border-2 border-white shadow-md z-30">
              <img 
                src="/lovable-uploads/0eea8f44-c308-4c9f-b556-ac288b8b5293.png" 
                alt="Satisfied patient 3" 
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          <span className="font-medium">Join thousands of satisfied patients</span>
        </div>
        
        <div className="flex items-center justify-center gap-1">
          <div className="flex items-center">
            {[1, 2, 3, 4].map((_, i) => (
              <Star key={i} className="w-4 h-4 fill-current text-amber-400" />
            ))}
            <Star className="w-4 h-4 fill-current text-amber-400 opacity-50" />
          </div>
          <span className="text-sm font-medium">4.8</span>
          <span className="text-xs text-muted-foreground">(10,000+ reviews)</span>
        </div>
      </div>
      
      <p className="text-sm text-muted-foreground">
        Need help? Our care team is available 24/7.<br />
        Call or text <a href="tel:888-817-8857" className="text-primary font-medium hover:underline">888-817-8857</a>
      </p>
    </div>
  );
};
