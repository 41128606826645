
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { formatCompletionTime } from "../utils/formatTime";
import { DashboardMetrics, TimeFilter } from "./types";

interface MetricsDisplayProps {
  metrics: DashboardMetrics | undefined;
  isLoading: boolean;
  timeFilter: TimeFilter;
  onTimeFilterChange: (value: TimeFilter) => void;
}

export const MetricsDisplay = ({ 
  metrics, 
  isLoading, 
  timeFilter,
  onTimeFilterChange 
}: MetricsDisplayProps) => {
  if (isLoading) return null;

  const completionRate = metrics?.completionMetrics.total > 0
    ? ((metrics.completionMetrics.sameDay / metrics.completionMetrics.total) * 100).toFixed(1)
    : '0';

  return (
    <div className="space-y-8">
      {/* Fixed Metrics Section */}
      <div className="grid gap-4 md:grid-cols-3">
        <Card className="bg-[#F1F0FB]">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Weekly Assessments
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{metrics?.weeklyAssessmentCount || 0}</div>
          </CardContent>
        </Card>
        <Card className="bg-[#F1F0FB]">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Weekly Revenue
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              ${(metrics?.weeklyRevenue || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
          </CardContent>
        </Card>
        <Card className="bg-[#F1F0FB]">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Monthly Revenue
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              ${(metrics?.monthRevenue || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Filtered Metrics Section */}
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Filtered Metrics</h3>
          <Select value={timeFilter} onValueChange={onTimeFilterChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select time period" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="day">Today</SelectItem>
              <SelectItem value="week">This Week</SelectItem>
              <SelectItem value="lastWeek">Last Week</SelectItem>
              <SelectItem value="month">This Month</SelectItem>
              <SelectItem value="quarter">This Quarter</SelectItem>
              <SelectItem value="year">This Year</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-4 md:grid-cols-3">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {timeFilter === 'day' ? "Today's" : 
                 timeFilter === 'week' ? "This Week's" :
                 timeFilter === 'lastWeek' ? "Last Week's" :
                 timeFilter === 'month' ? "This Month's" :
                 timeFilter === 'quarter' ? "This Quarter's" :
                 "This Year's"} Assessments
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{metrics?.todayCount || 0}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {timeFilter === 'day' ? "Today's" : 
                 timeFilter === 'week' ? "This Week's" :
                 timeFilter === 'lastWeek' ? "Last Week's" :
                 timeFilter === 'month' ? "This Month's" :
                 timeFilter === 'quarter' ? "This Quarter's" :
                 "This Year's"} Revenue
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                ${(metrics?.filteredRevenue || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Completion Rate
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{completionRate}%</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Weekly Conversion Rate
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {(metrics?.completionMetrics.weeklyConversionRate || 0).toFixed(1)}%
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Monthly Conversion Rate
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {(metrics?.completionMetrics.monthlyConversionRate || 0).toFixed(1)}%
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Unread Messages
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{metrics?.unreadMessages || 0}</div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
