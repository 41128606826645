
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Toaster } from "@/components/ui/toaster"
import { Toaster as SonnerToaster } from "@/components/ui/sonner"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Index from "@/pages/Index"
import Dashboard from "@/pages/Dashboard"
import ProviderLogin from "@/pages/ProviderLogin"
import ProviderDashboard from "@/components/ProviderDashboard"
import { ProviderAuthGuard } from "@/components/provider/ProviderAuthGuard"
import { ProviderSidebar } from "@/components/provider/ProviderSidebar"
import { DashboardContent } from "@/components/provider/dashboard/DashboardContent"
import { StatusTabs } from "@/components/provider/StatusTabs"
import { SidebarProvider } from "@/components/ui/sidebar"
import ProviderMessages from "@/components/provider/messages/ProviderMessages"
import { AddProviderPage } from "@/components/provider/AddProviderPage"
import { PatientsPage } from "@/components/provider/patients/PatientsPage"
import { UsersPage } from "@/components/provider/users/UsersPage"
import { PricingManagementPage } from "@/components/provider/pricing/PricingManagementPage"
import ResetPassword from "@/components/ResetPassword"
import UnsubscribeConfirmation from "@/pages/UnsubscribeConfirmation"
import { PromoCodesPage } from "@/components/provider/promo-codes/PromoCodesPage"
import { BannerManagementPage } from "@/components/provider/banner/BannerManagementPage"
import { SalesBanner } from "@/components/SalesBanner"
import { useEffect } from "react"
import { supabase } from "@/integrations/supabase/client"
import { toast } from "sonner"

// Create a client with default options to prevent excessive refetching
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      retry: 1,
    },
  },
})

function App() {
  // Check if the sales_banner table exists and ensure realtime is enabled on app start
  useEffect(() => {
    const enableRealtimeForBanner = async () => {
      try {
        // This will let us test if the table exists and if we can access it
        const { error: testError } = await supabase
          .from('sales_banner')
          .select('id')
          .limit(1);
          
        if (testError) {
          console.error("Error connecting to sales_banner table:", testError);
          return;
        }
        
        console.log("Successfully connected to sales_banner table");
        
        // Enable realtime subscription for sales_banner table
        const channel = supabase
          .channel('sales-banner-verification')
          .on('postgres_changes', 
            { event: '*', schema: 'public', table: 'sales_banner' },
            (payload) => console.log('Banner change detected:', payload)
          )
          .subscribe((status) => {
            console.log('Realtime subscription status:', status);
            if (status === 'SUBSCRIBED') {
              console.log('Successfully verified realtime for banner');
            }
          });
          
        // Clean up subscription after verification
        setTimeout(() => {
          supabase.removeChannel(channel);
        }, 5000);
      } catch (err) {
        console.error("Unexpected error connecting to sales_banner table:", err);
      }
    };
    
    enableRealtimeForBanner();
  }, []);
  
  // Use effect to watch for changes in the DOM structure
  // related to the banner and adjust scroll positions if needed
  useEffect(() => {
    // Function to fix scroll position for step transitions
    const fixScrollPosition = () => {
      const stepElement = document.querySelector('.space-y-6');
      if (stepElement && document.body.classList.contains('has-banner')) {
        stepElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    // Watch for changes in step content
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && 
            mutation.target.nodeType === Node.ELEMENT_NODE &&
            (mutation.target as Element).classList.contains('space-y-6')) {
          fixScrollPosition();
        }
      }
    });
    
    // Observe the entire document for changes
    observer.observe(document.body, { childList: true, subtree: true });
    
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SalesBanner />
        <div className="app-container">
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/provider/login" element={<ProviderLogin />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/unsubscribe-confirmation" element={<UnsubscribeConfirmation />} />
            <Route
              path="/provider/*"
              element={
                <ProviderAuthGuard>
                  <SidebarProvider>
                    <div className="min-h-screen flex w-full">
                      <ProviderSidebar>
                        <Routes>
                          <Route index element={<ProviderDashboard />} />
                          <Route
                            path="assessments"
                            element={
                              <div className="container mx-auto py-10">
                                <StatusTabs />
                              </div>
                            }
                          />
                          <Route path="messages" element={<ProviderMessages />} />
                          <Route path="patients" element={<PatientsPage />} />
                          <Route path="users" element={<UsersPage />} />
                          <Route path="add-provider" element={<AddProviderPage />} />
                          <Route path="pricing" element={<PricingManagementPage />} />
                          <Route path="promo-codes" element={<PromoCodesPage />} />
                          <Route path="banner" element={<BannerManagementPage />} />
                        </Routes>
                      </ProviderSidebar>
                    </div>
                  </SidebarProvider>
                </ProviderAuthGuard>
              }
            />
          </Routes>
          <Toaster />
          <SonnerToaster />
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
