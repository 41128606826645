
import { useState } from "react";
import { ProviderPermissionManager } from "../ProviderPermissionManager";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { MetricsDisplay } from "./metrics/MetricsDisplay";
import { useMetrics } from "./metrics/useMetrics";
import { ConversionTrackingCard } from "./metrics/ConversionTrackingCard";
import { RenewalMetricsCard } from "./metrics/RenewalMetricsCard";
import { DateRangeAnalytics } from "./metrics/DateRangeAnalytics";
import { TimeFilter } from "./metrics/types";
import { startOfWeek, startOfMonth, endOfWeek, endOfMonth } from "date-fns";

export const DashboardContent = () => {
  const [uuid, setUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>("week");
  
  const { data: metrics, isLoading: metricsLoading } = useMetrics(timeFilter);
  
  const handleMakeProvider = async () => {
    if (!uuid || uuid.length < 36) {
      toast.error("Please enter a valid UUID");
      return;
    }
    
    setIsLoading(true);
    
    try {
      const { data, error } = await supabase.functions.invoke("make-provider-by-uuid", {
        body: { user_id: uuid }
      });
      
      if (error) throw error;
      
      toast.success("User successfully converted to provider with full access");
      setUuid("");
    } catch (error) {
      console.error("Error making user a provider:", error);
      toast.error("Failed to make user a provider. Please check the UUID and try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Date ranges for renewal metrics
  const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const endOfCurrentWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
  const startOfCurrentMonth = startOfMonth(new Date());
  const endOfCurrentMonth = endOfMonth(new Date());
  
  return (
    <div className="space-y-6">
      {/* Metrics Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-6">Dashboard</h2>
        <MetricsDisplay 
          metrics={metrics}
          isLoading={metricsLoading}
          timeFilter={timeFilter}
          onTimeFilterChange={setTimeFilter}
        />
      </div>
      
      {/* Additional Analytics */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <ConversionTrackingCard />
        <RenewalMetricsCard 
          startDate={startOfCurrentMonth} 
          endDate={endOfCurrentMonth} 
        />
      </div>
      
      <DateRangeAnalytics />
      
      {/* Admin Section */}
      <div className="mt-10">
        <h2 className="text-2xl font-bold mb-6">Administration</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ProviderPermissionManager />
          
          <Card>
            <CardHeader>
              <CardTitle>Make User a Provider</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-muted-foreground mb-4">
                    Enter a user UUID to convert them to a provider with full permissions.
                  </p>
                  <div className="flex gap-2">
                    <Input 
                      placeholder="Enter UUID" 
                      value={uuid} 
                      onChange={(e) => setUuid(e.target.value)}
                    />
                    <Button 
                      onClick={handleMakeProvider} 
                      disabled={isLoading || !uuid}
                    >
                      {isLoading ? "Processing..." : "Make Provider"}
                    </Button>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
