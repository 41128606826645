
import { CreditCard, Tag, X } from "lucide-react";
import { formatCurrency } from "@/utils/formatCurrency";
import { useIsMobile } from "@/hooks/use-mobile";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface OrderSummaryProps {
  medication: string;
  planType: string;
  doseType: string;
  amount: number;
  savingsAmount?: number;
  originalAmount?: number;
  offerEndDate?: string;
  subscriptionId: string;
  onPriceUpdate?: (newAmount: number, promoCode: string | null) => void;
}

// Define the allowed discount types explicitly
type DiscountType = "percentage" | "fixed";

export const OrderSummary = ({
  medication,
  planType,
  doseType,
  amount,
  savingsAmount,
  originalAmount,
  offerEndDate,
  subscriptionId,
  onPriceUpdate,
}: OrderSummaryProps) => {
  const isMobile = useIsMobile();
  const { toast } = useToast();
  const [promoCode, setPromoCode] = useState("");
  const [appliedPromoCode, setAppliedPromoCode] = useState<{
    code: string;
    discount: number;
    discountType: DiscountType;
  } | null>(null);
  const [isApplying, setIsApplying] = useState(false);
  
  // Calculate monthly cost (assuming standard plans are 3-month)
  const monthCount = planType.toLowerCase().includes("month") 
    ? parseInt(planType.match(/\d+/)?.[0] || "3") 
    : 3;
  
  // Calculate discounted amount if promo code is applied
  const discountedAmount = appliedPromoCode
    ? appliedPromoCode.discountType === "percentage"
      ? amount * (1 - appliedPromoCode.discount / 100)
      : amount - appliedPromoCode.discount
    : amount;
  
  const finalAmount = Math.max(discountedAmount, 0);
  const monthlyPrice = finalAmount / monthCount;
  
  // Calculate promo code savings
  const promoSavings = amount - finalAmount;

  const handleApplyPromoCode = async () => {
    if (!promoCode.trim()) return;
    
    setIsApplying(true);
    
    try {
      // Clean the promo code (trim whitespace and convert to uppercase)
      const cleanedPromoCode = promoCode.trim().toUpperCase();
      console.log("Applying promo code:", cleanedPromoCode);
      
      // Fetch promo code from the database
      const { data, error } = await supabase
        .from("promo_codes")
        .select("*")
        .ilike("code", cleanedPromoCode) // Using case-insensitive comparison
        .eq("is_active", true);
        
      if (error) {
        console.error("Error fetching promo code:", error);
        toast({
          title: "Error",
          description: "Failed to validate promo code. Please try again.",
          variant: "destructive",
        });
        return;
      }
      
      // Check if any valid promo code was found
      if (!data || data.length === 0) {
        console.error("No promo code found with code:", cleanedPromoCode);
        toast({
          title: "Invalid Promo Code",
          description: "This promo code does not exist or has expired.",
          variant: "destructive",
        });
        return;
      }
      
      console.log("Found promo code data:", data);
      const promoData = data[0];
      
      // Check if the promo code has reached its usage limit
      if (promoData.max_uses !== null && promoData.usage_count >= promoData.max_uses) {
        toast({
          title: "Promo Code Expired",
          description: "This promo code has reached its maximum usage limit.",
          variant: "destructive",
        });
        return;
      }
      
      // Check if the promo code has expired
      if (promoData.expiration_date && new Date(promoData.expiration_date) < new Date()) {
        toast({
          title: "Promo Code Expired",
          description: "This promo code has expired.",
          variant: "destructive",
        });
        return;
      }
      
      // Ensure discount_type is either "percentage" or "fixed" to satisfy TypeScript
      const discountType: DiscountType = promoData.discount_type === "percentage" ? "percentage" : "fixed";
      
      // Record promo code usage
      await supabase.from("promo_code_usages").insert({
        promo_code_id: promoData.id,
        assessment_id: subscriptionId,
        discount_amount: discountType === "percentage" 
          ? (amount * promoData.discount_amount / 100) 
          : promoData.discount_amount
      });
      
      // Set the applied promo code
      setAppliedPromoCode({
        code: promoData.code,
        discount: promoData.discount_amount,
        discountType: discountType,
      });
      
      // Calculate the new amount with the discount applied
      const newDiscountedAmount = discountType === "percentage" 
        ? amount * (1 - promoData.discount_amount / 100)
        : amount - promoData.discount_amount;
      
      const newFinalAmount = Math.max(Math.round(newDiscountedAmount), 0);
      
      // Update the amount in the parent component
      if (onPriceUpdate) {
        onPriceUpdate(newFinalAmount, promoData.code);
      }
      
      toast({
        title: "Promo Code Applied",
        description: `You saved ${discountType === "percentage" 
          ? `${promoData.discount_amount}%` 
          : formatCurrency(promoData.discount_amount)} with code ${promoData.code}`,
      });
      
      // Clear the input
      setPromoCode("");
    } catch (err) {
      console.error("Error applying promo code:", err);
      toast({
        title: "Error",
        description: "There was an error applying the promo code. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsApplying(false);
    }
  };
  
  const handleRemovePromoCode = () => {
    setAppliedPromoCode(null);
    
    // Update the amount in the parent component
    if (onPriceUpdate) {
      onPriceUpdate(amount, null);
    }
    
    toast({
      title: "Promo Code Removed",
      description: "The promo code has been removed.",
    });
  };

  return (
    <div className="space-y-3 mb-4">
      <div className={`bg-gradient-to-r from-muted/30 to-muted/50 rounded-lg ${isMobile ? 'p-3' : 'p-4'} ${isMobile ? 'space-y-3' : 'space-y-4'}`}>
        <h3 className="font-medium text-lg">Order Summary</h3>
        
        <div className="grid grid-cols-2 gap-y-2 text-sm">
          <span className="text-muted-foreground">Medication</span>
          <span className="font-medium text-right">{medication}</span>
          
          <span className="text-muted-foreground">Plan</span>
          <span className="font-medium text-right">{planType}</span>
          
          <span className="text-muted-foreground">Dose Type</span>
          <span className="font-medium text-right">{doseType} dose</span>
          
          <span className="text-muted-foreground">Billing</span>
          <span className="font-medium text-right">One-time payment</span>
        </div>
        
        {/* Promo code input */}
        <div className="border-t pt-3 mt-1">
          <div className="space-y-2">
            <p className="text-sm font-medium">Have a promo code?</p>
            <div className="flex items-center gap-2">
              <Input
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Enter code"
                className="flex-grow"
                disabled={!!appliedPromoCode || isApplying}
              />
              {appliedPromoCode ? (
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={handleRemovePromoCode} 
                  className="whitespace-nowrap"
                >
                  <X className="h-4 w-4 mr-1" />
                  Remove
                </Button>
              ) : (
                <Button 
                  onClick={handleApplyPromoCode} 
                  variant="outline" 
                  size="sm" 
                  disabled={!promoCode.trim() || isApplying}
                  className="whitespace-nowrap"
                >
                  Apply
                </Button>
              )}
            </div>
            
            {appliedPromoCode && (
              <div className="bg-green-50 border border-green-100 rounded-md p-2 text-green-700 text-xs">
                <p className="font-medium">
                  Code <span className="uppercase">{appliedPromoCode.code}</span> applied!
                  {appliedPromoCode.discountType === "percentage" 
                    ? ` (${appliedPromoCode.discount}% off)`
                    : ` (${formatCurrency(appliedPromoCode.discount)} off)`}
                </p>
              </div>
            )}
          </div>
        </div>
        
        <div className="border-t pt-3 mt-1">
          {/* Prominent monthly price display */}
          <div className="flex flex-col space-y-1 mb-3">
            <div className="flex items-center gap-1">
              <CreditCard className="w-4 h-4 text-primary" />
              <span className="text-sm font-medium text-primary">Monthly Payment</span>
            </div>
            <div className="flex justify-between items-baseline">
              <span className="text-3xl font-bold text-primary">${monthlyPrice.toFixed(2)}</span>
              <span className="text-sm text-muted-foreground">/month</span>
            </div>
          </div>
          
          {/* De-emphasized total price */}
          <div className="space-y-1">
            {(appliedPromoCode || savingsAmount) && (
              <div className="flex justify-between items-baseline text-xs">
                <span className="text-muted-foreground">Original price</span>
                <span className="font-medium line-through text-muted-foreground">${amount.toFixed(2)}</span>
              </div>
            )}
            
            {appliedPromoCode && (
              <div className="flex justify-between items-baseline text-xs">
                <span className="text-green-600">Promo discount</span>
                <span className="font-medium text-green-600">-${promoSavings.toFixed(2)}</span>
              </div>
            )}
            
            <div className="flex justify-between items-baseline text-xs">
              <span className="text-muted-foreground">Total one-time charge</span>
              <span className="font-medium">${finalAmount.toFixed(2)}</span>
            </div>
          </div>
          
          {savingsAmount && savingsAmount > 0 && !appliedPromoCode && (
            <div className="mt-3 p-2 bg-gradient-to-r from-orange-50 to-amber-50 border border-orange-100 rounded-md">
              <div className="flex items-center justify-center gap-1 text-orange-600">
                <Tag className="h-3 w-3" />
                <p className="text-sm font-medium">
                  Save ${savingsAmount.toFixed(2)} with today's offer
                </p>
              </div>
              {offerEndDate && (
                <p className="text-xs text-orange-500 text-center mt-0.5">
                  Limited time offer - ends {offerEndDate}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
