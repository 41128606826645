
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Card, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { format } from "date-fns";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";

interface CompletedAssessmentModalProps {
  assessment: any;
  open: boolean;
  onClose: () => void;
}

export const CompletedAssessmentModal = ({
  assessment,
  open,
  onClose,
}: CompletedAssessmentModalProps) => {
  if (!assessment) return null;

  const capitalizedMedication = assessment?.medication?.charAt(0).toUpperCase() + assessment?.medication?.slice(1);
  const formattedUpdatedAt = assessment.updated_at ? format(new Date(assessment.updated_at), "PPP 'at' p") : null;
  
  // Calculate the final price after discount (if any)
  const finalPrice = assessment.discounted_amount 
    ? assessment.discounted_amount 
    : assessment.amount;

  const AssessmentContent = ({ assessment }: { assessment: any }) => (
    <div className="space-y-6">
      {assessment.status === 'denied' && assessment.denial_reason && (
        <Alert variant="destructive">
          <AlertDescription>
            {assessment.denial_reason}
          </AlertDescription>
        </Alert>
      )}

      {/* Contact Information */}
      <Card>
        <CardContent className="pt-6 space-y-4">
          <h3 className="font-semibold text-lg">Contact Information</h3>
          <div className="grid grid-cols-2 gap-2">
            <span className="text-muted-foreground">Email:</span>
            <span>{assessment.profiles?.email || 'Not provided'}</span>
            
            <span className="text-muted-foreground">Phone:</span>
            <span>{assessment.cell_phone || 'Not provided'}</span>
          </div>
        </CardContent>
      </Card>

      {/* Order Summary */}
      <Card>
        <CardContent className="pt-6 space-y-4">
          <h3 className="font-semibold text-lg">Order Summary</h3>
          <div className="grid grid-cols-2 gap-2">
            <span className="text-muted-foreground">Date:</span>
            <span>{format(new Date(assessment.created_at), "PPP")}</span>
            
            <span className="text-muted-foreground">Medication:</span>
            <span>{capitalizedMedication}</span>
            
            <span className="text-muted-foreground">Plan:</span>
            <span>{assessment.plan_type}</span>
            
            <span className="text-muted-foreground">Amount:</span>
            <div>
              {assessment.applied_promo_code ? (
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <span>${finalPrice}</span>
                    <Badge variant="secondary" className="text-xs">
                      Promo: {assessment.applied_promo_code}
                    </Badge>
                  </div>
                  {assessment.amount !== finalPrice && (
                    <span className="text-muted-foreground line-through text-xs">${assessment.amount}</span>
                  )}
                </div>
              ) : (
                <span>${assessment.amount}</span>
              )}
            </div>
            
            <span className="text-muted-foreground">Status:</span>
            <div className="flex items-center gap-2">
              <span className="capitalize">{assessment.status}</span>
              {assessment.is_renewal && (
                <Badge variant="secondary">
                  Renewal #{assessment.renewal_count || 0}
                </Badge>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Medical Information */}
      <Card>
        <CardContent className="pt-6 space-y-4">
          <h3 className="font-semibold text-lg">Medical Information</h3>
          <div className="grid grid-cols-2 gap-2">
            <span className="text-muted-foreground">Height:</span>
            <span>
              {Math.floor(assessment.patient_height / 12)}'{assessment.patient_height % 12}"
            </span>
            
            <span className="text-muted-foreground">Weight:</span>
            <span>{assessment.patient_weight} lbs</span>
            
            <span className="text-muted-foreground">Medical Conditions:</span>
            <div>
              {assessment.medical_conditions?.length > 0 ? (
                <ul className="list-disc list-inside">
                  {assessment.medical_conditions.map((condition: string) => (
                    <li key={condition}>{condition}</li>
                  ))}
                </ul>
              ) : (
                <span>None reported</span>
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Shipping Information */}
      <Card>
        <CardContent className="pt-6 space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-lg">Shipping Information</h3>
            {formattedUpdatedAt && (
              <span className="text-sm text-muted-foreground">
                Last updated: {formattedUpdatedAt}
              </span>
            )}
          </div>
          <div className="grid grid-cols-2 gap-2">
            <span className="text-muted-foreground">Address:</span>
            <span>{assessment.shipping_address}</span>
            
            <span className="text-muted-foreground">City:</span>
            <span>{assessment.shipping_city}</span>
            
            <span className="text-muted-foreground">State:</span>
            <span>{assessment.shipping_state}</span>
            
            <span className="text-muted-foreground">ZIP Code:</span>
            <span>{assessment.shipping_zip}</span>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Assessment Details</DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh]">
          <div className="p-2">
            <AssessmentContent assessment={assessment} />
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
