import { Card } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { MapPin, Star } from "lucide-react";

interface Testimonial {
  name: string;
  location: string;
  quote: string;
  description: string;
  medication: string;
  imagePath: string;
}

const testimonials: Testimonial[] = [
  {
    name: "Beth Cooley",
    location: "Oklahoma City, Oklahoma",
    quote: "Very impressed with BelleHealth and Tirzepatide!",
    description: "I was very impressed with them and the medication. I also loved that I could text them and they would respond that sameday.",
    medication: "Tirzepatide",
    imagePath: "/lovable-uploads/8d51f5a7-3159-49ac-96a5-6a2ba2b47fc5.png"
  },
  {
    name: "Brittany Vergara",
    location: "Oklahoma City, Oklahoma",
    quote: "I lost 15 pounds in 2 months!",
    description: "I just finished my 2nd month of Semiglutide and have lost 15 lbs! Staff is attentive and helpful in answering all my questions.",
    medication: "Semaglutide",
    imagePath: "/lovable-uploads/d5e75ff0-b23a-48e3-9374-e90c3b1b131b.png"
  },
  {
    name: "Ariana J.",
    location: "Austin, Texas",
    quote: "I feel confident again",
    description: "I lost 35 lbs and I feel so good! My wedding ring fits better, I like going shopping again, I feel confident.",
    medication: "Tirzepatide",
    imagePath: "/lovable-uploads/564d6bc4-91e8-4609-952a-8f43f5abd419.png"
  },
  {
    name: "Selina Little",
    location: "Bountiful, Utah",
    quote: "Nothing but amazing experiences with BelleHealth!",
    description: "The whole team is amazing. I was on their semaglutide program and I cant say enough good things. I was SO SICK of dealing with companies I couldn't talk to. I'm so happy with my results!",
    medication: "Semaglutide",
    imagePath: "/lovable-uploads/45c4cb44-74f8-482a-b45f-3e721a8bc9e9.png"
  },
  {
    name: "Mariah Hopkins",
    location: "Draper, Utah",
    quote: "Before Belle, I couldn't lose a single pound. Now I'm down 40 lbs!",
    description: "It really works! IT. REALLY. WORKS. BelleHealth is amazing!",
    medication: "Semaglutide",
    imagePath: "/lovable-uploads/86f67384-475a-4dcb-b67d-71e4b67dec96.png"
  }
];

export const TestimonialCarousel = () => {
  return (
    <Carousel className="w-full max-w-lg mx-auto" opts={{ loop: true }}>
      <CarouselContent>
        {testimonials.map((testimonial, index) => (
          <CarouselItem key={index}>
            <Card className="p-6 rounded-xl">
              <div className="flex flex-col items-center space-y-4">
                <img 
                  src={testimonial.imagePath} 
                  alt={testimonial.name}
                  className="w-24 h-24 rounded-full object-cover"
                />
                <h3 className="font-semibold text-lg">{testimonial.name}</h3>
                <p className="text-muted-foreground text-sm">{testimonial.medication}</p>
                <div className="flex items-center space-x-1">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-4 w-4 fill-primary text-primary" />
                  ))}
                </div>
                <p className="text-lg font-medium text-center text-primary">"{testimonial.quote}"</p>
                <p className="text-sm text-center text-muted-foreground">
                  {testimonial.description}
                </p>
                <div className="flex items-center text-sm text-muted-foreground">
                  <MapPin className="h-4 w-4 mr-1" />
                  {testimonial.location}
                </div>
              </div>
            </Card>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="left-0" />
      <CarouselNext className="right-0" />
    </Carousel>
  );
};