
import { DoseSelectionCard } from "./medical-history/DoseSelectionCard";
import { useState, useEffect } from "react";
import { DoseType } from "./pricing/types";
import { usePricingData } from "./pricing/usePricingData";
import { PricingHeader } from "./pricing/PricingHeader";
import { PricingGrid } from "./pricing/PricingGrid";
import { PricingFooter } from "./pricing/PricingFooter";
import { usePricingSelection } from "./pricing/usePricingSelection";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { calculateAmount } from "@/utils/pricing/pricingCalculator";
import { useQueryClient } from "@tanstack/react-query";

interface PricingPlansProps {
  selectedMedication: string;
  selectedPlan: string;
  onPlanSelect: (plan: string, doseType: DoseType, amount: number) => void;
  previousGlp1: string;
  previousMedication: string;
  recentGlp1: string;
  doseType: DoseType;
  onBack: () => void;
}

export const PricingPlans = ({
  selectedMedication,
  selectedPlan,
  onPlanSelect,
  previousGlp1,
  previousMedication,
  recentGlp1,
  doseType: initialDoseType,
}: PricingPlansProps) => {
  const [doseType, setDoseType] = useState<DoseType>(initialDoseType);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  
  const needsRampUp = (): boolean => {
    return previousGlp1 !== "yes" || 
           recentGlp1 !== "yes" || 
           previousMedication?.toLowerCase() !== selectedMedication?.toLowerCase();
  };

  const { pricingData } = usePricingData({
    selectedMedication,
    previousGlp1,
    previousMedication,
    recentGlp1,
    doseType
  });

  // Send GTM event when pricing plans page is viewed
  useEffect(() => {
    if (window.dataLayer && selectedMedication) {
      window.dataLayer.push({
        event: 'view_pricing_plans',
        medication: selectedMedication,
        dose_type: doseType,
        needs_ramp_up: needsRampUp()
      });
      console.log('Pricing plans view event sent to GTM');
    }
  }, [selectedMedication, doseType]);

  // Load saved plan without auto-selecting
  useEffect(() => {
    const loadSavedPlan = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          console.error('No authenticated user found');
          return;
        }

        const { data: draftAssessment, error } = await supabase
          .from('assessments')
          .select('*')
          .eq('user_id', user.id)
          .eq('status', 'draft')
          .maybeSingle();

        if (error) {
          console.error('Error fetching draft assessment:', error);
          return;
        }

        // Only log the saved plan info, don't auto-select
        if (draftAssessment?.plan_type && draftAssessment?.amount) {
          console.log('Found saved plan:', {
            plan: draftAssessment.plan_type,
            amount: draftAssessment.amount,
            doseType: draftAssessment.dose_type || 'standard'
          });
          
          // Update the dose type if it exists
          if (draftAssessment.dose_type) {
            setDoseType(draftAssessment.dose_type);
          }
        }
      } catch (error) {
        console.error('Error loading saved plan:', error);
      }
    };

    loadSavedPlan();
  }, []);

  const { handlePlanSelect } = usePricingSelection({
    selectedMedication,
    pricingData,
    onPlanSelect
  });

  const getHeaderText = () => {
    if (needsRampUp()) {
      return "Since you're new to this medication, you'll start with our ramp-up dosing schedule";
    }
    return "Choose your preferred dosing schedule";
  };

  const getDescriptionText = () => {
    if (needsRampUp()) {
      return "Our ramp-up schedule ensures your body adjusts safely to the medication while minimizing side effects.";
    }
    return "Select the plan that works best for you. All plans include medical consultation, ongoing support, and free shipping.";
  };

  const handleDoseTypeChange = async (newDoseType: DoseType) => {
    try {
      setDoseType(newDoseType);
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        return;
      }

      console.log('Fetching draft assessment for user:', user.id);

      const { data: draftAssessment, error: fetchError } = await supabase
        .from('assessments')
        .select('*')
        .eq('user_id', user.id)
        .eq('status', 'draft')
        .maybeSingle();

      if (fetchError) {
        console.error('Error fetching draft assessment:', fetchError);
        toast({
          title: "Error",
          description: "Failed to update dose type. Please try again.",
          variant: "destructive",
        });
        return;
      }

      if (!draftAssessment) {
        console.error('No draft assessment found');
        toast({
          title: "Error",
          description: "No draft assessment found. Please try again.",
          variant: "destructive",
        });
        return;
      }

      // Calculate the amount for the current plan with the new dose type
      const currentPlan = draftAssessment.plan_type;
      let amount = null;
      
      if (currentPlan && currentPlan !== 'pending') {
        amount = await calculateAmount({
          medication: selectedMedication,
          plan: currentPlan,
          doseType: newDoseType
        });
      }

      console.log('Updating assessment:', {
        id: draftAssessment.id,
        newDoseType,
        currentPlan,
        amount,
        currentStatus: draftAssessment.status
      });

      // Update the dose type in the draft assessment
      const { error: updateError } = await supabase
        .from('assessments')
        .update({ 
          dose_type: newDoseType,
          amount: amount || draftAssessment.amount, // Keep existing amount if calculation fails
          plan_type: currentPlan || 'pending'
        })
        .eq('id', draftAssessment.id);

      if (updateError) {
        console.error('Error updating dose type:', updateError);
        toast({
          title: "Error",
          description: "Failed to save dose type. Please try again.",
          variant: "destructive",
        });
      } else {
        // Invalidate queries to refresh data
        await queryClient.invalidateQueries({ queryKey: ["user-assessments"] });
        console.log('Successfully updated dose type to:', newDoseType);
      }
    } catch (error) {
      console.error('Error in handleDoseTypeChange:', error);
      toast({
        title: "Error",
        description: "An unexpected error occurred. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-6">
      <PricingHeader 
        headerText={getHeaderText()} 
        descriptionText={getDescriptionText()} 
      />

      {!needsRampUp() && (
        <DoseSelectionCard
          medication={selectedMedication}
          doseType={doseType}
          onDoseTypeChange={handleDoseTypeChange}
        />
      )}

      <PricingGrid
        pricingData={pricingData}
        selectedPlan={selectedPlan}
        onSelect={(plan) => handlePlanSelect(plan, doseType)}
      />

      <PricingFooter needsRampUp={needsRampUp()} />
    </div>
  );
};
