
import { TableCell, TableRow } from "@/components/ui/table"
import { Badge } from "@/components/ui/badge"
import { formatDate } from "@/utils/formatDate"
import { formatCurrency } from "@/utils/formatCurrency"
import { Assessment } from "../types"
import { BadgePercent } from "lucide-react"

interface OrderTableRowProps {
  assessment: Assessment
  onClick: () => void
}

export const OrderTableRow = ({ assessment, onClick }: OrderTableRowProps) => {
  console.log('Rendering order row:', assessment);

  const getPatientTypeDisplay = () => {
    if (!assessment.previous_glp1 || !assessment.recent_glp1) {
      return (
        <Badge variant="outline" className="bg-yellow-50 text-yellow-700 border-yellow-200">
          Ramping Patient
        </Badge>
      )
    }
    return (
      <Badge variant="outline" className="bg-blue-50 text-blue-700 border-blue-200">
        Continuing Patient
      </Badge>
    )
  }

  const getRequestTypeDisplay = () => {
    if (assessment.is_renewal) {
      return (
        <Badge variant="secondary">
          Renewal #{assessment.renewal_count || 0}
        </Badge>
      )
    }
    return (
      <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
        New Assessment
      </Badge>
    )
  }

  const getDoseTypeDisplay = () => {
    const doseType = assessment.dose_type || 'standard'
    return (
      <Badge 
        variant="outline" 
        className={doseType === 'standard' 
          ? "bg-blue-50 text-blue-700 border-blue-200"
          : "bg-purple-50 text-purple-700 border-purple-200"
        }
      >
        {doseType === 'standard' ? 'Standard Dose' : 'Max Dose'}
      </Badge>
    )
  }

  // Check if a promo code was applied
  const hasPromoCode = !!assessment.applied_promo_code;
  // Get the final price (discounted if applicable)
  const finalPrice = assessment.discounted_amount || assessment.amount;

  return (
    <TableRow 
      className="cursor-pointer hover:bg-gray-50"
      onClick={onClick}
    >
      <TableCell>{formatDate(assessment.created_at || '')}</TableCell>
      <TableCell>
        <div className="flex flex-col gap-1">
          {getRequestTypeDisplay()}
          {getPatientTypeDisplay()}
        </div>
      </TableCell>
      <TableCell className="capitalize">{assessment.medication}</TableCell>
      <TableCell>{getDoseTypeDisplay()}</TableCell>
      <TableCell>{assessment.plan_type}</TableCell>
      <TableCell>
        {hasPromoCode ? (
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <BadgePercent className="h-3.5 w-3.5 text-green-600" />
              <span>{formatCurrency(finalPrice)}</span>
            </div>
            <span className="text-xs text-muted-foreground line-through">
              {formatCurrency(assessment.amount)}
            </span>
          </div>
        ) : (
          formatCurrency(assessment.amount)
        )}
      </TableCell>
      <TableCell>
        <Badge variant="outline" className={
          assessment.status === 'prescribed' ? 'bg-green-50 text-green-700' :
          assessment.status === 'denied' ? 'bg-red-50 text-red-700' :
          assessment.status === 'completed' ? 'bg-blue-50 text-blue-700' :
          'bg-gray-50 text-gray-700'
        }>
          {assessment.status}
        </Badge>
      </TableCell>
    </TableRow>
  )
}
