
import { ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthCheck } from "./hooks/useAuthCheck"
import { Skeleton } from "@/components/ui/skeleton"
import { supabase } from "@/integrations/supabase/client"
import { toast } from "sonner"

interface ProviderAuthGuardProps {
  children: ReactNode
}

export const ProviderAuthGuard = ({ children }: ProviderAuthGuardProps) => {
  const { authChecked, isProvider } = useAuthCheck()
  const navigate = useNavigate()
  const [isVerifying, setIsVerifying] = useState(true)

  useEffect(() => {
    const verifySession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession()
        if (!session) {
          console.log('No active session')
          navigate("/provider/login", { replace: true })
          return
        }

        // Log current location for debugging
        console.log("Current location:", window.location.pathname)
        
        // Check both auth metadata and profiles table
        const isProviderMeta = session.user?.user_metadata?.is_provider === true
        
        if (!isProviderMeta) {
          // Check profiles table as fallback
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('is_provider')
            .eq('id', session.user.id)
            .single()
            
          if (profileError || !profile || !profile.is_provider) {
            console.log('User is not a provider in either metadata or profiles')
            toast.error("You don't have provider access")
            await supabase.auth.signOut()
            navigate("/provider/login", { replace: true })
            return
          }
        }
      } catch (error) {
        console.error('Session verification error:', error)
        navigate("/provider/login", { replace: true })
      } finally {
        setIsVerifying(false)
      }
    }

    verifySession()

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("Auth guard state change:", event)
      
      if (event === 'SIGNED_OUT' || !session) {
        navigate("/provider/login", { replace: true })
        return
      }

      // Verify provider status on any auth state change
      if (session && !session.user?.user_metadata?.is_provider) {
        // Check profiles table as fallback
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('is_provider')
          .eq('id', session.user.id)
          .single()
          
        if (profileError || !profile || !profile.is_provider) {
          console.log('User is not a provider, redirecting')
          await supabase.auth.signOut()
          navigate("/provider/login", { replace: true })
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [navigate])

  if (!authChecked || isVerifying) {
    return (
      <div className="container mx-auto py-10">
        <div className="space-y-4">
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-32 w-full" />
          <Skeleton className="h-32 w-full" />
        </div>
      </div>
    )
  }

  if (!isProvider) {
    return null
  }

  console.log("Rendering provider-protected content");
  return <>{children}</>
}
