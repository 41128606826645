
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Check, Timer } from "lucide-react";
import { cn } from "@/lib/utils";
import { formatCurrency } from "@/utils/formatCurrency";
import { format, addDays } from "date-fns";

interface PricingCardProps {
  plan: {
    duration_months: number;
    monthly_price: number;
    total_price: number;
  };
  isSelected: boolean;
  isPopular: boolean;
  onSelect: () => void;
}

export const PricingCard = ({ plan, isSelected, isPopular, onSelect }: PricingCardProps) => {
  const getFeatures = (duration: number) => {
    const features = [
      `${duration}-month supply`,
      "Medical consultation included",
      duration >= 4 ? "Priority support" : "24/7 provider support",
      duration >= 4 ? "Personalized treatment plan" : "Cancel anytime",
      duration >= 6 ? "Monthly progress reviews" : "Regular check-ins",
    ];
    return features;
  };

  const originalMonthlyPrice = plan.monthly_price * 1.2; // 20% higher
  const originalTotalPrice = plan.total_price * 1.2; // 20% higher
  const offerEndDate = format(addDays(new Date(), 3), "MMM d, yyyy");

  return (
    <Card
      className={cn(
        "relative cursor-pointer transition-all hover:shadow-lg border-2",
        isSelected ? "border-primary ring-2 ring-primary bg-primary/5" : "border-transparent",
        isPopular && "scale-105 md:translate-y-[-1rem]"
      )}
      onClick={onSelect}
    >
      {isPopular && (
        <>
          <div className="absolute -top-4 md:-top-3 left-1/2 transform -translate-x-1/2">
            <div className="bg-primary/90 text-primary-foreground px-4 py-2 md:px-3 md:py-1.5 rounded-full text-sm md:text-xs font-semibold flex items-center gap-2 md:gap-1.5 shadow-md transition-opacity duration-700">
              <Timer className="w-5 h-5 md:w-4 md:h-4" />
              Limited Time
            </div>
          </div>
          <div className="absolute -right-2 top-12 transform rotate-12">
            <div className="bg-[#FDE1D3] text-[#F97316] px-3 py-1 rounded-lg text-xs font-bold shadow-sm">
              Most Value
            </div>
          </div>
        </>
      )}
      <CardHeader>
        <CardTitle className="text-xl text-center">
          {plan.duration_months} Month Plan
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div className="text-center">
            {isPopular ? (
              <div className="space-y-1">
                <p className="text-4xl font-bold">
                  ${plan.monthly_price}
                  <span className="text-base font-normal text-muted-foreground">/month</span>
                </p>
                <p className="text-sm text-muted-foreground line-through">
                  ${Math.round(originalMonthlyPrice)}/month
                </p>
                <p className="text-sm text-muted-foreground mt-1">
                  ${plan.total_price} billed once
                </p>
              </div>
            ) : (
              <>
                <p className="text-4xl font-bold">
                  ${plan.monthly_price}
                  <span className="text-base font-normal text-muted-foreground">/month</span>
                </p>
                <p className="text-sm text-muted-foreground mt-1">
                  ${plan.total_price} billed once
                </p>
              </>
            )}
            {isPopular && (
              <div className="mt-2 space-y-1">
                <p className="text-sm font-medium text-[#F97316]">
                  Save ${Math.round(originalTotalPrice - plan.total_price)} with this offer
                </p>
                <p className="text-xs text-muted-foreground">
                  Discount expires <span className="font-bold">{offerEndDate}</span>
                </p>
              </div>
            )}
          </div>
          <ul className="space-y-3">
            {getFeatures(plan.duration_months).map((feature) => (
              <li key={feature} className="flex items-center gap-2 text-sm">
                <Check className="w-4 h-4 text-primary flex-shrink-0" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </CardContent>
    </Card>
  );
};
