interface MedicationsSectionProps {
  assessment: any;
}

export const MedicationsSection = ({ assessment }: MedicationsSectionProps) => {
  const formatBoolean = (value: boolean | null) => {
    if (value === null) return "Not specified";
    return value ? "Yes" : "No";
  };

  return (
    <section className="space-y-4">
      <h3 className="text-lg font-semibold">Medications & Allergies</h3>
      <div className="grid gap-6">
        <div>
          <p className="text-sm font-medium text-muted-foreground">Taking Medications</p>
          <p className="text-sm mt-1">{formatBoolean(assessment.taking_medications)}</p>
          {assessment.taking_medications && assessment.medications_list && (
            <div className="mt-2">
              <p className="text-sm font-medium text-muted-foreground">Medications List</p>
              <p className="text-sm mt-1 whitespace-pre-wrap">{assessment.medications_list}</p>
            </div>
          )}
        </div>

        <div className="space-y-2">
          <div>
            <p className="text-sm font-medium text-muted-foreground">Previous GLP-1 Medications</p>
            <p className="text-sm mt-1">{formatBoolean(assessment.previous_glp1)}</p>
          </div>
          {assessment.previous_glp1 && (
            <>
              <div>
                <p className="text-sm font-medium text-muted-foreground">Recent GLP-1 (Last 8 weeks)</p>
                <p className="text-sm mt-1">{formatBoolean(assessment.recent_glp1)}</p>
              </div>
              {assessment.recent_glp1 && assessment.recent_glp1_date && (
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Last GLP-1 Date</p>
                  <p className="text-sm mt-1">{new Date(assessment.recent_glp1_date).toLocaleDateString()}</p>
                </div>
              )}
              {assessment.previous_medication && (
                <div>
                  <p className="text-sm font-medium text-muted-foreground">Previous Medication</p>
                  <p className="text-sm mt-1">{assessment.previous_medication}</p>
                </div>
              )}
            </>
          )}
        </div>

        <div>
          <p className="text-sm font-medium text-muted-foreground">Has Allergies</p>
          <p className="text-sm mt-1">{formatBoolean(assessment.has_allergies)}</p>
          {assessment.has_allergies && assessment.allergies_list && (
            <div className="mt-2">
              <p className="text-sm font-medium text-muted-foreground">Allergies List</p>
              <p className="text-sm mt-1 whitespace-pre-wrap">{assessment.allergies_list}</p>
            </div>
          )}
        </div>

        <div>
          <p className="text-sm font-medium text-muted-foreground">Taking Blood Thinners</p>
          <p className="text-sm mt-1">{formatBoolean(assessment.taking_blood_thinners)}</p>
        </div>
      </div>
    </section>
  );
};