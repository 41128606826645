import { Loader } from "lucide-react"
import { useAuthCheck } from "./provider/hooks/useAuthCheck"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { supabase } from "@/integrations/supabase/client"
import { DashboardHeader } from "./provider/dashboard/DashboardHeader"
import { DashboardContent } from "./provider/dashboard/DashboardContent"
import { Profile } from "@/types/database/profile"
import { useNavigate } from "react-router-dom"
import { useToast } from "@/hooks/use-toast"
import { useEffect, useState } from "react"

const ProviderDashboard = () => {
  const { authChecked, isProvider } = useAuthCheck()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useToast()
  const [isInitializing, setIsInitializing] = useState(true)

  // Enhanced auth state listener
  useEffect(() => {
    const checkInitialSession = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession()
        if (!session) {
          console.log('No initial session found')
          navigate("/provider/login", { replace: true })
          return
        }
        
        // Verify provider status
        if (!session.user?.user_metadata?.is_provider) {
          console.log('User is not a provider')
          await supabase.auth.signOut()
          navigate("/provider/login", { replace: true })
          return
        }
      } catch (error) {
        console.error('Session check error:', error)
        navigate("/provider/login", { replace: true })
      } finally {
        setIsInitializing(false)
      }
    }

    checkInitialSession()

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session?.user?.user_metadata)
      
      if (event === 'SIGNED_OUT' || !session) {
        queryClient.clear()
        navigate("/provider/login", { replace: true })
        return
      }

      if (event === 'TOKEN_REFRESHED') {
        if (!session) {
          console.log('Token refresh failed')
          queryClient.clear()
          navigate("/provider/login", { replace: true })
          return
        }

        // Verify provider status on token refresh
        if (!session.user?.user_metadata?.is_provider) {
          console.log('User is not a provider after token refresh')
          await supabase.auth.signOut()
          navigate("/provider/login", { replace: true })
          return
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [navigate, queryClient])

  const { data: profile, error: profileError } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      try {
        const { data: { user }, error: userError } = await supabase.auth.getUser()
        
        if (userError) {
          console.error("Auth error:", userError)
          if (userError.message.includes("session_not_found") || 
              userError.message.includes("JWT expired")) {
            await supabase.auth.signOut()
            toast({
              title: "Session expired",
              description: "Please sign in again",
              variant: "destructive",
            })
            navigate("/provider/login", { replace: true })
            return null
          }
          throw userError
        }

        if (!user) {
          console.log("No user found, redirecting to login")
          navigate("/provider/login", { replace: true })
          return null
        }

        if (!user.user_metadata?.is_provider) {
          console.log("User is not a provider, redirecting to login")
          await supabase.auth.signOut()
          navigate("/provider/login", { replace: true })
          return null
        }
        
        const { data, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", user.id)
          .single()

        if (error) {
          console.error("Profile fetch error:", error)
          throw error
        }

        return data as Profile
      } catch (error) {
        console.error("Profile query error:", error)
        throw error
      }
    },
    enabled: authChecked && isProvider && !isInitializing,
    retry: false,
    staleTime: 1000 * 60 * 5,
  })

  // Handle loading state
  if (!authChecked || !isProvider || isInitializing || !profile) {
    return (
      <div className="container mx-auto py-10">
        <div className="flex justify-center items-center min-h-[400px]">
          <Loader className="h-8 w-8 animate-spin text-primary" />
        </div>
      </div>
    )
  }

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="container mx-auto py-10">
        <DashboardHeader 
          profile={profile} 
          onProfileUpdate={() => {
            queryClient.invalidateQueries({ queryKey: ["profile"] })
          }} 
        />
        <DashboardContent />
      </div>
    </main>
  )
}

export default ProviderDashboard