
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { AssessmentFormData } from "@/types/assessment";
import { calculateAmount } from "@/utils/pricingUtils";
import { DoseType } from "@/components/pricing/types";

export const useSaveDraftAssessment = (formData: AssessmentFormData, draftAssessmentId: string | null) => {
  const { toast } = useToast();

  useEffect(() => {
    const saveDraftAssessment = async () => {
      try {
        if (!draftAssessmentId) {
          console.log('No draft assessment ID available');
          return;
        }

        // First check if assessment exists and get its status
        const { data: currentAssessment, error: fetchError } = await supabase
          .from('assessments')
          .select('status')
          .eq('id', draftAssessmentId)
          .maybeSingle();

        if (fetchError) {
          console.error('Error fetching assessment:', fetchError);
          return;
        }

        // If no assessment found or it's already completed, don't update
        if (!currentAssessment || currentAssessment.status === 'completed') {
          console.log('Assessment not found or already completed, skipping draft save');
          return;
        }

        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          console.log('No authenticated user found');
          return;
        }

        // Extract the plan type string from the selectedPlan object if it exists
        const planType = formData.selectedPlan 
          ? (typeof formData.selectedPlan === 'object' 
              ? formData.selectedPlan.selectedPlan 
              : formData.selectedPlan)
          : "pending";

        // Extract the dose type from the selectedPlan object if it exists
        const doseType: DoseType = formData.selectedPlan && typeof formData.selectedPlan === 'object'
          ? formData.selectedPlan.doseType
          : formData.doseType || 'standard';

        // Get the amount either from the selectedPlan object or calculate it
        const amount = formData.selectedPlan
          ? (typeof formData.selectedPlan === 'object'
              ? formData.selectedPlan.amount
              : await calculateAmount(formData.selectedMedication, planType))
          : 0;

        console.log('Saving assessment data:', {
          user_id: user.id,
          date_of_birth: formData.dateOfBirth || null,
          gender: formData.gender || null,
          cell_phone: formData.cellPhone || null,
          medical_conditions: formData.selectedConditions || [],
          other_medical_conditions: formData.otherCondition || null,
          medullary_thyroid_cancer: formData.medullaryThyroidCancer === "yes",
          family_mtc_history: formData.familyMtcHistory === "yes",
          men2: formData.men2 === "yes",
          pregnant_or_breastfeeding: formData.pregnantOrBreastfeeding === "yes",
          patient_height: parseInt(formData.heightFeet) * 12 + parseInt(formData.heightInches || '0'),
          patient_weight: parseFloat(formData.weight) || null,
          exercise_activity: formData.exerciseActivity || null,
          taking_medications: formData.takingMedications === "yes",
          medications_list: formData.medicationsList || null,
          previous_glp1: formData.previousGlp1 === "yes",
          previous_medication: formData.previousMedication || null,
          recent_glp1: formData.recentGlp1 === "yes",
          recent_glp1_date: formData.recentGlp1Date || null,
          has_allergies: formData.hasAllergies === "yes",
          allergies_list: formData.allergiesList || null,
          taking_blood_thinners: formData.takingBloodThinners === "yes",
          medication: formData.selectedMedication || "pending",
          plan_type: planType,
          amount,
          shipping_address: formData.shippingAddress || null,
          shipping_city: formData.shippingCity || null,
          shipping_state: formData.shippingState || null,
          shipping_zip: formData.shippingZip || null,
          status: 'draft' as const,
          dose_type: doseType
        });

        const { error } = await supabase
          .from('assessments')
          .update({
            user_id: user.id,
            date_of_birth: formData.dateOfBirth || null,
            gender: formData.gender || null,
            cell_phone: formData.cellPhone || null,
            medical_conditions: formData.selectedConditions || [],
            other_medical_conditions: formData.otherCondition || null,
            medullary_thyroid_cancer: formData.medullaryThyroidCancer === "yes",
            family_mtc_history: formData.familyMtcHistory === "yes",
            men2: formData.men2 === "yes",
            pregnant_or_breastfeeding: formData.pregnantOrBreastfeeding === "yes",
            patient_height: parseInt(formData.heightFeet) * 12 + parseInt(formData.heightInches || '0'),
            patient_weight: parseFloat(formData.weight) || null,
            exercise_activity: formData.exerciseActivity || null,
            taking_medications: formData.takingMedications === "yes",
            medications_list: formData.medicationsList || null,
            previous_glp1: formData.previousGlp1 === "yes",
            previous_medication: formData.previousMedication || null,
            recent_glp1: formData.recentGlp1 === "yes",
            recent_glp1_date: formData.recentGlp1Date || null,
            has_allergies: formData.hasAllergies === "yes",
            allergies_list: formData.allergiesList || null,
            taking_blood_thinners: formData.takingBloodThinners === "yes",
            medication: formData.selectedMedication || "pending",
            plan_type: planType,
            amount,
            shipping_address: formData.shippingAddress || null,
            shipping_city: formData.shippingCity || null,
            shipping_state: formData.shippingState || null,
            shipping_zip: formData.shippingZip || null,
            status: 'draft' as const,
            dose_type: doseType
          })
          .eq('id', draftAssessmentId);

        if (error) {
          console.error('Error saving draft assessment:', error);
          toast({
            title: "Error",
            description: "Failed to save your progress. Please try again.",
            variant: "destructive",
          });
          return;
        }

        console.log('Successfully saved draft assessment');
      } catch (error) {
        console.error('Error in saveDraftAssessment:', error);
        toast({
          title: "Error",
          description: "Failed to save your progress. Please try again.",
          variant: "destructive",
        });
      }
    };

    // Save draft after a short delay to avoid too frequent updates
    const debounceTimeout = setTimeout(saveDraftAssessment, 500);
    return () => clearTimeout(debounceTimeout);
  }, [formData, draftAssessmentId, toast]);
};
