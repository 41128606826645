
import { TableHead, TableHeader, TableRow } from "@/components/ui/table"

interface AssessmentTableHeaderProps {
  showDenialReason?: boolean
  showActions?: boolean
}

export const AssessmentTableHeader = ({ 
  showDenialReason = false, 
  showActions = false 
}: AssessmentTableHeaderProps) => {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>Patient</TableHead>
        <TableHead>Plan</TableHead>
        <TableHead>Medication</TableHead>
        <TableHead>Status</TableHead>
        {showDenialReason && (
          <TableHead>Denial Reason</TableHead>
        )}
        <TableHead>Date</TableHead>
        <TableHead>Amount</TableHead>
        {showActions && (
          <TableHead>Actions</TableHead>
        )}
      </TableRow>
    </TableHeader>
  )
}
