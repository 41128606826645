
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { EditPricingDialog } from "./EditPricingDialog";

interface PricingData {
  id: string;
  medication: string;
  dose_type: "standard" | "max";
  duration_months: number;
  monthly_price: number;
  total_price: number;
  patient_type: string;
}

interface PricingTableProps {
  pricingData: PricingData[];
}

export const PricingTable = ({ pricingData }: PricingTableProps) => {
  const [selectedPrice, setSelectedPrice] = useState<PricingData | null>(null);

  console.log("PricingTable received data:", pricingData); // Add this debug log

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Medication</TableHead>
            <TableHead>Patient Type</TableHead>
            <TableHead>Dose Type</TableHead>
            <TableHead>Duration</TableHead>
            <TableHead className="text-right">Monthly Price</TableHead>
            <TableHead className="text-right">Total Price</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {pricingData.map((price) => (
            <TableRow key={price.id}>
              <TableCell className="capitalize">{price.medication}</TableCell>
              <TableCell className="capitalize">{price.patient_type}</TableCell>
              <TableCell className="capitalize">{price.dose_type}</TableCell>
              <TableCell>{price.duration_months} months</TableCell>
              <TableCell className="text-right">${price.monthly_price}</TableCell>
              <TableCell className="text-right">${price.total_price}</TableCell>
              <TableCell className="text-right">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setSelectedPrice(price)}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <EditPricingDialog
        open={!!selectedPrice}
        onOpenChange={() => setSelectedPrice(null)}
        pricing={selectedPrice}
      />
    </>
  );
};
