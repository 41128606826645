import { useState } from "react"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { UserPlus, Search, Loader2 } from "lucide-react"
import { MakeProviderButton } from "@/components/MakeProviderButton"
import { useToast } from "@/hooks/use-toast"
import { useQuery } from "@tanstack/react-query"
import { supabase } from "@/integrations/supabase/client"
import { Input } from "@/components/ui/input"
import { ScrollArea } from "@/components/ui/scroll-area"

interface UserResult {
  id: string
  first_name: string | null
  last_name: string | null
  email: string | null
  is_provider: boolean
}

export function AddProviderPage() {
  const { toast } = useToast()
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("")

  const { data: users, isLoading } = useQuery({
    queryKey: ["search-users", searchTerm],
    queryFn: async () => {
      if (!searchTerm) return []
      
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .or(`first_name.ilike.%${searchTerm}%,last_name.ilike.%${searchTerm}%,email.ilike.%${searchTerm}%`)
        .eq("is_provider", false)
        .limit(10)

      if (error) {
        console.error("Error searching users:", error)
        throw error
      }

      return data as UserResult[]
    },
    enabled: searchTerm.length >= 2,
  })

  const handleProviderSuccess = () => {
    toast({
      title: "Success",
      description: "User has been made a provider successfully.",
    })
    setSelectedUserId("")
    setSearchTerm("")
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Add New Provider</h1>
      <Card>
        <CardHeader>
          <CardTitle className="text-lg font-semibold flex items-center gap-2">
            <UserPlus className="h-5 w-5" />
            Make New Provider
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search users by name or email..."
              className="pl-9"
            />
          </div>

          {isLoading && (
            <div className="flex items-center justify-center py-4">
              <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
            </div>
          )}

          {searchTerm.length >= 2 && !isLoading && users?.length === 0 && (
            <p className="text-center text-muted-foreground py-4">
              No non-provider users found
            </p>
          )}

          {users && users.length > 0 && (
            <ScrollArea className="h-[300px]">
              <div className="space-y-2">
                {users.map((user) => (
                  <Card
                    key={user.id}
                    className={`p-4 cursor-pointer transition-colors hover:bg-muted/50 ${
                      selectedUserId === user.id ? "border-primary" : ""
                    }`}
                    onClick={() => setSelectedUserId(user.id)}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="font-medium">
                          {user.first_name} {user.last_name}
                        </p>
                        <p className="text-sm text-muted-foreground">
                          {user.email}
                        </p>
                      </div>
                      {selectedUserId === user.id && (
                        <MakeProviderButton
                          userId={user.id}
                          onSuccess={handleProviderSuccess}
                        />
                      )}
                    </div>
                  </Card>
                ))}
              </div>
            </ScrollArea>
          )}
        </CardContent>
      </Card>
    </div>
  )
}