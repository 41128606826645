
import { useInfiniteQuery } from "@tanstack/react-query"
import { supabase } from "@/integrations/supabase/client"
import { Assessment } from "../types"

const PAGE_SIZE = 50

interface AssessmentPage {
  assessments: Assessment[];
  totalCount: number;
  currentPage: number;
  hasMore: boolean;
}

export const useAssessments = (isProvider: boolean | undefined, authChecked: boolean) => {
  return useInfiniteQuery<AssessmentPage>({
    queryKey: ["provider-assessments"],
    queryFn: async ({ pageParam }) => {
      const page = typeof pageParam === 'number' ? pageParam : 0;
      console.log('Fetching assessments page:', page)
      
      const from = page * PAGE_SIZE
      const to = from + PAGE_SIZE - 1

      const { data, error, count } = await supabase
        .from("assessments")
        .select(`
          *,
          profiles (
            id,
            first_name,
            last_name,
            email,
            is_provider,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_term,
            utm_content,
            created_at,
            updated_at
          )
        `, { count: 'exact' })
        .order('created_at', { ascending: false })
        .range(from, to)

      if (error) {
        console.error('Error fetching assessments:', error)
        throw error
      }

      return {
        assessments: data as Assessment[],
        totalCount: count || 0,
        currentPage: page,
        hasMore: (count || 0) > (page + 1) * PAGE_SIZE
      }
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (!lastPage.hasMore) return undefined
      return lastPage.currentPage + 1
    },
    enabled: authChecked && isProvider,
  })
}
