
import { PaymentData } from "../types/payment";
import { type ToastActionElement } from "@/components/ui/toast";
import { supabase } from "@/integrations/supabase/client";

type ToastProps = {
  title?: string;
  description?: string;
  variant?: "default" | "destructive";
  action?: ToastActionElement;
};

export const validatePaymentData = (paymentData: PaymentData, showToast: (props: ToastProps) => void): boolean => {
  // Skip validation if using a saved payment method
  if (paymentData.savedMethodId) {
    return true;
  }

  const cleanCardNumber = paymentData.cardNumber.replace(/\s/g, '');
  
  if (cleanCardNumber === '4111111111111111') {
    console.log('Test card detected - bypassing initial validation');
    return true;
  }

  if (!/^\d{15,16}$/.test(cleanCardNumber)) {
    showToast({
      title: "Invalid Card Number",
      description: "Please enter a valid card number (15-16 digits).",
      variant: "destructive",
    });
    return false;
  }

  if (!/^\d{2}\/\d{2}$/.test(paymentData.expirationDate)) {
    showToast({
      title: "Invalid Expiration Date",
      description: "The card expiration date is invalid or the card has expired.",
      variant: "destructive",
    });
    return false;
  }

  if (!/^\d{3,4}$/.test(paymentData.cardCode)) {
    showToast({
      title: "Invalid Security Code",
      description: "Please enter a valid security code (3-4 digits).",
      variant: "destructive",
    });
    return false;
  }

  return true;
};

export const getErrorMessage = (error: any): string => {
  console.log('Payment error details:', error);
  
  const errorMessage = error?.message?.toLowerCase() || '';
  
  if (errorMessage.includes('card number format')) {
    return 'Please enter a valid card number (15-16 digits).';
  }
  if (errorMessage.includes('expired')) {
    return 'This card has expired. Please use a different card.';
  }
  if (errorMessage.includes('declined')) {
    return 'Your card was declined. Please check your card details or try a different card.';
  }
  if (errorMessage.includes('invalid')) {
    return 'The card information provided is invalid. Please check and try again.';
  }
  if (errorMessage.includes('cvv') || errorMessage.includes('security code')) {
    return 'Invalid security code (CVV). Please check and try again.';
  }
  
  return 'There was a problem processing your payment. Please try again.';
};

// Track payment failure and send immediate email notification
export const trackPaymentFailure = async (error: any, email: string, firstName?: string) => {
  try {
    console.log('Tracking payment failure and sending email notification');
    
    // Parse the error to determine the type
    const errorMessage = error?.message?.toLowerCase() || '';
    let errorType = 'general';
    
    if (errorMessage.includes('declined')) {
      errorType = 'declined';
    } else if (errorMessage.includes('expired')) {
      errorType = 'expired';
    } else if (errorMessage.includes('invalid')) {
      errorType = 'invalid';
    }
    
    // Create a unique identifier for this payment failure
    const failureId = crypto.randomUUID();

    // Send the email immediately through the edge function
    console.log(`Sending payment failure email to ${email}`);
    try {
      const { error: emailError } = await supabase.functions.invoke('send-payment-failure-email', {
        body: {
          to: email,
          firstName: firstName || undefined,
          errorType,
          replyTo: "support@mybellehealth.com"
        },
      });

      if (emailError) {
        console.error("Error sending payment failure email:", emailError);
        return { success: false, error: emailError };
      } else {
        console.log("Payment failure email sent successfully");
        return { success: true, failureId };
      }
    } catch (err) {
      console.error("Error in payment failure email sender:", err);
      return { success: false, error: err };
    }
  } catch (err) {
    console.error("Error tracking payment failure:", err);
    return { success: false, error: err };
  }
};
