
import { createClient } from '@supabase/supabase-js'
import type { Database } from './types'

const SUPABASE_URL = "https://frpivlbucqrlerxfquaw.supabase.co"
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZycGl2bGJ1Y3FybGVyeGZxdWF3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzYzOTQxODAsImV4cCI6MjA1MTk3MDE4MH0.YHHHwSs7bWYbgpxJ2vZeHRIOpo29NSYb_C65pCZDyuo"

// Create a single supabase client for interacting with your database
export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  },
})

// Add a listener for auth state changes to check token validity
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state changed:', event)
  
  if (event === 'TOKEN_REFRESHED') {
    console.log('Token refreshed successfully')
  }
  
  if (event === 'SIGNED_OUT') {
    console.log('User signed out')
  }
})
