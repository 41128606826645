
import { Assessment } from "@/types/database/assessment"
import { formatDate } from "@/utils/formatDate"
import { Badge } from "@/components/ui/badge"

interface PatientDetailsProps {
  assessment: Assessment
}

export const PatientDetails = ({ assessment }: PatientDetailsProps) => {
  // Calculate the final price after discount (if any)
  const finalPrice = assessment.discounted_amount 
    ? assessment.discounted_amount 
    : assessment.amount;

  return (
    <div>
      <h3 className="font-semibold mb-4">Patient Details</h3>
      <div className="space-y-3 text-sm">
        <div>
          <span className="text-muted-foreground">Date of Birth:</span>
          <span className="ml-2">
            {assessment.date_of_birth ? formatDate(assessment.date_of_birth) : "Not provided"}
          </span>
        </div>

        <div>
          <span className="text-muted-foreground">Gender:</span>
          <span className="ml-2 capitalize">
            {assessment.gender || "Not provided"}
          </span>
        </div>

        <div>
          <span className="text-muted-foreground">Phone:</span>
          <span className="ml-2">
            {assessment.cell_phone || "Not provided"}
          </span>
        </div>

        <div className="pt-4 border-t">
          <h4 className="font-medium mb-2">Purchase Details</h4>
          <div className="space-y-2">
            <div>
              <span className="text-muted-foreground">Plan:</span>
              <span className="ml-2">{assessment.plan_type}</span>
            </div>
            <div>
              <span className="text-muted-foreground">Medication:</span>
              <span className="ml-2 capitalize">{assessment.medication}</span>
            </div>
            <div>
              <span className="text-muted-foreground">Dose Type:</span>
              <Badge variant="outline" className="ml-2 capitalize">
                {assessment.dose_type || "standard"}
              </Badge>
            </div>
            <div>
              <span className="text-muted-foreground">Total Amount:</span>
              {assessment.applied_promo_code ? (
                <div className="inline-flex items-center ml-2 gap-2">
                  <span>${finalPrice}</span>
                  <Badge variant="secondary" className="text-xs">
                    Promo: {assessment.applied_promo_code}
                  </Badge>
                  {assessment.amount !== finalPrice && (
                    <span className="text-muted-foreground line-through text-xs">${assessment.amount}</span>
                  )}
                </div>
              ) : (
                <span className="ml-2">${assessment.amount}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
